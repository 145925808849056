import axios from "axios"
import queryString from "query-string"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import { LOGOUT, SET_TITLE } from "../redux/Actions"
import { pm } from "../utils"

const { REACT_APP_SERVIDOR } = process.env
const https = require("https")

function Details(props) {
  const user = useSelector(state => state.user)
  const query = queryString.parse(props.location.search)
  const [base64, setBase64] = useState(null)
  const [data, setData] = useState(null)
  const [log, setLog] = useState([])
  const [waiting, setWaiting] = useState(false)
  const [permitted, setPermitted] = useState(false)
  const dispatch = useDispatch()

  const checkPermission = () => {
    if (!pm(user, 5)) {
      props.history.push("/")
      toast.error("Você não tem permissão para acessar esta página.")
    } else {
      setPermitted(true)
      dispatch({ type: SET_TITLE, payload: query.nome })
    }
  }

  const monitor = async (mac, status) => {
    try {
      await axios.post(
        `${REACT_APP_SERVIDOR}/geral/robo/monitor`,
        {
          mac,
          status,
        },
        {
          headers: { token: user.token },
          httpsAgent: new https.Agent({
            rejectUnauthorized: false,
          }),
        }
      )
    } catch (err) {
      console.warn(err)
      if (err && err.response && err.response.status == 403) {
        dispatch({ type: LOGOUT })
        if (!toast.isActive("session")) {
          toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
        }
      }
    }
  }

  const getScreenshot = async () => {
    try {
      const req = await axios.get(`${REACT_APP_SERVIDOR}geral/robo/screenshot?mac=${query.mac}`, {
        headers: { token: user.token },
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
        }),
      })
      setBase64(req.data.base64)
      setData(req.data.robo)
      if (req.data.base64 == null) {
        setWaiting(true)
      }
    } catch (err) {
      console.warn(err)

      if (err && err.response && err.response.status == 403) {
        dispatch({ type: LOGOUT })
        if (!toast.isActive("session")) {
          toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
        }
      }
    }
  }

  const getLogs = async () => {
    try {
      const req = await axios.get(`${REACT_APP_SERVIDOR}geral/robo/log?mac=${query.mac}`, {
        headers: { token: user.token },
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
        }),
      })
      setLog(req.data)
    } catch (err) {
      console.warn(err)

      if (err && err.response && err.response.status == 403) {
        dispatch({ type: LOGOUT })
        if (!toast.isActive("session")) {
          toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
        }
      }
    }
  }

  useEffect(() => {
    checkPermission()

    getScreenshot()
    getLogs()
  }, [])

  useEffect(() => {
    if (log.length > 0) {
      setTimeout(() => getLogs(), 10000)
    }
  }, [log])

  if (!permitted) return null

  return (
    <div className="container-fluid mt--6">
      <div className="row card-wrapper">
        <div className="col-lg-4">
          <div className="card">
            <img
              className="card-img-top zoom"
              src={base64 ? `data:image/png;base64, ${base64}` : "../../assets/img/theme/offline.png"}
              alt="Image placeholder"
              onClick={() => {
                let data = `data:image/png;base64,${base64}`
                let w = window.open("about:blank")
                let image = new Image()
                image.src = data
                setTimeout(function () {
                  w.document.write(image.outerHTML)
                }, 0)
              }}
            />
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                {waiting ? "Aguardando requisição..." : data && data.banco ? `Banco: ${data.banco}` : "..."}
              </li>
              <li className="list-group-item">
                {waiting
                  ? "Aguardando requisição..."
                  : data && data.nome
                  ? `Nome: ${`${data.nome} (${data.id})`}`
                  : "..."}
              </li>
              <li className="list-group-item">{data && data.versao ? `Versão: ${data.versao}` : "..."}</li>
            </ul>
            <div className="card-body">
              <h3 className="card-title mb-3">Ações</h3>
              <p className="card-text mb-4">Envie algumas ações para esta máquina através dos botões abaixo.</p>
              <button onClick={() => monitor(query.mac, "on")} type="button" className="btn btn-sm btn-success">
                Ligar Monitor
              </button>
              <button onClick={() => monitor(query.mac, "off")} type="button" className="btn btn-sm btn-danger ">
                Desligar Monitor
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="card card-pricing bg-gradient-default border-0 text-center mb-4">
            <div className="card-header bg-transparent">
              <h4 className="text-uppercase ls-1 text-white mb-0">Console</h4>
            </div>
            <div className="card-body">
              <ul id="console">
                {log.map(x => (
                  <li>{x}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Details
