import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import TableV2 from "../components/TableV2"
import { SET_TITLE } from "../redux/Actions"
import { pm } from "../utils"

const columns = [
  {
    id: "usuario",
    name: "Id",
    selector: "usuario",
    sortable: true,
  },
  {
    id: "nome",
    name: "Nome",
    selector: "nome",
    sortable: false,
  },
  {
    id: "parceiro",
    name: "Tipo",
    cell: row => (
      <span>
        <span style={{ color: row.parceiro ? "blue" : "orange" }}>● </span>
        {row.parceiro ? "Parceiro" : "Promotor"}
      </span>
    ),
    sortable: true,
  },
  {
    id: "inicio",
    name: "Login Poupanegócios",
    selector: "ini",
    sortable: true,
  },
  {
    id: "fim",
    name: "Logout Poupanegócios",
    selector: "fim",
    sortable: true,
  },
  {
    id: "age(fim, inicio)",
    name: "Duração Poupanegócios",
    selector: "dur",
    sortable: true,
  },
  {
    id: "inicio_csg",
    name: "Login CSG",
    selector: "ini_csg",
    sortable: true,
  },
  {
    id: "fim_csg",
    name: "Logout CSG",
    selector: "fim_csg",
    sortable: true,
  },
  {
    id: "age(fim_csg, inicio_csg)",
    name: "Duração CSG",
    selector: "dur_csg",
    sortable: true,
  },
  {
    id: "corban",
    name: "Corban",
    selector: "corban",
    sortable: true,
  },
  {
    id: "sigla",
    name: "Sigla",
    selector: "sigla",
    sortable: true,
  },
  { id: "versao", name: "Versão", selector: "versao", sortable: true },
]

function PoupaconsigConnections(props) {
  const user = useSelector(state => state.user)
  const [permitted, setPermitted] = useState(false)
  const [filter, setFilter] = useState(null)
  const dispatch = useDispatch()

  const checkPermission = () => {
    if (!pm(user, 8)) {
      props.history.push("/")
      toast.error("Você não tem permissão para acessar esta página.")
    } else {
      setPermitted(true)
      dispatch({ type: SET_TITLE, payload: "Poupanegócios > Conexões" })
    }
  }

  useEffect(() => {
    checkPermission()
  }, [])

  if (!permitted) {
    return null
  }

  return (
    <div className="container-fluid mt--6">
      <div className="card">
        {/* Card header */}
        <div className="card-header border-0">
          <div className="row">
            <div className="col-9">
              <h3 className="mb-0">Tabela</h3>
            </div>
            <div className="col-3 text-right">
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder="Pesquisar..."
                aria-controls="datatable-basic"
                onChange={e => setFilter(e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* Light table */}
        <TableV2 table={"poupaconsig/conexoes"} columns={columns} filter={filter} />
      </div>
    </div>
  )
}

export default PoupaconsigConnections
