import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import moment from "moment"
import TableV2 from "../components/TableV2"
import { SET_TITLE } from "../redux/Actions"
import { pm } from "../utils"

const { REACT_APP_SERVIDOR } = process.env

//Proposta,Nr. Contrato;Tipo de Operação;CPF;Cliente;Cód. Empr.;Empregador;Vlr. Comissão;Vlr. Imposto;Vlr. Liq. Comissão;Dt. Pagamento;Dt. Fech. Fatura;Dt. Apuração;Tipo de Cálculo;Dt. Base Contrato;Qtd. Parc.;Prz. Adit.;Vlr. Lib. Contrato;Cód. Promot.;Cód. Agência.;Login Promot.;Regra Empregador;Regra Comissão;Status;Valor Oper.;Valor Seg. Prestamista;Valor Liq.;Regra de Contratação;Nome da Regra;Data cad. Proposta
//proposta,nr_contrato,tp_operacao,cpf,cliente,cod_empregador,empregador,vlr_comissao,vlr_imposto,vlr_liq_comissao,dt_pagamento,dt_fatura,dt_apuracao,tp_calculo,data_base,qtd_parcela,prz_adit,vlr_lib_contrato,escritorio,cod_agencia,login_promotora,regra_empregador,regra_comissao,status,vlr_operacao,vlr_seg_prestamista,vlr_liq,regra_contratacao,nome_regra,data_cad_proposta
const columns = [
  { id: "proposta", name: "Proposta", selector: "proposta", sortable: true },
  { id: "nr_contrato", name: "Nr. Contrato", selector: "nr_contrato", sortable: true },
  { id: "tp_operacao", name: "Tipo de Operação", selector: "tp_operacao", sortable: true },
  { id: "cpf", name: "CPF", selector: "cpf", sortable: true },
  { id: "cliente", name: "Cliente", selector: "cliente", sortable: true },
  { id: "cod_empregador", name: "Cód. Empr.", selector: "cod_empregador", sortable: true },
  { id: "empregador", name: "Empregador", selector: "empregador", sortable: true },
  { id: "vlr_comissao", name: "Vlr. Comissão", selector: "vlr_comissao", sortable: true },
  { id: "vlr_imposto", name: "Vlr. Imposto", selector: "vlr_imposto", sortable: true },
  { id: "vlr_liq_comissao", name: "Vlr. Liq. Comissão", selector: "vlr_liq_comissao", sortable: true },
  { id: "dt_pagamento", name: "Dt. Pagamento", selector: "dt_pagamento", sortable: true },
  { id: "dt_fatura", name: "Dt. Fech. Fatura", selector: "dt_fatura", sortable: true },
  { id: "dt_apuracao", name: "Dt. Apuração", selector: "dt_apuracao", sortable: true },
  { id: "tp_calculo", name: "Tipo de Cálculo", selector: "tp_calculo", sortable: true },
  { id: "data_base", name: "Dt. Base Contrato", selector: "data_base", sortable: true },
  { id: "qtd_parcela", name: "Qtd. Parc.", selector: "qtd_parcela", sortable: true },
  { id: "prz_adit", name: "Prz. Adit.", selector: "prz_adit", sortable: true },
  { id: "vlr_lib_contrato", name: "Vlr. Lib. Contrato", selector: "vlr_lib_contrato", sortable: true },
  { id: "escritorio", name: "Cód. Promot.", selector: "escritorio", sortable: true },
  { id: "cod_agencia", name: "Cód. Agência.", selector: "cod_agencia", sortable: true },
  { id: "login_promotora", name: "Login Promot.", selector: "login_promotora", sortable: true },
  { id: "regra_empregador", name: "Regra Empregador", selector: "regra_empregador", sortable: true },
  { id: "regra_comissao", name: "Regra Comissão", selector: "regra_comissao", sortable: true },
  { id: "status", name: "Status", selector: "status", sortable: true },
  { id: "vlr_operacao", name: "Valor Oper.", selector: "vlr_operacao", sortable: true },
  { id: "vlr_seg_prestamista", name: "Valor Seg. Prestamista", selector: "vlr_seg_prestamista", sortable: true },
  { id: "vlr_liq", name: "Valor Liq.", selector: "vlr_liq", sortable: true },
  { id: "regra_contratacao", name: "Regra de Contratação", selector: "regra_contratacao", sortable: true },
  { id: "nome_regra", name: "Nome da Regra", selector: "nome_regra", sortable: true },
  { id: "data_cad_proposta", name: "Data cad. Proposta", selector: "data_cad_proposta", sortable: true },
]

function PoupaconsigProposalsBank(props) {
  const user = useSelector(state => state.user)
  const [permitted, setPermitted] = useState(false)
  const [dateType, setDateType] = useState(1)
  const [include, setInclude] = useState(0)
  const [filter, setFilter] = useState(null)
  const [start, setStart] = useState(moment().startOf("month"))
  const [end, setEnd] = useState(moment())
  const dispatch = useDispatch()

  const checkPermission = () => {
    if (!pm(user, 99)) {
      props.history.push("/")
      toast.error("Você não tem permissão para acessar esta página.")
    } else {
      setPermitted(true)
      dispatch({ type: SET_TITLE, payload: "Poupanegócios > Propostas (Banco)" })
    }
  }

  const onDateChange = (data, f) => {
    f(moment(data, "YYYY-MM-DD"))
  }

  const exportPress = () => {
    window.open(
      `${REACT_APP_SERVIDOR}arquivo/excel/propostas-banco?id=${user.id}&parceiro=${
        user.parceiro
      }&cargos=${JSON.stringify(user.cargos)}&inicio=${start.format("YYYY-MM-DD")}&fim=${end.format("YYYY-MM-DD")}`
    )
  }

  useEffect(() => {
    checkPermission()
  }, [])

  if (!permitted) {
    return null
  }

  return (
    <div className="container-fluid mt--6">
      <div className="card">
        {/* Card header */}
        <div className="card-header border-0">
          <div className="row">
            <div className="col-9">
              <h3 className="mb-0">Tabela</h3>
            </div>
            <div className="col-3 text-right">
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder="Pesquisar proposta..."
                aria-controls="datatable-basic"
                onChange={e => setFilter(e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* Light table */}
        <TableV2 table={"poupaconsig/propostas-banco"} columns={columns} filter={filter} />
      </div>
      <div className="card mb-4">
        {/* Card header */}
        <div className="card-header">
          <h3 className="mb-0">Exportação de Dados</h3>
        </div>
        {/* Card body */}
        <div className="card-body">
          {/* Form groups used in grid */}
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label className="form-control-label">Dt. Início</label>
                <input
                  className="form-control"
                  type="date"
                  defaultValue={start.format("YYYY-MM-DD")}
                  onChange={e => onDateChange(e.target.value, setStart)}
                  max={moment().format("YYYY-MM-DD")}
                  min={moment().subtract(65, "days").format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="form-control-label">Dt. Fim</label>
                <input
                  className="form-control"
                  type="date"
                  defaultValue={end.format("YYYY-MM-DD")}
                  onChange={e => onDateChange(e.target.value, setEnd)}
                  max={moment().format("YYYY-MM-DD")}
                  min={moment().subtract(65, "days").format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="form-control-label">Ação</label>
                <button className="form-control btn btn-primary" type="button" onClick={exportPress}>
                  Exportar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PoupaconsigProposalsBank
