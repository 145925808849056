import { useDispatch, useSelector } from "react-redux"
import { LOGOUT } from "../redux/Actions"

function TopNav() {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const logout = () => {
    dispatch({
      type: LOGOUT,
    })
  }

  return (
    <nav className="navbar navbar-top navbar-expand navbar-light bg-secondary border-bottom">
      <div className="container-fluid">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {/* Navbar links */}
          {true && (
            <ul className="navbar-nav align-items-center ml-md-auto">
              <li className="nav-item d-xl-none">
                {/* Sidenav toggler */}
                <div
                  className="pr-3 sidenav-toggler sidenav-toggler-light active"
                  data-action="sidenav-pin"
                  data-target="#sidenav-main"
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </li>
            </ul>
          )}
          <ul className="navbar-nav align-items-center ml-auto ml-md-0">
            <li className="nav-item dropdown">
              <a
                className="nav-link pr-0"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="media align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="Image placeholder"
                      src={`https://ui-avatars.com/api/?name=${user.nome
                        .split(/(\s).+\s/)
                        .join("")
                        .replaceAll(" ", "+")}&background=3498db&color=fff`}
                    />
                  </span>
                  <div className="media-body ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm  font-weight-bold">{user.nome}</span>
                  </div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <div className="dropdown-header noti-title">
                  <h6 className="text-overflow m-0">Bem-vindo!</h6>
                </div>
                <div className="dropdown-divider" />
                <button onClick={logout} type="button" className="dropdown-item">
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default TopNav
