import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

// Plugins
import { toast } from "react-toastify"
import axios from "axios"
import Swal from "sweetalert2";

// Componentes
import TableV2 from "../components/TableV2"
import { SelectForm } from "../components/Select"
import { Modal } from "../components/Modal"

// Redux
import { LOGOUT, SET_TITLE } from "../redux/Actions"

// Utils
import { pm } from "../utils"

// Hook Form
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

const https = require("https")
const { REACT_APP_SERVIDOR } = process.env

const hookFormSchema = yup
   .object({
      id: yup.string().nullable(),
      siglas: yup.mixed().required("Campo obrigatório")
   })
   .required()

const valoresPadroes = {
   id: "",
   siglas: ""
}

export function SiglasCompartilhadas(props) {
   const [permitido, setPermitido] = useState(false)
   const [totaisSiglasCompartilhadas, setTotaisSiglasCompartilhadas] = useState(null)
   const [siglasParaCompartilhar, setSiglasParaCompartilhar] = useState(null)
   const [filtro, setFiltro] = useState(null)

   const user = useSelector((state) => state.user)
   const table = useRef()

   const dispatch = useDispatch()

   const {
      reset,
      setValue,
      control,
      handleSubmit,
      formState: { errors },
   } = useForm({ resolver: yupResolver(hookFormSchema) })

   const colunas = [
      {
         id: "sigla",
         name: "Sigla",
         cell: (row) => <strong>{row.sigla}</strong>
      },
      {
         id: "ativo",
         name: "Status",
         cell: (row) => (
            <span className="badge badge-dot mr-4">
               <i className={`bg-${row.ativo ? "success" : "danger"}`}></i>
               <span style={{ color: "#525f7f" }} className="status">
                  {row.ativo ? "Ativo" : "Inativo"}
               </span>
            </span>
         )
      },
      {
         id: "ultima_alteracao",
         name: "Última Alteração",
         cell: (row) => <span>{row.ultima_alteracao}</span>
      },
   ]

   function checarPermissao() {
      if (!pm(user, 2)) {
         props.history.push("/")
         toast.error("Você não tem permissão para acessar esta página.")
      } else if ([74].includes(user.id)) {
         props.history.push("/")
         toast.error("Você não tem permissão para acessar esta página.")
      } else {
         setPermitido(true)
         dispatch({ type: SET_TITLE, payload: "Poupanegócios > Siglas Compartilhadas" })
      }
   }

   async function listarTotaisSiglasCompartilhadas() {
      try {
         const response = await axios.get(`${REACT_APP_SERVIDOR}siglas-compartilhadas/dashboard/totais`, {
            headers: { token: user.token },
            httpsAgent: new https.Agent({
               rejectUnauthorized: false,
            }),
         })

         const { status, data } = response

         if (status === 201) {
            setTotaisSiglasCompartilhadas(data)
         } else {
            toast.error("Ocorreu um erro ao listar as informações dos totais das siglas compartilhadas")
         }
      } catch (error) {
         console.error(error)

         if (error && error.response && error.response.status == 403) {
            dispatch({ type: LOGOUT })

            if (!toast.isActive("session")) {
               toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
            }
         }
      }
   }

   async function listarSiglasParaCompartilhar() {
      try {
         const response = await axios.get(`${REACT_APP_SERVIDOR}siglas-compartilhadas/listar-siglas-para-compartilhar`, {
            headers: { token: user.token },
            httpsAgent: new https.Agent({
               rejectUnauthorized: false,
            }),
         })

         const { status, data } = response

         if (status === 201) {
            setSiglasParaCompartilhar(data)
         } else {
            toast.error("Ocorreu um erro ao listar as siglas livres para compartilhar")
         }
      } catch (error) {
         console.error(error)

         if (error && error.response && error.response.status == 403) {
            dispatch({ type: LOGOUT })

            if (!toast.isActive("session")) {
               toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
            }
         }
      }
   }

   async function salvarSiglasCompartilhadas(dados) {
      try {
         const response = await axios.post(`${REACT_APP_SERVIDOR}siglas-compartilhadas/salvar`, {
            siglas: dados.siglas,
            usuarioId: user.id
         }, {
            headers: { token: user.token },
            httpsAgent: new https.Agent({
               rejectUnauthorized: false,
            }),
         })

         if (response.data && response.status === 201) {
            toast.success("Sigla(s) inserida(s) com sucesso")

            window.$("#modal-formulario-sigla-compartilhada").modal("hide")

            reset(valoresPadroes)
            listarTotaisSiglasCompartilhadas()

            if (table) {
               table.current.refresh()
            }
         } else {
            toast.error("Ocorreu um erro ao salvar a(s) sigla(s)")
         }
      } catch (error) {
         console.error(error)

         if (error && error.response && error.response.status == 403) {
            dispatch({ type: LOGOUT })

            if (!toast.isActive("session")) {
               toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
            }
         }
      }
   }

   async function toggleStatusSiglaCompartilhada(siglaId, sigla, status) {
      const urlStatus = status ? "/inativar" : "/ativar"

      const tituloStatus = status ? "inativar" : "ativar"
      const textoStatus = status ? "inativada" : "ativada"

      const swalWithBootstrapButtons = Swal.mixin({
         customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-secondary'
         },
         buttonsStyling: false
      })

      try {
         swalWithBootstrapButtons.fire({
            title: `Deseja ${tituloStatus} a sigla ${sigla}?`,
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Sim, quero ${tituloStatus}`,
            cancelButtonText: "Não, cancelar",
            reverseButtons: true
         }).then(async (result) => {
            if (result.isConfirmed) {
               const response = await axios.patch(`${REACT_APP_SERVIDOR}siglas-compartilhadas${urlStatus}`, {
                  siglaId,
                  sigla
               }, {
                  headers: { token: user.token },
                  httpsAgent: new https.Agent({
                     rejectUnauthorized: false,
                  }),
               })

               const { status, data } = response

               if (status === 201 && data.retorno) {
                  swalWithBootstrapButtons.fire(
                     "Sucesso",
                     `A sigla ${sigla} foi ${textoStatus}`,
                     "success"
                  )

                  listarTotaisSiglasCompartilhadas()

                  if (table) {
                     table.current.refresh()
                  }
               } else {
                  toast.error("Ocorreu um erro ao alterar o status da sigla")
               }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
               swalWithBootstrapButtons.fire(
                  "Ação cancelada",
                  "",
                  "info"
               );
            }
         })
      } catch (error) {
         console.error(error)

         if (error && error.response && error.response.status == 403) {
            dispatch({ type: LOGOUT })

            if (!toast.isActive("session")) {
               toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
            }
         }
      }
   }

   useEffect(() => {
      checarPermissao()
      listarTotaisSiglasCompartilhadas()
   }, [])

   if (!permitido) {
      return null
   }

   return (
      <main className="container-fluid mt--6">
         <section id="cards" className="row">
            {
               totaisSiglasCompartilhadas &&
               totaisSiglasCompartilhadas.dashboard &&
               totaisSiglasCompartilhadas.dashboard.map((item, index) => (
                  <div key={index} className="col-xl-4 col-md-12">
                     <div className="card card-stats">
                        <div class="card-body">
                           <div class="row">
                              <div class="col">
                                 <h5 class="card-title text-uppercase text-muted mb-0">{item.title}</h5>
                                 <span class="h2 font-weight-bold mb-0">{item.value}</span>
                              </div>
                              <div class="col-auto">
                                 <div className={`
                                    icon icon-shape bg-gradient-${item.color || "red"} text-white rounded-circle shadow
                                 `}>
                                    <i className={item.icon} />
                                 </div>
                              </div>
                           </div>
                           <p className="mt-3 mb-0 text-sm">
                              <span className="text-default mr-2">
                                 <i
                                    style={{ color: item.percent ? undefined : "white" }}
                                    className="fa fa-calculator"
                                 />{" "}
                                 {item.percent}
                              </span>
                              {item.percent && <span className="text-nowrap">Percentual</span>}
                           </p>
                        </div>
                     </div>
                  </div>
               ))
            }
         </section>

         <section id="table" className="row">
            <div className="col-md-12">
               <div className="card">
                  <div className="card-header border-0">
                     <div className="row">
                        <div className="col-5">
                           <h3 className="mb-0">Lista</h3>
                        </div>
                        <div className="col-3 text-right">
                           <button
                              type="button"
                              data-toggle="modal"
                              data-target="#modal-formulario-sigla-compartilhada"
                              className="btn btn-sm btn-primary btn-round btn-icon"
                           >
                              <span className="btn-inner--icon">
                                 <i className="fas fa-plus" />
                              </span>
                              <span className="btn-inner--text" onClick={listarSiglasParaCompartilhar}>
                                 Adicionar
                              </span>
                           </button>
                        </div>
                        <div className="col-4 text-center">
                           <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder="Pesquisar..."
                              aria-controls="datatable-basic"
                              onChange={(e) => setFiltro(e.target.value)}
                           />
                        </div>
                     </div>
                  </div>

                  <TableV2
                     ref={table}
                     table={"poupanegocios/siglas-compartilhadas"}
                     columns={[
                        ...colunas,
                        {
                           id: "actions",
                           name: "",
                           cell: (row) => (
                              <div>
                                 <a
                                    href="#!"
                                    className="table-action"
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    title={row.ativo ? "Inativar" : "Ativar"}
                                    onClick={() => toggleStatusSiglaCompartilhada(row.login_id, row.sigla, row.ativo)}
                                 >
                                    {row.ativo ? (
                                       <i class="fas fa-solid fa-pause"></i>
                                    ) : (
                                       <i class="fas fa-solid fa-play"></i>
                                    )}
                                 </a>
                              </div>
                           )
                        },
                     ]}
                     filter={filtro}
                     column={"ativo"}
                  />
               </div>
            </div>
         </section>

         {/* Modal - Formulário Sigla Compartilhada */}
         <Modal
            id={"modal-formulario-sigla-compartilhada"}
            titulo={"Siglas Compartilhadas"}
            tamanho={"md"}
            form={
               <div>
                  <div class="form-group">
                     <SelectForm
                        label="Siglas"
                        name="siglas"
                        control={control}
                        options={siglasParaCompartilhar}
                        placeholder="Selecione a sigla"
                        isMulti
                        error={
                           errors.siglas &&
                           errors.siglas.message
                        }
                        backspaceRemovesValue
                     />
                  </div>
                  <div className="form-group text-right">
                     <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => reset(valoresPadroes)}
                     >
                        Fechar
                     </button>
                     <button
                        type="button"
                        class="btn btn-primary"
                        onClick={handleSubmit(salvarSiglasCompartilhadas)}
                     >
                        Salvar
                     </button>
                  </div>
               </div>
            }
         />
      </main>
   )
}
