import React from "react"

export function Modal(props) {
   const { id, form, titulo, tamanho, full = false, onSubmit = false } = props

   return (
      <div
         id={id}
         className="modal fade"
         tabindex="-1"
         role="dialog"
         aria-hidden="true"
      >
         <div className={`modal-dialog modal-dialog-centered modal-${tamanho}`} role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">{titulo}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">×</span>
                  </button>
               </div>
               <div class="modal-body">
                  {onSubmit && (
                     <form onSubmit={onSubmit} role="form">
                        {form}
                     </form>
                  )}

                  {!onSubmit && <form role="form">{form}</form>}
               </div>
            </div>
         </div>
      </div>
   )
}