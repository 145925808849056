import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import TableV2 from "../components/TableV2"
import { LOGOUT, SET_TITLE } from "../redux/Actions"
import { pm } from "../utils"

const https = require("https")
const { REACT_APP_SERVIDOR } = process.env

const columns = [
  {
    id: "banco_id",
    name: "Banco",
    sortable: true,
    cell: row => <img className="img-icon-sm" src={`/assets/img/icons/banks/${row.banco.toLowerCase()}.png`} />,
    width: 100,
  },
  {
    id: "master",
    name: "Master",
    cell: (row) => (
      <span className="badge badge-dot mr-4">
        <i className={`bg-${row.master ? "success" : "danger"}`}></i>
        <span style={{ color: "#525f7f" }} className="status">
          <strong>{row.master ? "Sim" : "Não"}</strong>
        </span>
      </span>
    ),
    sortable: true,
  },
  {
    id: "ativo",
    name: "Status",
    sortable: true,
    cell: row => (
      <span className="badge badge-dot mr-4">
        <i className={`bg-${row.ativo ? "success" : "danger"}`}></i>
        <span style={{ color: "#525f7f" }} className="status">
          {row.ativo ? "Ativo" : "Inativo"}
        </span>
      </span>
    ),
  },
  { id: "usuario", name: "Usuário", sortable: true, cell: row => <b style={{ color: "#525f7f" }}>{row.usuario}</b> },
  { id: "senha", name: "Senha", selector: "senha" },
  { id: "robos", name: "Aplicações", selector: "robos" },
  { id: "atualizado_em", name: "Atualizado Em", sortable: true, selector: "att" },
  { id: "usado_em", name: "Usado Em", sortable: true, selector: "uso" },
]

function Credentials(props) {
  const user = useSelector(state => state.user)
  const [permitted, setPermitted] = useState(false)
  const [filter, setFilter] = useState(null)
  const [selected, setSelected] = useState(null)
  const [password, setPassword] = useState("")
  const [active, setActive] = useState("")
  const [checkedError, setCheckedError] = useState(false)
  const [textError, setTextError] = useState("")
  const [checkedMaster, setCheckedMaster] = useState(false);

  const table = useRef()
  const dispatch = useDispatch()

  const checkPermission = () => {
    if (!pm(user, 2)) {
      props.history.push("/")
      toast.error("Você não tem permissão para acessar esta página.")
    } else if ([74].includes(user.id)) {
      props.history.push("/")
      toast.error("Você não tem permissão para acessar esta página.")
    } else {
      setPermitted(true)
      dispatch({ type: SET_TITLE, payload: "Credenciais" })
    }
  }

  useEffect(() => {
    checkPermission()
  }, [])

  const onClick = row => {
    setSelected(row)
    setActive(row.ativo)
    setPassword(row.senha)
    setTextError(row.texto ? row.texto : "")
    setCheckedError(row.erro)
    setCheckedMaster(row.master)
  }

  const onReset = row => {
    setSelected(null)
    setActive("")
    setPassword("")
    setTextError("")
    setCheckedError(false)
    setCheckedMaster(false)
  }

  const handleOnChange = () => {
    setCheckedError(!checkedError);
  };

  const handleOnChangeMaster = () => {
    setCheckedMaster(!checkedMaster);
  }

  const onSave = async () => {
    const aux = selected.usuario + ""

    toast.promise(
      () => {
        return new Promise(async (resolve, reject) => {
          try {
            await axios.post(
              `${REACT_APP_SERVIDOR}geral/login/atualizar`,
              {
                login: selected.usuario,
                senha: password,
                ativo: active,
                erro: checkedError,
                texto_erro: textError,
                master: checkedMaster
              },
              {
                headers: { token: user.token },
                httpsAgent: new https.Agent({
                  rejectUnauthorized: false,
                }),
              }
            )

            if (table) {
              table.current.refresh()
            }

            resolve()
          } catch (err) {
            console.warn(err)
            reject(err)

            if (err && err.response && err.response.status == 403) {
              dispatch({ type: LOGOUT })
              if (!toast.isActive("session")) {
                toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
              }
            }
          }
        })
      },
      {
        pending: `Atualizando credencial ${aux}...`,
        success: `Credencial ${aux} atualizada com sucesso!`,
        error: `Falha ao atualizar a credencial ${aux}. Verifique sua conexão e tente novamente.`,
      }
    )
  }

  if (!permitted) {
    return null
  }

  return (
    <div className="container-fluid mt--6">
      <div className="card">
        {/* Card header */}
        <div className="card-header border-0">
          <div className="row">
            <div className="col-9">
              <h3 className="mb-0">Tabela</h3>
            </div>
            <div className="col-3 text-right">
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder="Pesquisar usuário..."
                aria-controls="datatable-basic"
                onChange={e => setFilter(e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* Light table */}
        <TableV2
          ref={table}
          filter={filter}
          table={"credenciais"}
          columns={[
            ...columns,
            {
              id: "actions",
              name: "",
              cell: row => (
                <a
                  href="#!"
                  onClick={() => onClick(row)}
                  className="table-action"
                  data-toggle="modal"
                  data-target="#modal-form"
                >
                  <i className="fas fa-edit" />
                </a>
              ),
            },
          ]}
        />
      </div>
      <div
        className="modal fade"
        id="modal-form"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modal-form"
        style={{ display: "none" }}
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal- modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="card bg-secondary border-0 mb-0">
                <div className="card-body px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <b>{selected && selected.usuario}</b>
                  </div>
                  <form role="form">
                    <div className="form-group">
                      <div className="custom-control custom-checkbox mb-3">
                        <input
                          type="checkbox"
                          id="checkedMaster"
                          className="custom-control-input"
                          checked={checkedMaster}
                          onChange={handleOnChangeMaster}
                        />
                        <label className="custom-control-label" htmlFor="checkedMaster">Sigla Master</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">Senha</label>
                      <div className="input-group input-group-merge input-group-alternative">
                        <input
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">Status</label>
                      <select
                        value={active ? "1" : "0"}
                        onChange={e => setActive(e.target.value == 1 ? true : false)}
                        className="form-control"
                      >
                        <option value="1">Ativo</option>
                        <option value="0">Inativo</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox mb-3">
                        <input
                          type="checkbox"
                          id="hasErrorSigla"
                          className="custom-control-input"
                          checked={checkedError}
                          onChange={handleOnChange}
                        />
                        <label className="custom-control-label" htmlFor="hasErrorSigla">Sigla contém erro?</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">Messagem de erro</label>
                      <div className="input-group input-group-merge input-group-alternative">
                        <input
                          type="text"
                          className="form-control"
                          value={textError}
                          onChange={e => setTextError(e.target.value)}
                          disabled={!checkedError ? true : false}
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <button onClick={onSave} type="button" className="btn btn-primary my-4" data-dismiss="modal">
                        Salvar
                      </button>
                      <button
                        onClick={onReset}
                        type="button"
                        className="btn btn-default text-white ml-auto"
                        data-dismiss="modal"
                      >
                        Cancelar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Credentials
