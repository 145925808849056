import "./TableV2.css"

import axios from "axios"
import React, { useEffect, useImperativeHandle, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ReactTable from "react-table"
import { toast } from "react-toastify"

import { LOGOUT } from "../redux/Actions"

const https = require("https")
const { REACT_APP_SERVIDOR } = process.env

const TableV2 = React.forwardRef((props, ref) => {
  const [initialized, setInitialized] = useState(false)
  const [data, setData] = useState([])
  const [pages, setPages] = useState(null)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [sortArr, setSortArr] = useState([])
  const [loading, setLoading] = useState(true)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      const aux = localStorage.getItem(`@table-config/${props.table}`)

      if (aux) {
        setSortArr(JSON.parse(aux))
        requestData(JSON.parse(aux))
      } else {
        requestData()
      }
    } else {
      requestData()
    }
  }, [page, pageSize, sortArr])

  useEffect(() => {
    if (props.filter != null) {
      const delayDebounceFn = setTimeout(async () => {
        try {
          requestData()
        } catch (err) {
          if (err && err.response && err.response.status == 403) {
            dispatch({ type: LOGOUT })
            if (!toast.isActive("session")) {
              toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
            }
          }
        }
      }, 1500)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [props.filter])

  useImperativeHandle(ref, () => ({
    refresh: requestData,
  }))

  const requestData = (st = sortArr) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)

        let url = `${REACT_APP_SERVIDOR}tabela/${props.table}?id=${user.id}&parceiro=${user.parceiro
          }&cargos=${JSON.stringify(user.cargos)}`

        if (page) {
          url += `&page=${page}`
        }

        if (pageSize) {
          url += `&size=${pageSize}`
        }

        if (props.column) {
          url += `&column=${props.column}`
        } else if (st[0]) {
          url += `&column=${st[0].id}`
        }

        if (st[0]) {
          url += `&sort=${st[0].desc ? "desc" : "asc"}`
        }

        if (props.filter) {
          url += `&filter=${encodeURI(props.filter || "")}`
        }

        const response = await axios.get(url, {
          headers: { token: user.token },
          httpsAgent: new https.Agent({
            rejectUnauthorized: false,
          }),
        })
        setData(response.data.data)
        setPages(Math.ceil(response.data.total / pageSize))
        setLoading(false)
        resolve()
      } catch (err) {
        reject(err)
      }
    })
  }

  const initCap = value => {
    return value.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m) {
      return m.toUpperCase()
    })
  }

  return (
    <ReactTable
      columns={props.columns.map(x => ({
        id: x.id,
        Header: x.name,
        accessor: x.cell || x.selector,
        sortable: x.sortable || false,
        className: "text-center",
        width: x.width,
      }))}
      multiSort={false}
      filterable={false}
      showPaginationTop={false}
      pageSizeOptions={[5, 10, 20, 25, 50]}
      defaultPageSize={10}
      sorted={sortArr}
      manual // Forces table not to paginate or sort automatically, so we can handle it server-side
      data={data}
      pages={pages} // Display the total number of pages
      loading={loading} // Display the loading overlay when we need it
      onPageChange={index => setPage(index + 1)}
      onPageSizeChange={(pageSize, index) => {
        setPage(index + 1)
        setPageSize(pageSize)
      }}
      onSortedChange={newSorted => {
        localStorage.setItem(`@table-config/${props.table}`, JSON.stringify(newSorted))
        setSortArr(newSorted)
      }}
      className="-striped -highlight"
      previousText={"Anterior"}
      nextText={"Próxima"}
      loadingText={"Carregando..."}
      noDataText={"Nenhum dado encontrado."}
      pageText={"Página"}
      ofText={"de"}
      rowsText={"linhas"}
    />
  )
})

export default TableV2
