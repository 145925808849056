import "./Live.css"

import axios from "axios"
import datalabels from "chartjs-plugin-datalabels"
import moment from "moment"
import { useEffect, useState } from "react"
import { Chart, Doughnut, Line } from "react-chartjs-2"
import Loader from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import ReactTable from "react-table"
import Status from "../components/Status"
import { LOGOUT, SET_TITLE } from "../redux/Actions"
import { diff, dynamicColors, f, formatName, pm, capitalize } from "../utils"

const https = require("https")
const { REACT_APP_SERVIDOR } = process.env
Chart.register(datalabels)
const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
    },
    datalabels: null,
  },
}

function PoupaconsigMetricsCSG(props) {
  const user = useSelector(state => state.user)
  const [list, setList] = useState([])
  const [permitted, setPermitted] = useState(false)
  const [ids, setIds] = useState(null)
  const dispatch = useDispatch()

  const checkPermission = () => {
    if (!pm(user, 5)) {
      props.history.push("/")
      toast.error("Você não tem permissão para acessar esta página.")
    } else {
      setPermitted(true)
      dispatch({ type: SET_TITLE, payload: "Poupanegócios > Métricas CSG" })
    }
  }

  const ft = x => (ids && ids.length == 0) || ids || ids.includes(x.id + x.parceiro.toString())

  const getInitial = async () => {
    try {
      setList([])

      const req1 = await axios.get(
        `${REACT_APP_SERVIDOR}poupaconsig/lista-ids-permitidos?id=${user.id}&parceiro=${user.parceiro}`,
        {
          headers: { token: user.token },
          httpsAgent: new https.Agent({
            rejectUnauthorized: false,
          }),
        }
      )

      const req2 = await axios.get(`${REACT_APP_SERVIDOR}poupaconsig/lista`, {
        headers: { token: user.token },
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
        }),
      })

      if (req1.data.length > 0) {
        const aux = req1.data.map(x => x.id + x.parceiro.toString())
        setIds(aux)
        setList(
          req2.data
            .filter(x => aux.includes(x.id + x.parceiro.toString()))
            .filter(x => x.metrics)
            .sort((a, b) => b.metrics.Timestamp - a.metrics.Timestamp)
        )
      } else {
        setIds([])
        setList(req2.data.filter(x => x.metrics).sort((a, b) => b.metrics.Timestamp - a.metrics.Timestamp))
      }
    } catch (err) {
      console.warn(err)
      if (err && err.response && err.response.status == 403) {
        dispatch({ type: LOGOUT })
        if (!toast.isActive("session")) {
          toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
        }
      }
    }
  }

  // Enviar requisição para as máquinas online:
  useEffect(() => {
    try {
      checkPermission()
      getInitial()
    } catch (err) {
      console.warn(err)
    }
  }, [])

  const avgArr = field => {
    const aux = list.filter(ft).map(x => x.metrics[field])
    let sum = 0

    for (let i = 0; i < aux.length; i++) {
      sum += aux[i]
    }

    const result = sum / aux.length

    return list.filter(ft).map(() => result)
  }

  if (!permitted) {
    return null
  }

  return (
    <div className="container-fluid mt--6">
      {ids != null && (
        <div className="row card-wrapper">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h6 className="card-title text-uppercase text-muted mb-0">Layout (elementos da tela)</h6>
              </div>
              <div className="card-body">
                <Line
                  options={{
                    ...defaultOptions,
                    scales: {
                      x: {
                        ticks: {
                          display: false,
                        },
                      },
                    },
                  }}
                  data={{
                    labels: list
                      .filter(ft)
                      .filter(x => x.metrics)
                      .map(x => x.usuario + " - " + (x.url ? x.url : "*")),
                    datasets: [
                      {
                        label: "Tempo de Carregamento (Segundos)",
                        data: list.filter(ft).map(x => x.metrics.LayoutDuration),
                        hoverOffset: 4,
                        backgroundColor: "blue",
                      },
                      {
                        label: "Média",
                        data: avgArr("LayoutDuration"),
                        hoverOffset: 4,
                        backgroundColor: "gray",
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h6 className="card-title text-uppercase text-muted mb-0">Scripts</h6>
              </div>
              <div className="card-body">
                <Line
                  options={{
                    ...defaultOptions,
                    scales: {
                      x: {
                        ticks: {
                          display: false,
                        },
                      },
                    },
                  }}
                  data={{
                    labels: list
                      .filter(ft)
                      .filter(x => x.metrics)
                      .map(x => x.usuario + " - " + (x.url ? x.url : "*")),
                    datasets: [
                      {
                        label: "Tempo de Carregamento (Segundos)",
                        data: list.filter(ft).map(x => x.metrics.ScriptDuration),
                        hoverOffset: 4,
                        backgroundColor: "orange",
                      },
                      {
                        label: "Média",
                        data: avgArr("ScriptDuration"),
                        hoverOffset: 4,
                        backgroundColor: "gray",
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h6 className="card-title text-uppercase text-muted mb-0">Tasks Async</h6>
              </div>
              <div className="card-body">
                <Line
                  options={{
                    ...defaultOptions,
                    scales: {
                      x: {
                        ticks: {
                          display: false,
                        },
                      },
                    },
                  }}
                  data={{
                    labels: list
                      .filter(ft)
                      .filter(x => x.metrics)
                      .map(x => x.usuario + " - " + (x.url ? x.url : "*")),
                    datasets: [
                      {
                        label: "Tempo de Carregamento (Segundos)",
                        data: list.filter(ft).map(x => x.metrics.TaskDuration),
                        hoverOffset: 4,
                        backgroundColor: "red",
                      },
                      {
                        label: "Média",
                        data: avgArr("TaskDuration"),
                        hoverOffset: 4,
                        backgroundColor: "gray",
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h6 className="card-title text-uppercase text-muted mb-0">Tempo de Processamento em Segundos</h6>
              </div>
              <ReactTable
                columns={[
                  {
                    id: "url",
                    Header: "Url",
                    accessor: "url",
                    sortable: false,
                    width:800
                  },
                  {
                    id: "layout",
                    Header: "Layout",
                    accessor: x => x.metrics.LayoutDuration,
                    sortable: true,
                    className: "text-center",
                  },
                  {
                    id: "script",
                    Header: "Script",
                    accessor: x => x.metrics.ScriptDuration,
                    sortable: false,
                    className: "text-center",
                  },
                  {
                    id: "tasks",
                    Header: "Tasks",
                    accessor: x => x.metrics.TaskDuration,
                    sortable: true,
                    className: "text-center",
                  },
                ]}
                multiSort={false}
                filterable={false}
                showPagination={false}
                manual
                data={list.filter(ft).filter(x => x.url)}
                className="-striped -highlight"
                previousText={"Anterior"}
                nextText={"Próxima"}
                loadingText={"Carregando..."}
                noDataText={"Nenhum dado encontrado."}
                pageText={"Página"}
                ofText={"de"}
                rowsText={"linhas"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PoupaconsigMetricsCSG
