import "./Live.css"

import axios from "axios"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import Iframe from "../components/Iframe"
import { LOGOUT, SET_TITLE } from "../redux/Actions"
import { pm } from "../utils"

const https = require("https")
const { REACT_APP_SERVIDOR } = process.env

function MapCSG(props) {
  const user = useSelector(state => state.user)
  const [permitted, setPermitted] = useState(false)
  const [list, setList] = useState([])
  const dispatch = useDispatch()

  const checkPermission = () => {
    if (!pm(user, 12)) {
      props.history.push("/")
      toast.error("Você não tem permissão para acessar esta página.")
    } else {
      setPermitted(true)
      dispatch({ type: SET_TITLE, payload: "Poupanegócios > Mapeamento do CSG" })
    }
  }

  const getInitial = async () => {
    try {
      const req = await axios.get(`${REACT_APP_SERVIDOR}poupaconsig/mapeamento-csg`, {
        headers: { token: user.token },
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
        }),
      })
      setList(
        req.data.map(x => ({
          ...x,
          html: x.html.replace(
            "../../../App_Themes/SANTANDER2/estilo.css",
            `${process.env.PUBLIC_URL}/assets/css/estilonovo.css`
          ),
        }))
      )
    } catch (err) {
      if (err && err.response && err.response.status == 403) {
        dispatch({ type: LOGOUT })
        if (!toast.isActive("session")) {
          toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
        }
      }
    }
  }

  // Enviar requisição para as máquinas online:
  useEffect(() => {
    try {
      checkPermission()
      getInitial()
    } catch (err) {
      console.warn(err)
    }
  }, [])

  if (!permitted) {
    return null
  }

  return (
    <div className="container-fluid mt--6">
      <div className="row card-wrapper">
        <div className="col-xl-12">
          {list.map(x => (
            <div key={x.id} className="card">
              {/* Card header */}
              <div className="card-header border-0">
                <div className="row">
                  <div className="col-12">
                    <h3 className="mb-0">Colhida em {x.cri}</h3>
                    <p>{x.url}</p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <Iframe content={x.html} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MapCSG
