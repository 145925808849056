import axios from "axios"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import { LOGOUT, SET_TITLE } from "../redux/Actions"
import { pm } from "../utils"

const https = require("https")
const { REACT_APP_SERVIDOR } = process.env

function PoupaconsigConfig(props) {
  const user = useSelector(state => state.user)
  const [permitted, setPermitted] = useState(false)
  const [sessions, setSessions] = useState(null)
  const [away, setAway] = useState(null)
  const [message, setMessage] = useState("")
  const [title, setTitle] = useState("")
  const dispatch = useDispatch()

  const checkPermission = () => {
    if (!pm(user, 16)) {
      props.history.push("/")
      toast.error("Você não tem permissão para acessar esta página.")
    } else {
      setPermitted(true)
      dispatch({ type: SET_TITLE, payload: "Poupanegócios > Configurações" })
      getInitial()
    }
  }

  useEffect(() => {
    checkPermission()
  }, [])

  const getInitial = async () => {
    const req = await axios.get(`${REACT_APP_SERVIDOR}poupaconsig/configuracoes`, {
      headers: { token: user.token },
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
    })

    if (req.data) {
      setAway(req.data.ausencia)
      setSessions(req.data.sessoes)
    }
  }

  const onSendMsg = async () => {
    let valid = true

    if (title == null || title.length < 3) {
      valid = false
      toast.warning("Informe um título válido.")
    } else if (message == null || message.length < 3) {
      valid = false
      toast.warning("Informe uma mensagem válida.")
    }

    if (valid) {
      toast.promise(
        () => {
          return new Promise(async (resolve, reject) => {
            try {
              await axios.post(
                `${REACT_APP_SERVIDOR}poupaconsig/enviar-notificacoes`,
                { titulo: title, mensagem: message },
                {
                  headers: { token: user.token },
                  httpsAgent: new https.Agent({
                    rejectUnauthorized: false,
                  }),
                }
              )

              setTitle("")
              setMessage("")

              resolve()
            } catch (err) {
              console.warn(err)
              reject(err)

              if (err && err.response && err.response.status == 403) {
                dispatch({ type: LOGOUT })
                if (!toast.isActive("session")) {
                  toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
                }
              }
            }
          })
        },
        {
          pending: `Enviando notificação...`,
          success: `Notificação com sucesso!`,
          error: `Falha ao enviar notificação. Verifique sua conexão e tente novamente.`,
        }
      )
    }
  }

  const onReset = async () => {

  }

  const onSave = async () => {
    let valid = true

    if (away == null || away <= 0) {
      valid = false
      toast.warning("Informe um tempo de ausência válido (precisa ser maior que 0).")
    } else if (sessions == null || sessions <= 0) {
      valid = false
      toast.warning("Informe uma quantidade de sessões válida (precisa ser maior que 0).")
    }

    if (valid) {
      toast.promise(
        () => {
          return new Promise(async (resolve, reject) => {
            try {
              await axios.post(
                `${REACT_APP_SERVIDOR}poupaconsig/atualizar-configuracoes`,
                { ausencia: away, sessoes: sessions },
                {
                  headers: { token: user.token },
                  httpsAgent: new https.Agent({
                    rejectUnauthorized: false,
                  }),
                }
              )

              resolve()
            } catch (err) {
              console.warn(err)
              reject(err)

              if (err && err.response && err.response.status == 403) {
                dispatch({ type: LOGOUT })
                if (!toast.isActive("session")) {
                  toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
                }
              }
            }
          })
        },
        {
          pending: `Atualizando configurações...`,
          success: `Configurações atualizadas com sucesso!`,
          error: `Falha atualizar configurações. Verifique sua conexão e tente novamente.`,
        }
      )
    }
  }

  if (!permitted) {
    return null
  }

  return (
    <div className="container-fluid mt--6">
      <div className="row">
        <div className="col-md-4">
          <div className="card mb-4">
            {/* Card header */}
            <div className="card-header">
              <h3 className="mb-0">Configurações</h3>
            </div>
            {/* Card body */}
            <div className="card-body">
              {/* Form groups used in grid */}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-control-label" htmlFor="example3cols1Input">
                      Tempo de Ausência (Minutos)
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Tempo de ausência máximo permitido em minutos."
                      min={1}
                      value={away}
                      onChange={e => setAway(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-control-label">Qtd. Sessões Paralelas (Unidade)</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Quantidade de sessões paralelas do mesmo usuário permitidas."
                      min={1}
                      value={sessions}
                      onChange={e => setSessions(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-control-label">Ação</label>
                    <button className="form-control btn btn-primary" type="button" onClick={onSave}>
                      Atualizar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card mb-4">
            {/* Card header */}
            <div className="card-header">
              <h3 className="mb-0">Notificações</h3>
            </div>
            {/* Card body */}
            <div className="card-body">
              {/* Form groups used in grid */}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-control-label" htmlFor="example3cols1Input">
                      Título*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Título da notificação..."
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-control-label">Mensagem*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Mensagem da notificação..."
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-control-label">Ação</label>
                    <button className="form-control btn btn-success" type="button" onClick={onSendMsg}>
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card bg-default shadow mb-4">
            {/* Card header */}
            <div className="card-header bg-transparent border-0">
              <h3 className="text-white mb-0">Robôs</h3>
            </div>
            {/* Card body */}
            <div className="card-body">
              {/* Form groups used in grid */}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="text-white form-control-label">Robô de Coleta Status de Proposta 1</label>
                    <button className="form-control btn btn-danger" type="button" onClick={onSave}>
                      Reiniciar
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="text-white form-control-label">Robô de Coleta Status de Proposta 2</label>
                    <button className="form-control btn btn-danger" type="button" onClick={onSave}>
                      Reiniciar
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="text-white form-control-label">Robô de Checagem Banco (D+1)</label>
                    <button className="form-control btn btn-danger" type="button" onClick={onSave}>
                      Reiniciar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PoupaconsigConfig
