import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import moment from "moment"
import TableV2 from "../components/TableV2"
import { SET_TITLE } from "../redux/Actions"
import { pm } from "../utils"

const { REACT_APP_SERVIDOR } = process.env

const color = (x) => {
	if (x == "Reprovado" || x == "Cancelado") {
		return "red"
	} else if (x == "Integrado") {
		return "green"
	} else if (x == "Andamento") {
		return "orange"
	} else {
		return "blue"
	}
}

const columns = [
	{
		id: "proposta",
		name: "Proposta",
		selector: "proposta",
		sortable: true,
	},
	{
		id: "dados->>'dtBase'",
		name: "Data Base",
		selector: "base",
		sortable: true,
	},
	{
		id: "status",
		name: "Status",
		selector: "status",
		cell: (row) => (
			<span>
				<span style={{ color: color(row.status) }}>● </span>
				<b>{row.status || "-"}</b>
			</span>
		),
		sortable: true,
	},
	{ id: "ultima_checagem", name: "Última Checagem", selector: "chk", sortable: true },
	{
		id: "usuario->>'parceiro'",
		name: "Tipo",
		cell: (row) => (
			<span>
				<span style={{ color: row.tipo == "Parceiro" ? "blue" : "orange" }}>● </span>
				{row.tipo}
			</span>
		),
		sortable: true,
	},
	{
		id: "iniciais->>'escritorio'",
		name: "Corban",
		selector: "corban",
		sortable: true,
	},
	{
		id: "usuario->>'nome'",
		name: "Responsável",
		selector: "nome",
		cell: (row) => <b>{row.nome}</b>,
		sortable: true,
	},
	{
		id: "usuario->>'cargos'",
		name: "Cargos",
		selector: "esc",
		cell: (row) => row.esc,
		sortable: false,
	},
	{
		id: "dados->>'cliqueUnico'",
		name: "Clique Único",
		selector: "clique",
		cell: (row) => row.clique,
		sortable: true,
	},
	{ id: "dados->>'cpf'", name: "CPF - Cliente", selector: "cpf_cliente" },
	{ id: "dados->>'nome'", name: "Nome - Cliente", selector: "nome_cliente", sortable: true },
	{ id: "tipo", name: "Tipo Proposta", selector: "tipo_proposta", sortable: true },
	{
		id: "versao",
		name: "Versão",
		selector: "versao",
		cell: (row) => (
			<span>
				<span style={{ color: row.versao == "2.0.0" ? "red" : "blue" }}>● </span>
				{row.versao}
			</span>
		),
		sortable: true
	},
	{ id: "iniciais->>'convenio'", name: "Convênio", selector: "convenio", sortable: true },
	{ id: "iniciais->>'regra'", name: "Regra", selector: "regra", sortable: true },
	{
		id: "dados->>'a'",
		name: "Valor",
		selector: "valor",
		sortable: true,
	},
	{ id: "criado_em", name: "Criado Em", selector: "cri", sortable: true },
	{ id: "atualizacao", name: "Modo Atualização", selector: "att", sortable: true },
]

function PoupaconsigProposals(props) {
	const user = useSelector((state) => state.user)
	const [permitted, setPermitted] = useState(false)
	const [dateType, setDateType] = useState(2)
	const [integrated, setIntegrated] = useState(0)
	const [include, setInclude] = useState(0)
	const [filter, setFilter] = useState(null)
	const [start, setStart] = useState(moment().startOf("month"))
	const [end, setEnd] = useState(moment())
	const dispatch = useDispatch()

	const checkPermission = () => {
		if (!pm(user, 15)) {
			props.history.push("/")
			toast.error("Você não tem permissão para acessar esta página.")
		} else {
			setPermitted(true)
			dispatch({ type: SET_TITLE, payload: "Poupanegócios > Propostas" })
		}
	}

	const onDateChange = (data, f) => {
		f(moment(data, "YYYY-MM-DD"))
	}

	const exportPress = () => {
		window.open(
			`${REACT_APP_SERVIDOR}arquivo/excel/propostas?id=${user.id}&parceiro=${user.parceiro
			}&cargos=${JSON.stringify(user.cargos)}&inicio=${start.format("YYYY-MM-DD")}&fim=${end.format(
				"YYYY-MM-DD"
			)}&tipo=${dateType}&incluir=${include}&integrados=${integrated}`
		)
	}

	useEffect(() => {
		checkPermission()
	}, [])

	if (!permitted) {
		return null
	}

	return (
		<div className="container-fluid mt--6">
			<div className="card">
				{/* Card header */}
				<div className="card-header border-0">
					<div className="row">
						<div className="col-9">
							<h3 className="mb-0">Tabela</h3>
						</div>
						<div className="col-3 text-right">
							<input
								type="search"
								className="form-control form-control-sm"
								placeholder="Pesquisar proposta..."
								aria-controls="datatable-basic"
								onChange={(e) => setFilter(e.target.value)}
							/>
						</div>
					</div>
				</div>
				{/* Light table */}
				<TableV2 table={"poupaconsig/propostas"} columns={columns} filter={filter} />
			</div>
			<div className="card mb-4">
				{/* Card header */}
				<div className="card-header">
					<h3 className="mb-0">Exportação de Dados</h3>
				</div>
				{/* Card body */}
				<div className="card-body">
					{/* Form groups used in grid */}
					<div className="row">
						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Tp. Data</label>
								<select
									className="form-control"
									value={dateType}
									onChange={(e) => setDateType(e.target.value)}
								>
									<option value={2}>Data Base</option>
									<option value={1}>Data de Criação</option>
								</select>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Dt. Início</label>
								<input
									className="form-control"
									type="date"
									defaultValue={start.format("YYYY-MM-DD")}
									onChange={(e) => onDateChange(e.target.value, setStart)}
									max={moment().format("YYYY-MM-DD")}
									min={moment().subtract(65, "days").format("YYYY-MM-DD")}
								/>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Dt. Fim</label>
								<input
									className="form-control"
									type="date"
									defaultValue={end.format("YYYY-MM-DD")}
									onChange={(e) => onDateChange(e.target.value, setEnd)}
									max={moment().format("YYYY-MM-DD")}
									min={moment().subtract(65, "days").format("YYYY-MM-DD")}
								/>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">
									<span>
										Incluir <b>Pre-save</b> e <b>Não Processados</b>?
									</span>
								</label>
								<select
									className="form-control"
									value={include}
									onChange={(e) => setInclude(e.target.value)}
								>
									<option value={0}>Não</option>
									<option value={1}>Sim</option>
								</select>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">
									<span>
										Incluir somente <b>Integrados</b>?
									</span>
								</label>
								<select
									className="form-control"
									value={integrated}
									onChange={(e) => setIntegrated(e.target.value)}
								>
									<option value={1}>Sim</option>
									<option value={0}>Não</option>
								</select>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Ação</label>
								<button
									className="form-control btn btn-primary"
									type="button"
									onClick={exportPress}
								>
									Exportar
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PoupaconsigProposals
