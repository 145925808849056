import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Redux
import { SET_TITLE } from "../redux/Actions";

// Components
import TableV2 from "../components/TableV2";
import { Modal } from "../components/Modal";
import Input from "../components/Input/Input";

// Utils
import { pm } from "../utils";

const https = require("https");
const { REACT_APP_SERVIDOR } = process.env;

const color = (x) => {
   if (x == "Reprovado" || x == "Cancelado") {
      return "red";
   } else if (x == "Integrado") {
      return "green";
   } else if (x == "Andamento") {
      return "orange";
   } else {
      return "blue";
   }
};

const columns = [
   {
      id: "criado_em",
      name: "Criado Em",
      selector: "criado",
      sortable: false,
   },
   {
      id: "proposta",
      name: "Proposta",
      selector: "proposta",
      sortable: false,
   },
   {
      id: "dados_iniciais->>'escritorio'",
      name: "Corban",
      selector: "corban",
      sortable: false,
   },
   {
      id: "usuario->>'nome'",
      name: "Responsável",
      selector: "nome",
      cell: (row) => <b>{row.nome}</b>,
      sortable: false,
   },
   {
      id: "usuario->>'parceiro'",
      name: "Tipo",
      selector: "tipo",
      cell: (row) => (
         <span>
            <span style={{ color: row.tipo == "Parceiro" ? "blue" : "orange" }}>
               ●{" "}
            </span>
            {row.tipo}
         </span>
      ),
      sortable: false,
   },
   {
      id: "dados_csg->>'login'",
      name: "Login",
      selector: "login",
      sortable: false,
   },
   {
      id: "dados->>'cpf'",
      name: "CPF - Cliente",
      selector: "cpf_cliente",
      sortable: false,
   },
   {
      id: "dados->>'nome'",
      name: "Nome - Cliente",
      selector: "nome_cliente",
      sortable: false,
   },
   {
      id: "dados_iniciais->>'convenio'",
      name: "Convênio",
      selector: "convenio",
      sortable: false,
   },
   {
      id: "dados_iniciais->>'regra'",
      name: "Regra",
      selector: "regra",
      sortable: false,
   },
   {
      id: "versao",
      name: "Versão",
      selector: "versao",
      cell: (row) => (
         <span>
            <span style={{ color: row.versao == "2.0.0" ? "red" : "blue" }}>
               ●{" "}
            </span>
            {row.versao}
         </span>
      ),
      sortable: false,
   },
];

export function PoupaconsigPropostasRascunho(props) {
   const user = useSelector((state) => state.user);

   const [permission, setPermission] = useState(false);
   const [filter, setFilter] = useState(null);
   const [rascunho, setRascunho] = useState({});
   const [errors, setErrors] = useState({});

   const dispatch = useDispatch();

   useEffect(() => {
      checkPermission();
   }, []);

   const checkPermission = () => {
      if (!pm(user, 15)) {
         props.history.push("/");
         toast.error("Você não possui permissão para acessar essa página.");
      } else {
         setPermission(true);
         dispatch({
            type: SET_TITLE,
            payload: "Poupanegócios > Propostas (Rascunho)",
         });
      }
   };

   function handleChange(event) {
      const { id, value } = event.target;

      setRascunho({ ...rascunho, [id]: value });
      setErrors((prevErros) => ({ ...prevErros, [id]: "" }));
   }

   function handleBlur(event) {
      const { id, value } = event.target;
      const mensagem = "Campo obrigatório";

      if (!value) {
         setErrors((prevErros) => ({ ...prevErros, [id]: mensagem }));
      }
   }

   function openModalData(data) {
      clearModal();
      setRascunho(data);
      window.$("#modal-data").modal("show");
   }

   async function handleSave(event) {
      event.preventDefault();

      try {
         if (!rascunho.proposta) {
            toast.info("Número da proposta precisa ser preenchido !");
            return;
         }

         toast.promise(
            () => {
               return new Promise(async (resolve, reject) => {
                  try {
                     let response = await axios.post(
                        `${REACT_APP_SERVIDOR}poupaconsig/gravar-proposta`,
                        { ...rascunho },
                        {
                           headers: { token: user.token },
                           httpsAgent: new https.Agent({
                              rejectUnauthorized: false,
                           }),
                        }
                     );

                     response = response.data;
                     if (response.retorno) {
                        resolve(response.mensagem);
                        window.$("#modal-data").modal("hide");
                     } else {
                        reject(response.mensagem);
                     }
                  } catch (err) {
                     console.warn(err);
                     reject(err);
                  }
               });
            },
            {
               pending: "Gravando proposta...",
               success: "Proposta cadastrada com sucesso!",
               error: "Falha ao gravar a proposta, tente novamente.",
            }
         );
      } catch (err) {
         toast.error(err.mensagem ? err.mensagem : err.message);
      }
   }

   function clearModal() {
      setErrors({});
      setRascunho({});
      window.$("#proposta").val("");
   }

   if (!permission) {
      return null;
   }

   return (
      <main>
         <div className="container-fluid mt--6">
            <div className="card">
               <div className="card-header border-0">
                  <div className="row">
                     <div className="col-9">
                        <h3 className="mb-0">Tabela</h3>
                     </div>
                     <div className="col-3 text-right">
                        <input
                           type="search"
                           className="form-control form-control-sm"
                           placeholder="Pesquisar"
                           aria-controls="datatable-basic"
                           onChange={(e) => setFilter(e.target.value)}
                        />
                     </div>
                  </div>
               </div>
               <TableV2
                  table={"poupaconsig/propostas-rascunhos"}
                  columns={[
                     ...columns,
                     {
                        id: "acao",
                        name: "Ação",
                        cell: (row) =>
                           !row.proposta && (
                              <a
                                 href="#!"
                                 className="table-action text-success"
                                 data-toggle="tooltip"
                                 data-placement="left"
                                 title="Salvar Proposta"
                                 onClick={() => openModalData(row)}
                              >
                                 <i className="fas fa-save" />
                              </a>
                           ),
                     },
                  ]}
                  filter={filter}
               />
            </div>
         </div>
         {/* Modal Data - Salvar Proposta */}
         <Modal
            id={"modal-data"}
            titulo={"Salvar Proposta"}
            tamanho={"md"}
            form={
               <div>
                  <div className="col-lg-12">
                     <div className="card">
                        <div className="card-body">
                           <div className="row">
                              <div className="col-12">
                                 <div className="form-group">
                                    <label
                                       htmlFor="proposta"
                                       className="form-control-label"
                                    >
                                       Número Proposta
                                    </label>
                                    <Input
                                       name="proposta"
                                       id="proposta"
                                       className="form-control"
                                       value={rascunho.proposta || null}
                                       placeholder="Ex: 123456789"
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       help={errors.proposta || ""}
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="form-group text-right">
                     <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => clearModal()}
                     >
                        Fechar
                     </button>
                     <button
                        type="submit"
                        class="btn btn-primary"
                        onClick={handleSave}
                     >
                        Salvar
                     </button>
                  </div>
               </div>
            }
         />
      </main>
   );
}
