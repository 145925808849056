import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import TableV2 from "../components/TableV2"

import { LOGOUT, SET_TITLE } from "../redux/Actions"
import { pm } from "../utils"

const https = require("https")
const { REACT_APP_SERVIDOR } = process.env

function Siglas(props) {
	const user = useSelector((state) => state.user)
	const [permitted, setPermitted] = useState(false)
	const [data, setData] = useState(null)
	const [preventivo, setPreventivo] = useState(null)
	const [filter, setFilter] = useState(null)
	const [selected, setSelected] = useState(null)
	const [active, setActive] = useState(1)
	const [cpf, setCpf] = useState(null)
	const [corban, setCorban] = useState(null)
	const [username, setUsername] = useState(null)
	const [password, setPassword] = useState(null)
	const [search, setSearch] = useState(null)
	const [blockCorban, setBlockCorban] = useState(null)
	const [blockSearch, setBlockSearch] = useState(1)
	const [checkedError, setCheckedError] = useState(false)
	const [textError, setTextError] = useState("")
	const [checkedMaster, setCheckedMaster] = useState(false);

	const [listaRobos, setListaRobos] = useState([]);
	const [robosSelecionados, setRobosSelecionados] = useState({});
	const [robosParaVincularNaSigla, setRobosParaVincularNaSigla] = useState([]);
	const [siglaId, setSiglaId] = useState(null);

	const table = useRef()
	const dispatch = useDispatch()

	const columns = [
		{
			id: "master",
			name: "Master",
			cell: (row) => (
				<span className="badge badge-dot mr-4">
					<i className={`bg-${row.master ? "success" : "danger"}`}></i>
					<span style={{ color: "#525f7f" }} className="status">
						<strong>{row.master ? "Sim" : "Não"}</strong>
					</span>
				</span>
			),
			sortable: true,
		},
		{
			id: "ativo",
			name: "Ativo",
			cell: (row) => (
				<span className="badge badge-dot mr-4">
					<i className={`bg-${row.ativo ? "success" : "danger"}`}></i>
					<span style={{ color: "#525f7f" }} className="status">
						{row.ativo ? "Ativo" : "Inativo"}
					</span>
				</span>
			),
			sortable: true,
		},
		{ id: "usuario", name: "Usuário", cell: (row) => <b>{row.usuario}</b>, sortable: true },
		{ id: "senha", name: "Senha", selector: "senha", sortable: false },
		{ id: "robos", name: "Aplicações", selector: "robos" },
		{
			id: "consulta",
			name: "Tipo",
			cell: (row) => <b>{row.consulta ? "Consulta" : "Digitação"}</b>,
			sortable: true,
		},
		{
			id: "preventivo",
			name: "Preventivo",
			cell: (row) => <b>{row.preventivo ? "Sim" : "Não"}</b>,
			sortable: true,
		},
		{ id: "extra->>'cpf'", name: "CPF", selector: "cpf" },
		{ id: "extra->>'escritorio'", name: "Corban", selector: "corban", sortable: true },
		{
			id: "erro",
			name: "Erro",
			sortable: true,
			cell: (row) => (
				<span className="badge badge-dot mr-4">
					<i className={`bg-${row.erro ? "success" : "danger"}`}></i>
					<span style={{ color: "#525f7f" }} className="status">
						{row.erro ? "Sim" : "Não"}
					</span>
				</span>
			),
		},
		{ id: "erro_texto", name: "Texto", cell: (row) => <span>{row.texto || "-"}</span> },
		{ id: "atualizado_em", name: "Atualizado Em", selector: "att", sortable: true },
		{ id: "usado_em", name: "Usado Em", selector: "uso", sortable: true },
	];

	const checkPermission = () => {
		if (!pm(user, 2)) {
			props.history.push("/")
			toast.error("Você não tem permissão para acessar esta página.")
		} else if ([74].includes(user.id)) {
			props.history.push("/")
			toast.error("Você não tem permissão para acessar esta página.")
		} else {
			setPermitted(true)
			dispatch({ type: SET_TITLE, payload: "Poupanegócios > Siglas" })
		}
	}

	const exportarDados = () => {
		try {
			window.open(
				`${REACT_APP_SERVIDOR}arquivo/excel/santander/siglas-rh?id=${user.id}&parceiro=false&&cargos=${JSON.stringify(user.cargos)}&page=1&size=1000`
			)
		} catch (error) {
			toast.error("Ops! Ocorreu um erro ao exportar a lista das siglas 😢")
		}
	}

	const loadData = async () => {
		try {
			const req = await axios.get(`${REACT_APP_SERVIDOR}poupaconsig/dashboard-siglas`, {
				headers: { token: user.token },
				httpsAgent: new https.Agent({
					rejectUnauthorized: false,
				}),
			})
			setData(req.data)
		} catch (err) {
			console.warn(err)

			if (err && err.response && err.response.status == 403) {
				dispatch({ type: LOGOUT })
				if (!toast.isActive("session")) {
					toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
				}
			}
		}
	}

	const cpfMask = (value) => {
		return value
			.replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
			.replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
			.replace(/(\d{3})(\d)/, "$1.$2")
			.replace(/(\d{3})(\d{1,2})/, "$1-$2")
			.replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
	}

	const onClick = (row) => {
		setSelected(row)
		setActive(row.active)
		setActive(row.ativo ? 1 : 0)
		setCpf(row.cpf)
		setCorban(row.corban)
		setUsername(row.usuario)
		setPassword(row.senha)
		setSearch(row.consulta)
		setPreventivo(row.preventivo)
		setTextError(row.texto ? row.texto : "")
		setCheckedError(row.erro)
		setCheckedMaster(row.master)
	}

	const onReset = async () => {
		setSelected(null)
		setActive(1)
		setCpf(null)
		setCorban(null)
		setUsername(null)
		setPassword(null)
		setSearch(null)
		setPreventivo(null)
		setTextError("")
		setCheckedError(false)
		setCheckedMaster(false)
	}

	const onSave = async () => {
		let valid = true

		if (selected == null) {
			if (username == null || username.length < 4) {
				valid = false
				toast.warning("Informe um usuário válido (precisa conter no mínimo 4 caracteres).")
			}
		}

		if (password == null || password.length < 4) {
			valid = false;
			toast.warning("Informe uma senha válida (precisa conter no mínimo 4 caracteres).");
		}

		if (!checkedMaster) {
			if (corban == null || corban.length != 5) {
				valid = false;
				toast.warning("Informe um corban válido (precisa conter 5 dígitos).");
			} else if (cpf == null || cpf.length != 14) {
				valid = false;
				toast.warning("Informe um CPF válido.");
			}
		}

		if (valid) {
			if (selected) {
				const aux = selected.usuario.toString()
				toast.promise(
					() => {
						return new Promise(async (resolve, reject) => {
							try {
								await axios.post(
									`${REACT_APP_SERVIDOR}poupaconsig/siglas/atualizar`,
									{
										usuario: username,
										senha: password,
										corban,
										cpf,
										ativo: active == "1" ? true : false,
										consulta: search == "1" ? true : false,
										preventivo: preventivo == "1" ? true : false,
										erro: checkedError,
										texto_erro: textError,
										master: checkedMaster
									},
									{
										headers: { token: user.token },
										httpsAgent: new https.Agent({
											rejectUnauthorized: false,
										}),
									}
								)

								if (table) {
									table.current.refresh()
								}

								loadData()
								onReset()
								resolve()

								window.$("#modal-form").modal("hide");
							} catch (err) {
								console.warn(err)
								reject(err)

								if (err && err.response && err.response.status == 403) {
									dispatch({ type: LOGOUT })
									if (!toast.isActive("session")) {
										toast.warning("Sua sessão foi expirada. Faça login novamente!", {
											toastId: "session",
										})
									}
								}
							}
						})
					},
					{
						pending: `Atualizando sigla "${aux}"...`,
						success: `Sigla "${aux}" atualizada com sucesso!`,
						error: `Falha ao atualizar a sigla "${aux}". Verifique sua conexão e tente novamente.`,
					}
				)
			} else {
				try {
					const req = await axios.get(
						`${REACT_APP_SERVIDOR}poupaconsig/siglas/checagem?usuario=${username
							.trim()
							.toUpperCase()}`,
						{
							headers: { token: user.token },
							httpsAgent: new https.Agent({
								rejectUnauthorized: false,
							}),
						}
					)

					if (req.data.total > 0) {
						toast.warning(`Sigla ${username} já existe, não foi possível cadastrá-la.`)
					} else {
						toast.promise(
							() => {
								return new Promise(async (resolve, reject) => {
									try {
										await axios.post(
											`${REACT_APP_SERVIDOR}poupaconsig/siglas/cadastrar`,
											{
												usuario: username,
												senha: password,
												corban,
												cpf,
												ativo: active == "1" ? true : false,
												consulta: search == "1" ? true : false,
												preventivo: preventivo == "1" ? true : false,
												erro: checkedError,
												texto_erro: textError,
												master: checkedMaster
											},
											{
												headers: { token: user.token },
												httpsAgent: new https.Agent({
													rejectUnauthorized: false,
												}),
											}
										)

										if (table) {
											table.current.refresh()
										}

										loadData()
										onReset()
										resolve()

										window.$("#modal-form").modal("hide");
									} catch (err) {
										console.warn(err)
										reject(err)

										if (err && err.response && err.response.status == 403) {
											dispatch({ type: LOGOUT })
											if (!toast.isActive("session")) {
												toast.warning("Sua sessão foi expirada. Faça login novamente!", {
													toastId: "session",
												})
											}
										}
									}
								})
							},
							{
								pending: `Cadastrando nova sigla "${username}"...`,
								success: `Sigla "${username}" cadastrada com sucesso!`,
								error: `Falha ao cadastrar a sigla "${username}". Verifique sua conexão e tente novamente.`,
							}
						)
					}
				} catch (err) {
					if (err && err.response && err.response.status == 403) {
						dispatch({ type: LOGOUT })
						if (!toast.isActive("session")) {
							toast.warning("Sua sessão foi expirada. Faça login novamente!", {
								toastId: "session",
							})
						}
					}
				}
			}
		}
	}

	const onSaveBlock = async () => {
		let valid = true

		if (blockCorban == null || blockCorban.toString().length < 4) {
			valid = false
			toast.warning("Informe um corban válido (precisa conter no mínimo 5 caracteres).")
		}

		if (valid) {
			const aux = blockCorban
			toast.promise(
				() => {
					return new Promise(async (resolve, reject) => {
						try {
							await axios.post(
								`${REACT_APP_SERVIDOR}poupaconsig/siglas/atualizar-bloco`,
								{
									corban: blockCorban,
									consulta: blockSearch,
								},
								{
									headers: { token: user.token },
									httpsAgent: new https.Agent({
										rejectUnauthorized: false,
									}),
								}
							)

							if (table) {
								table.current.refresh()
							}

							setBlockCorban(null)
							setBlockSearch(1)

							loadData()
							resolve()
						} catch (err) {
							console.warn(err)
							reject(err)

							if (err && err.response && err.response.status == 403) {
								dispatch({ type: LOGOUT })
								if (!toast.isActive("session")) {
									toast.warning("Sua sessão foi expirada. Faça login novamente!", {
										toastId: "session",
									})
								}
							}
						}
					})
				},
				{
					pending: `Atualizando corban "${aux}"...`,
					success: `Corban "${aux}" atualizada com sucesso!`,
					error: `Falha ao atualizar o corban "${aux}". Verifique sua conexão e tente novamente.`,
				}
			)
		}
	}

	const onDelete = async () => {
		if (selected) {
			const aux = selected.usuario.toString()
			toast.promise(
				() => {
					return new Promise(async (resolve, reject) => {
						try {
							await axios.post(
								`${REACT_APP_SERVIDOR}poupaconsig/siglas/deletar`,
								{
									usuario: username,
								},
								{
									headers: { token: user.token },
									httpsAgent: new https.Agent({
										rejectUnauthorized: false,
									}),
								}
							)

							if (table) {
								table.current.refresh()
							}

							loadData()

							onReset()

							resolve()
						} catch (err) {
							console.warn(err)
							reject(err)

							if (err && err.response && err.response.status == 403) {
								dispatch({ type: LOGOUT })
								if (!toast.isActive("session")) {
									toast.warning("Sua sessão foi expirada. Faça login novamente!", {
										toastId: "session",
									})
								}
							}
						}
					})
				},
				{
					pending: `Deletando sigla "${aux}"...`,
					success: `Sigla "${aux}" deletada com sucesso!`,
					error: `Falha ao deletar a sigla "${aux}". Verifique sua conexão e tente novamente.`,
				}
			)
		}
	}

	const handleOnChange = () => {
		setCheckedError(!checkedError);
	};

	const handleOnChangeMaster = () => {
		setCheckedMaster(!checkedMaster);
	}

	const limparCamposFormulario = () => {
		// Limpar os inputs
		const inputs = document.querySelectorAll('form input.form-control');
		inputs.forEach(item => {
			item.value = "";
			item.removeAttribute("disabled");
		});

		onReset();
	}

	// Seção: Vincular um ou mais robôs em um sigla
	const listarRobos = async () => {
		try {
			const resultado = await axios.get(`${REACT_APP_SERVIDOR}robos/listar`, {
				httpsAgent: new https.Agent({
					rejectUnauthorized: false
				})
			});

			const { status } = resultado;
			const { dados, retorno } = resultado.data;

			if (status === 200 && retorno) {
				setListaRobos(dados);
			}
		} catch (error) {
			console.error(error.message);
			toast.error("Ocorreu um erro ao retornar uma lista dos robôs");
		}
	}

	const listarRobosVinculadosNaSigla = async (row) => {
		try {
			const robos = row.robos_vinculados;

			robos.map(item => {
				setRobosParaVincularNaSigla(preRobosParaVincularNaSigla => [...preRobosParaVincularNaSigla, { roboId: item }])
			});
		} catch (error) {
			console.log(error);
		}
	}

	const handlerChangeRobosParaVincularNaSigla = (event) => {
		const { id, value } = event.target;
		setRobosSelecionados({ ...robosSelecionados, [id]: value });
	}

	const adicionarRobosParaVincularNaSigla = async () => {
		const { robo } = robosSelecionados;

		if (robo == null) {
			toast.warn("Para adicionar, primeiro você precisa selecionar um robô");
			return;
		}

		try {
			const roboEncontrado = robosParaVincularNaSigla.some(item => item.roboId == robo);

			if (roboEncontrado) {
				toast.info("O robô selecionado já se encontra na lista");
				return;
			}

			setRobosParaVincularNaSigla(prerobosParaVincularNaSigla => [
				...prerobosParaVincularNaSigla,
				{ roboId: parseInt(robo) }
			]);
		} catch (error) {
			toast.error("Ocorreu um erro ao adicionar um robô");
			console.error(error.message);
		}
	}

	const removerRobo = (robo, index) => {
		try {
			setRobosParaVincularNaSigla(current => current.filter((item, index) => {
				return robo.roboId !== item.roboId
			}));
		} catch (error) {
			console.error(error.message);
			toast.error("Ocorreu um erro ao remover o robô");
		}
	}

	const salvarRobosSigla = async () => {
		const btnSave = document.querySelector("#btnSalvarRobosSiglas");
		const siglaId = btnSave.dataset.siglaid;

		let robos = [];

		robosParaVincularNaSigla.map(item => {
			robos.push(item.roboId);
		});

		robos = JSON.stringify(robos)
		robos = robos.replace("[", "{").replace("]", "}");

		const resultado = await axios.post(
			`${REACT_APP_SERVIDOR}poupaconsig/siglas/vincular-robos`,
			{
				sigla_id: siglaId,
				robos
			},
			{
				headers: { token: user.token },
				httpsAgent: new https.Agent({
					rejectUnauthorized: false,
				}),
			}
		);

		const { status, data } = resultado;

		if (status == 200 && data.sucesso) {
			toast.success(data.msg);
			window.$("#modalVincularRoboSigla").modal("hide");

			if (table) {
				table.current.refresh();
			}

			document.querySelector("#robo").value = 0;

			setRobosParaVincularNaSigla([]);
			loadData();
		}
	}

	const montarTabelaRobosSigla = (data) => {
		const buscarRobo = (roboId) => {
			return listaRobos.find(item => item.id == roboId).nome;
		}

		return data.map((item, index) => (
			<tr key={index}>
				<td>{item.roboId}</td>
				<td>{buscarRobo(item.roboId)}</td>
				<td className="table-actions text-center">
					<span
						className="table-action"
						onClick={() => removerRobo(item, index)}
						style={{ cursor: "pointer" }}
					>
						<i className="fas fa-trash"></i>
					</span>
				</td>
			</tr>
		));
	}

	const abrirModalRobosSigla = async (row) => {
		setSiglaId(row.id);
		listarRobosVinculadosNaSigla(row);
	}

	const resetarCamposModalRobosSigla = () => {
		setRobosParaVincularNaSigla([]);
		document.querySelector("#robo").value = 0;
	}

	useEffect(() => {
		checkPermission()
		loadData()
		listarRobos();
	}, [])

	useEffect(() => {
		montarTabelaRobosSigla(robosParaVincularNaSigla);

		const campoRobo = document.querySelector("#robo");
		const campoRoboExiste = document.body.contains(campoRobo);

		if (campoRoboExiste) {
			document.querySelector("#robo").value = 0;
		}
	}, [robosParaVincularNaSigla]);

	if (!permitted) {
		return null
	}

	return (
		<div className="container-fluid mt--6">
			<div className="row">
				{data &&
					data.dashboard &&
					data.dashboard.map((x, i) => (
						<div key={i} className="col-xl-3 col-md-6">
							<div className="card card-stats">
								{/* Card body */}
								<div className="card-body">
									<div className="row">
										<div className="col">
											<h5 className="card-title text-uppercase text-muted mb-0">{x.title}</h5>
											<span className="h2 font-weight-bold mb-0">{x.value}</span>
										</div>
										<div className="col-auto">
											<div
												className={`icon icon-shape bg-gradient-${x.color || "red"
													} text-white rounded-circle shadow`}
											>
												<i className={x.icon} />
											</div>
										</div>
									</div>
									<p className="mt-3 mb-0 text-sm">
										<span className="text-default mr-2">
											<i
												style={{ color: x.percent ? undefined : "white" }}
												className="fa fa-calculator"
											/>{" "}
											{x.percent}
										</span>
										{x.percent && <span className="text-nowrap">Percentual</span>}
									</p>
								</div>
							</div>
						</div>
					))}
			</div>
			<div className="row">
				<div className="col-md-12">
					<div className="card">
						{/* Card header */}
						<div className="card-header border-0">
							<div className="row">
								<div className="col-5">
									<h3 className="mb-0">Tabela</h3>
								</div>
								<div className="col-4 text-right">
									<button
										type="button"
										className="btn btn-sm btn-primary btn-round btn-icon"
									>
										<span className="btn-inner--icon">
											<i class="fas fa-solid fa-download"></i>
										</span>
										<span className="btn-inner--text" onClick={exportarDados}>Exportar</span>
									</button>

									<button
										type="button"
										data-toggle="modal"
										data-target="#modal-block"
										className="btn btn-sm btn-primary btn-round btn-icon"
									>
										<span className="btn-inner--icon">
											<i className="fas fa-solid fa-database" />
										</span>
										<span className="btn-inner--text">Atualizar em Bloco</span>
									</button>

									<button
										type="button"
										data-toggle="modal"
										data-target="#modal-form"
										className="btn btn-sm btn-primary btn-round btn-icon"
									>
										<span className="btn-inner--icon">
											<i className="fas fa-plus" />
										</span>
										<span className="btn-inner--text">Adicionar</span>
									</button>
								</div>
								<div className="col-3 text-center">
									<input
										type="search"
										className="form-control form-control-sm"
										placeholder="Pesquisar..."
										aria-controls="datatable-basic"
										onChange={(e) => setFilter(e.target.value)}
									/>
								</div>
							</div>
						</div>
						{/* Light table */}
						<TableV2
							ref={table}
							table={"poupaconsig/siglas-rh"}
							columns={[
								...columns,
								{
									id: "actions",
									name: "",
									cell: (row) => (
										<>
											<a
												href="#!"
												onClick={() => onClick(row)}
												className="table-action"
												data-toggle="modal"
												data-target="#modal-form"
											>
												<i className="fas fa-edit" />
											</a>
											<a
												href="#!"
												className="table-action"
												data-toggle="modal"
												data-target="#modalVincularRoboSigla"
												title="Vincular robô"
												onClick={() => { abrirModalRobosSigla(row) }}
											>
												<i class="fas fa-solid fa-robot"></i>
											</a>
										</>
									),
								},
							]}
							filter={filter}
						/>
					</div>
				</div>
				<div className="col-md-12">
					<div className="card">
						<div className="card-header border-0">
							<div className="row align-items-center">
								<div className="col">
									<h3 className="mb-0">Ativas por Corban</h3>
								</div>
							</div>
						</div>
						<div className="table-responsive">
							{/* Projects table */}
							<table className="table align-items-center table-flush">
								<thead className="thead-light">
									<tr>
										<th scope="col">Corban</th>
										<th scope="col">Total</th>
										<th scope="col">Ativas</th>
										<th scope="col">Inativas</th>
										<th scope="col" />
									</tr>
								</thead>
								<tbody>
									{data &&
										data.corbans &&
										data.corbans.map((x) => (
											<tr key={x.id}>
												<th scope="row">{x.id}</th>
												<td
													style={{
														color:
															parseInt(x.t) > 7
																? "#1171ef"
																: parseInt(x.t) <= 1
																	? "#f5365c"
																	: parseInt(x.t) <= 4
																		? "#fbb140 "
																		: "#2dce89",
														fontWeight: "bold",
													}}
												>
													{x.t}
												</td>
												<td>{x.at}</td>
												<td>{x.in}</td>
												<td>
													<div className="d-flex align-items-center">
														<span className="mr-2">{`${x.p}%`}</span>
														<div>
															<div className="progress">
																<div
																	className={`progress-bar bg-gradient-${x.p == 100 ? "success" : x.p == 0 ? "red" : "warning"
																		}`}
																	role="progressbar"
																	aria-valuenow={60}
																	aria-valuemin={0}
																	aria-valuemax={100}
																	style={{ width: `${x.p}%` }}
																/>
															</div>
														</div>
													</div>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-form"
				tabIndex={-1}
				role="dialog"
				aria-labelledby="modal-form"
				style={{ display: "none" }}
				aria-hidden="true"
				data-backdrop="static"
				data-keyboard="false"
			>
				<div className="modal-dialog modal- modal-dialog-centered modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-body p-0">
							<div className="card bg-secondary border-0 mb-0">
								<div className="card-body px-lg-5 py-lg-5">
									<div className="text-center text-muted mb-4">
										<b>{(selected && "Atualizar - " + selected.usuario) || "Adicionar"}</b>
									</div>
									<form role="form">
										<div className="form-group">
											<label class="form-control-label">
												{selected == null ? "Usuário*" : "Usuário"}
											</label>
											<div className="input-group input-group-merge input-group-alternative">
												<input
													value={username}
													onChange={(e) => setUsername(e.target.value.trim().toUpperCase())}
													className="form-control"
													type="text"
													required={selected == null}
													disabled={selected != null}
												/>
											</div>
										</div>
										<div className="form-group">
											<div className="custom-control custom-checkbox mb-3">
												<input
													type="checkbox"
													id="checkedMaster"
													className="custom-control-input"
													checked={checkedMaster}
													onChange={handleOnChangeMaster}
												/>
												<label className="custom-control-label" htmlFor="checkedMaster">Sigla Master</label>
											</div>
										</div>
										<div className="form-group">
											<label class="form-control-label">Senha*</label>
											<div className="input-group input-group-merge input-group-alternative">
												<input
													value={password}
													onChange={(e) => setPassword(e.target.value.trim().toUpperCase())}
													className="form-control"
													type="text"
													required
												/>
											</div>
										</div>
										<div className="form-group">
											<label class="form-control-label">Corban*</label>
											<div className="input-group input-group-merge input-group-alternative">
												<input
													value={corban}
													onChange={(e) => setCorban(e.target.value)}
													className="form-control"
													type="number"
													required
													maxLength={5}
												/>
											</div>
										</div>
										<div className="form-group">
											<label class="form-control-label">CPF*</label>
											<div className="input-group input-group-merge input-group-alternative">
												<input
													maxLength="14"
													value={cpf}
													onChange={(e) => setCpf(cpfMask(e.target.value))}
													className="form-control"
													type="text"
													required
												/>
											</div>
										</div>
										<div className="form-group">
											<label class="form-control-label">Tipo*</label>
											<select
												value={search ? "1" : "0"}
												onChange={(e) => setSearch(e.target.value == 1 ? true : false)}
												className="form-control"
												required
											>
												<option value="0">Digitação</option>
												<option value="1">Consulta</option>
											</select>
										</div>
										<div className="form-group">
											<label class="form-control-label">Preventivo*</label>
											<select
												value={preventivo ? "1" : "0"}
												onChange={(e) => setPreventivo(e.target.value == 1 ? true : false)}
												className="form-control"
												required
											>
												<option value="0">Não</option>
												<option value="1">Sim</option>
											</select>
										</div>
										<div className="form-group">
											<label class="form-control-label">Status*</label>
											<select
												value={active ? "1" : "0"}
												onChange={(e) => setActive(e.target.value == 1 ? true : false)}
												className="form-control"
												required
											>
												<option value="1">Ativo</option>
												<option value="0">Inativo</option>
											</select>
										</div>
										<div className="form-group">
											<div className="custom-control custom-checkbox mb-3">
												<input
													type="checkbox"
													id="hasErrorSigla"
													className="custom-control-input"
													checked={checkedError}
													onChange={handleOnChange}
												/>
												<label className="custom-control-label" htmlFor="hasErrorSigla">Sigla contém erro?</label>
											</div>
										</div>
										<div className="form-group">
											<label className="form-control-label">Messagem de erro</label>
											<div className="input-group input-group-merge input-group-alternative">
												<input
													type="text"
													className="form-control"
													value={textError}
													onChange={e => setTextError(e.target.value)}
													disabled={!checkedError ? true : false}
													id="messagemErro"
												/>
											</div>
										</div>
										<div className="row">
											{selected && (
												<div className="col-6 text-left">
													<button
														onClick={onDelete}
														type="button"
														className="btn btn-danger text-white"
														data-dismiss="modal"
													>
														Deletar
													</button>
												</div>
											)}
											<div className={selected ? "col-6 text-right" : "col-12 text-right"}>
												<button
													onClick={onSave}
													type="button"
													className="btn btn-primary"
												// data-dismiss="modal"
												>
													Salvar
												</button>
												<button
													type="button"
													className="btn btn-default text-white"
													data-dismiss="modal"
													onClick={limparCamposFormulario}
												>
													Cancelar
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-block"
				tabIndex={-1}
				role="dialog"
				aria-labelledby="modal-block"
				style={{ display: "none" }}
				aria-hidden="true"
				data-backdrop="static"
				data-keyboard="false"
			>
				<div className="modal-dialog modal- modal-dialog-centered modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-body p-0">
							<div className="card bg-secondary border-0 mb-0">
								<div className="card-body px-lg-5 py-lg-5">
									<div className="text-center text-muted mb-4">
										<b>Atualizar em Bloco</b>
									</div>
									<form role="form">
										<div className="form-group">
											<label class="form-control-label">Corban*</label>
											<div className="input-group input-group-merge input-group-alternative">
												<input
													value={blockCorban}
													onChange={(e) => setBlockCorban(e.target.value)}
													className="form-control"
													type="number"
													required={true}
												/>
											</div>
										</div>
										<div className="form-group">
											<label class="form-control-label">Tipo*</label>
											<select
												value={blockSearch ? "1" : "0"}
												onChange={(e) =>
													setBlockSearch(e.target.value.toString() == "1" ? true : false)
												}
												className="form-control"
												required
											>
												<option value="0">Digitação</option>
												<option value="1">Consulta</option>
											</select>
										</div>
										<div className="row">
											<div className={selected ? "col-6 text-right" : "col-12 text-right"}>
												<button
													onClick={onSaveBlock}
													type="button"
													className="btn btn-primary"
													data-dismiss="modal"
												>
													Atualizar
												</button>
												<button
													type="button"
													className="btn btn-default text-white"
													data-dismiss="modal"
												>
													Cancelar
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				id="modalVincularRoboSigla"
				className="modal fade"
				tabIndex={-1}
				role="dialog"
				aria-labelledby="modalVincularRoboSigla"
				aria-hidden="true"
				data-backdrop="static"
				data-keyboard="false"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header align-items-center">
							<h5 id="tituloFormularioMaquinaFixa" className="modal-title">Vincular robô</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={resetarCamposModalRobosSigla}>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-lg-12">
									<form>
										<div className="form-group">
											<label htmlFor="robo">Robôs</label>
											<select
												id="robo"
												className="form-control"
												onChange={handlerChangeRobosParaVincularNaSigla}
											>
												<option value="0">Selecione um robô</option>
												{listaRobos.map((item, index) => (
													<option value={item.id} key={index}>{item.nome}</option>
												))}
											</select>
										</div>
										<div className="form-group">
											<button
												type="button"
												className="btn btn-sm btn-primary btn-round"
												onClick={adicionarRobosParaVincularNaSigla}
											>
												Adicionar
											</button>
										</div>
									</form>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12">
									<div className="table-responsive">
										<table className="table table-flush align-items-center">
											<thead className="thead-light">
												<tr>
													<th scope="col">#</th>
													<th scope="col">Robô</th>
													<th scope="col" className="text-center">Remover</th>
												</tr>
											</thead>
											<tbody>
												{montarTabelaRobosSigla(robosParaVincularNaSigla)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={resetarCamposModalRobosSigla}>Cancelar</button>
							<button type="button" id="btnSalvarRobosSiglas" className="btn btn-primary" data-siglaid={siglaId} onClick={salvarRobosSigla}>Salvar</button>
						</div>
					</div>
				</div>
			</div >
		</div >
	)
}

export default Siglas
