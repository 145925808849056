import moment from "moment"
import React, { useEffect, useState } from "react"

const Status = ({ away }) => {
  return away ? (
    <span className="badge badge-dot">
      <i className="bg-warning" />
      <span className="status">ausente</span>
    </span>
  ) : (
    <span className="badge badge-dot">
      <i className="bg-success" />
      <span className="status">online</span>
    </span>
  )
}

export default Status
