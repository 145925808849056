import "./Dashboard.css"

import axios from "axios"
import moment from "moment"
import { useEffect, useState } from "react"
import { Bar, Line } from "react-chartjs-2"
import Loader from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import ReactTable from "react-table"
import { LOGOUT, SET_TITLE } from "../redux/Actions"

const https = require("https")
const { REACT_APP_SERVIDOR } = process.env

// Permissions:
const GRAPH1_PERMITTED = [1, 6, 8, 7, 40]
const GRAPH2_PERMITTED = [0] //[1, 6, 8, 7, 40]
const VALIDATION_PERMITTED = [6, 8, 9, 7, 40]
const PROD_PERIOD_PERMITTED = [1, 6, 8, 7, 40]
const CORBANS_PERMITTED = [1, 6, 8, 7, 40]
const COMISSION_PERMITTED = [1, 9]

function Dashboard(props) {
  const user = useSelector(state => state.user)
  const [corbans, setCorbans] = useState(null)
  const [production, setProduction] = useState(null)
  const [graph1, setGraph1] = useState(null)
  const [graph2, setGraph2] = useState(null)
  const [validation, setValidation] = useState(null)
  const [indicators, setIndicators] = useState([])
  const [comission, setComission] = useState([])
  const [list, setList] = useState([])
  const [date, setDate] = useState(moment().subtract(1, "day"))
  const [processRobotCsg, setProcessRobotCsg] = useState([])
  const [processRobotFve, setProcessRobotFve] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: SET_TITLE, payload: "Dashboard" })
    props.header.current.setElement(
      <div className="col-md-12 row">
        <div className="col-md-10">
          <input
            className="form-control"
            type="date"
            defaultValue={date.format("YYYY-MM-DD")}
            onChange={e => onDateChange(e.target.value)}
            max={moment().format("YYYY-MM-DD")}
          />
        </div>
        <div className="col-md-2">
          <button className="btn btn-icon btn-default" type="button" onClick={() => onDateChange(date)}>
            <span className="btn-inner--icon">
              <i className="fas fa-sync-alt" />
            </span>
          </button>
        </div>
      </div>
    )

    try {
      getInitial()
      getCorbans()
      getProduction(date)
      getGraph1(date)
      getGraph2(date)
      getIndicators(date)
      getValidation()
      getProcessRobots();
      getComission(date)
    } catch (err) {
      console.warn(err)
    }
  }, [])

  const getInitial = async () => {
    try {
      setList([])

      const req = await axios.get(`${REACT_APP_SERVIDOR}poupaconsig/lista`, {
        headers: { token: user.token },
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
        }),
      })

      setList(req.data)
    } catch (err) {
      if (err && err.response && err.response.status == 403) {
        dispatch({ type: LOGOUT })
        if (!toast.isActive("session")) {
          toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
        }
      }
    }
  }

  const getGraph1 = async date => {
    if (GRAPH1_PERMITTED.some(item => user.cargos.includes(item))) {
      try {
        const req = await axios.get(
          `${REACT_APP_SERVIDOR}poupaconsig/grafico-producao-por-corban?data=${date.format("YYYY-MM-DD")}`,
          {
            headers: { token: user.token },
            httpsAgent: new https.Agent({
              rejectUnauthorized: false,
            }),
          }
        )
        setGraph1(req.data)
      } catch (err) {
        if (err && err.response && err.response.status == 403) {
          dispatch({ type: LOGOUT })
          if (!toast.isActive("session")) {
            toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
          }
        }
      }
    }
  }

  const getGraph2 = async date => {
    if (GRAPH2_PERMITTED.some(item => user.cargos.includes(item))) {
      try {
        const req = await axios.get(
          `${REACT_APP_SERVIDOR}poupaconsig/grafico-origem-producao?data=${date.format("YYYY-MM-DD")}`,
          {
            headers: { token: user.token },
            httpsAgent: new https.Agent({
              rejectUnauthorized: false,
            }),
          }
        )
        setGraph2(req.data)
      } catch (err) {
        if (err && err.response && err.response.status == 403) {
          dispatch({ type: LOGOUT })
          if (!toast.isActive("session")) {
            toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
          }
        }
      }
    }
  }

  const getProduction = async date => {
    if (PROD_PERIOD_PERMITTED.some(item => user.cargos.includes(item))) {
      try {
        const req = await axios.get(
          `${REACT_APP_SERVIDOR}poupaconsig/dashboard-producao-por-corban?data=${date.format("YYYY-MM-DD")}`,
          {
            headers: { token: user.token },
            httpsAgent: new https.Agent({
              rejectUnauthorized: false,
            }),
          }
        )

        setProduction(req.data)
      } catch (err) {
        if (err && err.response && err.response.status == 403) {
          dispatch({ type: LOGOUT })
          if (!toast.isActive("session")) {
            toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
          }
        }
      }
    }
  }

  const getComission = async date => {
    if (COMISSION_PERMITTED.some(item => user.cargos.includes(item))) {
      try {
        const req = await axios.get(
          `${REACT_APP_SERVIDOR}poupaconsig/dashboard-comissao-por-corban?data=${date.format("YYYY-MM-DD")}`,
          {
            headers: { token: user.token },
            httpsAgent: new https.Agent({
              rejectUnauthorized: false,
            }),
          }
        )

        setComission(req.data)
      } catch (err) {
        if (err && err.response && err.response.status == 403) {
          dispatch({ type: LOGOUT })
          if (!toast.isActive("session")) {
            toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
          }
        }
      }
    }
  }

  const getValidation = async () => {
    if (VALIDATION_PERMITTED.some(item => user.cargos.includes(item))) {
      try {
        const req = await axios.get(`${REACT_APP_SERVIDOR}poupaconsig/ultima-validacao-banco`, {
          headers: { token: user.token },
          httpsAgent: new https.Agent({
            rejectUnauthorized: false,
          }),
        })

        setValidation(req.data)
      } catch (err) {
        if (err && err.response && err.response.status == 403) {
          dispatch({ type: LOGOUT })
          if (!toast.isActive("session")) {
            toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
          }
        }
      }
    }
  }

  const getProcessRobots = async () => {
    try {
      setProcessRobotFve([]);
      setProcessRobotCsg([]);

      if (VALIDATION_PERMITTED.some(item => user.cargos.includes(item))) { 
        const req = await axios.get(`${REACT_APP_SERVIDOR}fve/margem/relatorio`, {
          headers: { token: user.token },
          httpsAgent: new https.Agent({
            rejectUnauthorized: false,
          }),
        });

        if (req.data.retorno) {
          const { processadosCsg, processadosFve } = req.data;

          setProcessRobotCsg(processadosCsg);
          setProcessRobotFve(processadosFve);
        }
      }

    } catch (err) { 
      if (err && err.response && err.response.status == 403) {
        dispatch({ type: LOGOUT })
        if (!toast.isActive("session")) {
          toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
        }
      }
    }
  }

  const getIndicators = async date => {
    try {
      setIndicators([])

      const req = await axios.get(
        `${REACT_APP_SERVIDOR}poupaconsig/indicadores?id=${user.id}&parceiro=${user.parceiro}&cargos=${JSON.stringify(
          user.cargos
        )}&data=${date.format("YYYY-MM-DD")}`,
        {
          headers: { token: user.token },
          httpsAgent: new https.Agent({
            rejectUnauthorized: false,
          }),
        }
      )

      setIndicators(req.data)
    } catch (err) {
      if (err && err.response && err.response.status == 403) {
        dispatch({ type: LOGOUT })
        if (!toast.isActive("session")) {
          toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
        }
      }
    }
  }

  const getCorbans = async () => {
    try {
      const req = await axios.get(`${REACT_APP_SERVIDOR}poupaconsig/lista-corbans`, {
        headers: { token: user.token },
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
        }),
      })
      setCorbans(req.data)
    } catch (err) {
      if (err && err.response && err.response.status == 403) {
        dispatch({ type: LOGOUT })
        if (!toast.isActive("session")) {
          toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
        }
      }
    }
  }

  const onDateChange = data => {
    const aux = moment(data, "YYYY-MM-DD")
    setGraph1(null)
    setGraph2(null)
    setProduction(null)
    setDate(aux)
    getIndicators(aux)
    getGraph1(aux)
    getGraph2(aux)
    getProduction(aux)
    getComission(aux)
  }

  const exportProduction = () => {
    window.open(`${REACT_APP_SERVIDOR}arquivo/excel/producao-por-corban?data=${date.format("YYYY-MM-DD")}`)
  }

  const draw = data => {
    const { diario, semanal, quinzenal } = data

    const buscar = (base, corban, key) => {
      const aux = base.find(x => x.corban == corban)
      if (aux) {
        return aux[key]
      } else {
        return null
      }
    }

    return data.quinzenal.map(x => (
      <tr key={x.corban}>
        <td>{x.corban}</td>
        <td>{x.nome}</td>
        <td>
          <span style={{ whiteSpace: "nowrap" }}>{buscar(diario, x.corban, "meta") || "R$0,00"}</span>
        </td>
        <td>
          <b style={{ whiteSpace: "nowrap" }}>
            <span style={{ color: buscar(diario, x.corban, "atg_pn") ? "green" : "red" }}>● </span>
            {buscar(diario, x.corban, "vlr_pn") || "R$ 0,00"}
          </b>
          <br />
          <span style={{ whiteSpace: "nowrap", opacity: 0.5 }}>
            <span style={{ color: buscar(diario, x.corban, "atg_ps") ? "green" : "red" }}>● </span>
            {buscar(diario, x.corban, "vlr_ps") || "R$ 0,00"}
          </span>
        </td>
        <td>
          <span style={{ whiteSpace: "nowrap" }}>{buscar(semanal, x.corban, "meta") || "R$0,00"}</span>
        </td>
        <td>
          <b style={{ whiteSpace: "nowrap" }}>
            <span style={{ color: buscar(semanal, x.corban, "atg_pn") ? "green" : "red" }}>● </span>
            {buscar(semanal, x.corban, "vlr_pn") || "R$ 0,00"}
          </b>
          <br />
          <span style={{ whiteSpace: "nowrap", opacity: 0.5 }}>
            <span style={{ color: buscar(semanal, x.corban, "atg_ps") ? "green" : "red" }}>● </span>
            {buscar(semanal, x.corban, "vlr_ps") || "R$ 0,00"}
          </span>
        </td>
        <td>
          <span style={{ whiteSpace: "nowrap" }}>{buscar(quinzenal, x.corban, "meta") || "R$0,00"}</span>
        </td>
        <td>
          <b style={{ whiteSpace: "nowrap" }}>
            <span style={{ color: buscar(quinzenal, x.corban, "atg_pn") ? "green" : "red" }}>● </span>
            {buscar(quinzenal, x.corban, "vlr_pn") || "R$ 0,00"}
          </b>
          <br />
          <span style={{ whiteSpace: "nowrap", opacity: 0.5 }}>
            <span style={{ color: buscar(quinzenal, x.corban, "atg_ps") ? "green" : "red" }}>● </span>
            {buscar(quinzenal, x.corban, "vlr_ps") || "R$ 0,00"}
          </span>
        </td>
      </tr>
    ))
  }

  return (
    <div className="container-fluid mt--6">
      <div className="row">
        {/* Indicadores */}
        {indicators.map((x, i) => (
          <div key={i} className="col-xl-4 col-md-6">
            <div className="card card-stats">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">{x.title}</h5>
                    <span className="h4 font-weight-bold mb-0">{x.value}</span>
                  </div>
                  <div className="col-auto">
                    <div className={`icon icon-shape bg-gradient-${x.color || "red"} text-white rounded-circle shadow`}>
                      <i className={x.icon || "ni ni-active-40"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* Meta diária */}
        {GRAPH1_PERMITTED.some(item => user.cargos.includes(item)) && (
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div className="col-md-9">
                    <h3 className="mb-0">{`Meta Diária - ${date.format("DD/MM/YYYY")}`}</h3>
                  </div>
                </div>
              </div>
              <div style={{ paddingRight: 25, paddingLeft: 25 }}>
                <h5 style={{ textAlign: "justify" }}>
                  Este gráfico coleta as metas por corban e compara com as propostas digitadas no Poupasistema
                  (vermelho) e Poupanegócios (azul), o objetivo é que ambas tenham o mesmo valor. Tudo acima da linha
                  verde significa atingimento de meta diária. Linha vermelha acima da azul, significa que há propostas
                  digitadas diretamente pelo CSG. Linha azul acima da vermelha, significa que há propostas digitadas
                  pelo Poupanegócios que não foram inseridas no Poupasistema.{" "}
                  <b>Mova o mouse sobre os dados para ver mais detalhes</b>.{" "}
                  <span style={{ color: "red" }}>
                    Clique sobre o a legenda do gráfico para mostrar/ocultar os dados referente a ela.
                  </span>
                </h5>
              </div>
              <div style={{ padding: 25, paddingTop: 0 }}>
                {graph1 == null ? (
                  <Loader type="ThreeDots" width={50} height={50} color="#172b4d" />
                ) : graph1.labels.length > 0 ? (
                  <Line
                    data={graph1}
                    options={{
                      plugins: {
                        datalabels: null,
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              return `${context.dataset.label}: ${context.dataset.data[context.dataIndex]} ${context.dataset.extra ? `(${context.dataset.extra[context.dataIndex]})` : ""
                                }`
                            },
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  <span style={{ whiteSpace: "nowrap" }}>Nenhum dado encontrado para a data selecionada.</span>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Origem propostas */}
        {GRAPH2_PERMITTED.some(item => user.cargos.includes(item)) && (
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="mb-0">{`Origem Propostas (CSG x Poupanegócios) - ${date.format("DD/MM/YYYY")}`}</h3>
                  </div>
                </div>
              </div>
              <div style={{ paddingRight: 25, paddingLeft: 25 }}>
                <h5 style={{ textAlign: "justify" }}>
                  Este gráfico coleta todas as propostas digitadas no Poupasistema pelos promotores, agrupa por
                  escritório e contabiliza quais número de propostas constam e não constam no banco de dados do
                  Poupanegócios. <b>Mova o mouse sobre os dados para ver mais detalhes</b>.{" "}
                  <span style={{ color: "red" }}>
                    Clique sobre o a legenda do gráfico para mostrar/ocultar os dados referente a ela.
                  </span>
                </h5>
              </div>
              <div style={{ padding: 25, paddingTop: 0 }}>
                {graph2 == null ? (
                  <Loader type="ThreeDots" width={50} height={50} color="#172b4d" />
                ) : graph2.labels.length > 0 ? (
                  <Bar
                    data={graph2}
                    options={{
                      plugins: {
                        datalabels: null,
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              return `${context.dataset.label}: ${context.dataset.data[context.dataIndex]} ${context.dataset.extra ? `(${context.dataset.extra[context.dataIndex]})` : ""
                                }`
                            },
                          },
                        },
                      },
                      scales: {
                        x: {
                          stacked: true,
                        },
                        y: {
                          stacked: true,
                        },
                      },
                    }}
                  />
                ) : (
                  <span style={{ whiteSpace: "nowrap" }}>Nenhum dado encontrado para a data selecionada.</span>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Diário, semanal e quinzenal */}
        {PROD_PERIOD_PERMITTED.some(item => user.cargos.includes(item)) && (
          <div className="col-xl-12">
            <div style={{ overflow: "hidden" }} className="card bg-default shadow">
              <div className="card-header bg-transparent border-0">
                <div className="row align-items-center">
                  <div className="col-md-10">
                    <h3 className="text-white mb-0">{`Resumo Metas - ${date.format("DD/MM/YYYY")}`}</h3>
                  </div>
                  <div className="col-md-2">
                    <button className="btn btn-icon btn-block btn-primary" type="button" onClick={exportProduction}>
                      <span className="btn-inner--icon">
                        <i className="ni ni-bag-17"></i>
                      </span>
                      <span className="btn-inner--text">Exportar</span>
                    </button>
                  </div>
                </div>
              </div>
              {production == null ? (
                <div style={{ padding: 25 }}>
                  <Loader type="ThreeDots" width={50} height={50} color="white" />
                </div>
              ) : production && production.quinzenal.length > 0 ? (
                <div className="table-responsive">
                  <div style={{ paddingRight: 25, paddingLeft: 25, paddingBottom: 25 }}>
                    <h5 className="text-white" style={{ textAlign: "justify" }}>
                      Esta tabela mostra o valor líquido e o valor das metas por corban referente ao dia, semana e
                      quinzena. Em negrito e em fonte maior, temos o valor do banco, colhido por um robô que roda todo
                      dia 06hrs da manhã, coletando informação das propostas pagas no dia anterior através do relatório
                      de nota fiscal. Em opaco e fonte menor, temos a produção cadastrada pelos promotores no
                      Poupasistema.
                    </h5>
                  </div>
                  <table id="resumo" className="table table-dark align-items-center table-flush table-sm text-center">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Corban</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Meta Diária</th>
                        <th scope="col"> Pago Dia </th>
                        <th scope="col">Meta Semanal</th>
                        <th scope="col">Pago Semana</th>
                        <th scope="col">Meta Quinzenal</th>
                        <th scope="col">Pago Quinzena</th>
                      </tr>
                    </thead>
                    <tbody>{draw(production)}</tbody>
                  </table>
                </div>
              ) : (
                <div style={{ padding: 25 }}>
                  <span className="text-white">Nenhum dado encontrado para a data selecionada.</span>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Permissões */}
        {COMISSION_PERMITTED.some(item => user.cargos.includes(item)) && (
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="row col-12">
                      <h3 className="mb-0">Comissão por Corban</h3>
                    </div>
                  </div>
                </div>
              </div>
              {corbans == null ? (
                <div style={{ padding: 25 }}>
                  <Loader type="ThreeDots" width={50} height={50} color="#172b4d" />
                </div>
              ) : (
                <ReactTable
                  columns={[
                    {
                      id: "Corban",
                      Header: "Corban",
                      accessor: "corban",
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Nome",
                      Header: "Nome",
                      accessor: "nome",
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "dia",
                      Header: `COMISSÃO - ${date.format("DD/MM")}`,
                      accessor: x => <b>{x.dia}</b>,
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "acumulado",
                      Header: `COMISSÃO - MÊS`,
                      accessor: "acumulado",
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "ult",
                      Header: "Dt. Último Contrato",
                      accessor: "ult",
                      sortable: false,
                      className: "text-center",
                    },
                  ]}
                  multiSort={false}
                  filterable={false}
                  showPagination={false}
                  manual
                  data={comission}
                  className="-striped -highlight"
                  previousText={"Anterior"}
                  nextText={"Próxima"}
                  loadingText={"Carregando..."}
                  noDataText={"Nenhum dado encontrado."}
                  pageText={"Página"}
                  ofText={"de"}
                  rowsText={"linhas"}
                />
              )}
            </div>
          </div>
        )}

        {/* Corbans */}
        {CORBANS_PERMITTED.some(item => user.cargos.includes(item)) && (
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="row col-12">
                      <h3 className="mb-0">Corbans</h3>
                      <button
                        onClick={() => getInitial()}
                        style={{ marginLeft: 10 }}
                        className="btn btn-sm btn-warning"
                        type="button"
                      >
                        <i className="fas fa-sync" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {corbans == null ? (
                <div style={{ padding: 25 }}>
                  <Loader type="ThreeDots" width={50} height={50} color="#172b4d" />
                </div>
              ) : (
                <ReactTable
                  columns={[
                    {
                      id: "Corban",
                      Header: "Corban",
                      accessor: "corban",
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Nome",
                      Header: "Nome",
                      accessor: x => (
                        <span style={{ whiteSpace: "nowrap" }}>
                          <span style={{ color: x.atg ? "green" : "red" }}>● </span>
                          {`${x.nome} (${x.atg ? "X" : x.prioridade})`}
                        </span>
                      ),
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Meta",
                      Header: "Meta",
                      accessor: "meta",
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Falta p/ Quinzena",
                      Header: "Falta p/ Quinzena",
                      accessor: x => <b>{(x.quinzena.includes("-") ? "ATINGIDO" : x.quinzena)}</b>,
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Pend. Canais",
                      Header: "Pend. Canais",
                      accessor: "total_pc",
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Em Averbação",
                      Header: "Averbação",
                      accessor: "total_av",
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Siglas",
                      Header: "Siglas",
                      accessor: x => x.total,
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Digitação",
                      Header: "Digitação",
                      accessor: x =>
                        x.total - x.cst <= 0 ? <b style={{ color: "red" }}>{x.total - x.cst}</b> : x.total - x.cst,
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Consulta",
                      Header: "Consulta",
                      accessor: x => (x.cst <= 0 ? <b style={{ color: "red" }}>{x.cst}</b> : x.cst),
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Em Uso",
                      Header: "Em Uso",
                      accessor: x =>
                        list.filter(y => y.corban == x.corban).length > 0 ? (
                          <b style={{ color: "green" }}>{list.filter(y => y.corban == x.corban).length}</b>
                        ) : (
                          list.filter(y => y.corban == x.corban).length
                        ),
                      sortable: false,
                      className: "text-center",
                    },
                  ]}
                  multiSort={false}
                  filterable={false}
                  showPagination={false}
                  manual
                  data={corbans}
                  className="-striped -highlight"
                  previousText={"Anterior"}
                  nextText={"Próxima"}
                  loadingText={"Carregando..."}
                  noDataText={"Nenhum dado encontrado."}
                  pageText={"Página"}
                  ofText={"de"}
                  rowsText={"linhas"}
                />
              )}
            </div>
          </div>
        )}

        {CORBANS_PERMITTED.some(item => user.cargos.includes(item)) && (
          <div className="col-xl-6">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="row col-12">
                      <h3 className="mb-0">ROBO CSG - Corbans Processados</h3>
                    </div>
                  </div>
                </div>
              </div>
              {processRobotCsg == null ? (
                <div style={{ padding: 25 }}>
                  <Loader type="ThreeDots" width={50} height={50} color="#172b4d" />
                </div>
              ) : (
                <ReactTable
                  columns={[
                    {
                      id: "Corban",
                      Header: "Corban",
                      accessor: "corban",
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Data processamento",
                      Header: "Data processamento",
                      accessor: "data_atualizacao",
                      sortable: false,
                      className: "text-center",
                    },
                  ]}
                  multiSort={false}
                  filterable={false}
                  showPagination={false}
                  manual
                  data={processRobotCsg}
                  className="-striped -highlight"
                  previousText={"Anterior"}
                  nextText={"Próxima"}
                  loadingText={"Carregando..."}
                  noDataText={"Nenhum dado encontrado."}
                  pageText={"Página"}
                  ofText={"de"}
                  rowsText={"linhas"}
                />
              )}
            </div>
          </div>
        )}

        {CORBANS_PERMITTED.some(item => user.cargos.includes(item)) && (
          <div className="col-xl-6">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="row col-12">
                      <h3 className="mb-0">ROBO FVE - Corbans Processados</h3>
                    </div>
                  </div>
                </div>
              </div>
              {processRobotFve == null ? (
                <div style={{ padding: 25 }}>
                  <Loader type="ThreeDots" width={50} height={50} color="#172b4d" />
                </div>
              ) : (
                <ReactTable
                  columns={[
                    {
                      id: "Corban",
                      Header: "Corban",
                      accessor: "corban",
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Cnpj",
                      Header: "Cnpj",
                      accessor: "cnpj",
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Data processamento",
                      Header: "Data processamento",
                      accessor: "data_atualizacao",
                      sortable: false,
                      className: "text-center",
                    },
                  ]}
                  multiSort={false}
                  filterable={false}
                  showPagination={false}
                  manual
                  data={processRobotFve}
                  className="-striped -highlight"
                  previousText={"Anterior"}
                  nextText={"Próxima"}
                  loadingText={"Carregando..."}
                  noDataText={"Nenhum dado encontrado."}
                  pageText={"Página"}
                  ofText={"de"}
                  rowsText={"linhas"}
                />
              )}
            </div>
          </div>
        )}

        {/* Data de validação */}
        {VALIDATION_PERMITTED.some(item => user.cargos.includes(item)) && (
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="row col-12">
                      <h3 className="mb-0">Última Validação (Banco)</h3>
                    </div>
                  </div>
                </div>
              </div>
              {validation == null ? (
                <div style={{ padding: 25 }}>
                  <Loader type="ThreeDots" width={50} height={50} color="#172b4d" />
                </div>
              ) : (
                <ReactTable
                  columns={[
                    {
                      id: "Corban",
                      Header: "Corban",
                      accessor: "corban",
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Nome",
                      Header: "Nome",
                      accessor: "nome",
                      sortable: false,
                      className: "text-center",
                    },
                    {
                      id: "Data",
                      Header: "Data",
                      accessor: x =>
                        moment(x.data, "DD/MM/YYYY HH:mm").format("YYYYMMDD") != moment().format("YYYYMMDD") ? (
                          <span
                            style={{
                              color: "red",
                              fontWeight: "bold",
                            }}
                          >
                            {x.data}
                          </span>
                        ) : (
                          <span>{x.data}</span>
                        ),
                      sortable: false,
                      className: "text-center",
                    },
                  ]}
                  multiSort={false}
                  filterable={false}
                  showPagination={false}
                  manual
                  data={validation}
                  className="-striped -highlight"
                  previousText={"Anterior"}
                  nextText={"Próxima"}
                  loadingText={"Carregando..."}
                  noDataText={"Nenhum dado encontrado."}
                  pageText={"Página"}
                  ofText={"de"}
                  rowsText={"linhas"}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Dashboard
