import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import TableV2 from "../components/TableV2"
import { LOGOUT, SET_TITLE } from "../redux/Actions"
import { pm } from "../utils"

const https = require("https")
const { REACT_APP_SERVIDOR } = process.env

const columns = [
  { id: "id", name: "#ID", selector: "id" },
  { id: "data_criacao", name: "Data Cadastro", sortable: true, selector: "data_criacao" },
  { id: "codigo", name: "Código", selector: "codigo" },
  { id: "descricao", name: "Descrição", selector: "descricao" },
  { id: "taxa", name: "Taxa", sortable: true, selector: "taxa" }
]

function FactaRegras(props) {
  const user = useSelector(state => state.user);
  const [permitted, setPermitted] = useState(false);
  const [filter, setFilter] = useState(null);
  const [selected, setSelected] = useState(null);
  const [codigo, setCodigo] = useState("");
  const [taxa, setTaxa] = useState("");
  const [descricao, setDescricao] = useState("");

  const table = useRef()
  const dispatch = useDispatch()

  const checkPermission = () => {
    if (!pm(user, 2)) {
      props.history.push("/")
      toast.error("Você não tem permissão para acessar esta página.")
    } else if ([74].includes(user.id)) {
      props.history.push("/")
      toast.error("Você não tem permissão para acessar esta página.")
    } else {
      setPermitted(true)
      dispatch({ type: SET_TITLE, payload: "Regras Robo Facta - Fgts" })
    }
  }

  useEffect(() => {
    checkPermission()
  }, [])

  const onClick = row => {
    setSelected(row);
    setCodigo(row.codigo);
    setTaxa(row.taxa);
    setDescricao(row.descricao);
  }

  const onReset = row => {
    setSelected(null)
    setCodigo("");
    setTaxa("");
    setDescricao("");
  }

  const onSave = async () => {
    const aux = selected.id + ""

    toast.promise(
      () => {
        return new Promise(async (resolve, reject) => {
          try {
            await axios.post(
              `${REACT_APP_SERVIDOR}geral/facta/atualizar-regra`,
              {
                id: selected.id,
                codigo,
                taxa,
                descricao
              },
              {
                headers: { token: user.token },
                httpsAgent: new https.Agent({
                  rejectUnauthorized: false,
                }),
              }
            )

            if (table) {
              table.current.refresh()
            }

            resolve()
          } catch (err) {
            console.warn(err)
            reject(err)

            if (err && err.response && err.response.status == 403) {
              dispatch({ type: LOGOUT })
              if (!toast.isActive("session")) {
                toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
              }
            }
          }
        })
      },
      {
        pending: `Atualizando a regra: ${aux}...`,
        success: `Regra ${aux} atualizada com sucesso!`,
        error: `Falha ao atualizar a regra ${aux}. Verifique sua conexão e tente novamente.`,
      }
    )
  }

  if (!permitted) {
    return null
  }

  return (
    <div className="container-fluid mt--6">
      <div className="card">
        {/* Card header */}
        <div className="card-header border-0">
          <div className="row">
            <div className="col-9">
              <h3 className="mb-0">Tabela</h3>
            </div>
            <div className="col-3 text-right">
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder="Pesquisar usuário..."
                aria-controls="datatable-basic"
                onChange={e => setFilter(e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* Light table */}
        <TableV2
          ref={table}
          filter={filter}
          table={"facta/regras"}
          columns={[
            ...columns,
            {
              id: "actions",
              name: "",
              cell: row => (
                <a
                  href="#!"
                  onClick={() => onClick(row)}
                  className="table-action"
                  data-toggle="modal"
                  data-target="#modal-form"
                >
                  <i className="fas fa-edit" />
                </a>
              ),
            },
          ]}
        />
      </div>
      <div
        className="modal fade"
        id="modal-form"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modal-form"
        style={{ display: "none" }}
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal- modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="card bg-secondary border-0 mb-0">
                <div className="card-body px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <b>{selected && selected.usuario}</b>
                  </div>
                  <form role="form">
                    <div className="form-group">
                      <label className="form-control-label">Código</label>
                      <div className="input-group input-group-merge input-group-alternative">
                        <input
                          value={codigo}
                          onChange={e => setCodigo(e.target.value)}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">Taxa</label>
                      <div className="input-group input-group-merge input-group-alternative">
                        <input
                          value={taxa}
                          onChange={e => setTaxa(e.target.value)}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">Descrição</label>
                      <div className="input-group input-group-merge input-group-alternative">
                        <input
                          value={descricao}
                          onChange={e => setDescricao(e.target.value)}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <button onClick={onSave} type="button" className="btn btn-primary my-4" data-dismiss="modal">
                        Salvar
                      </button>
                      <button
                        onClick={onReset}
                        type="button"
                        className="btn btn-default text-white ml-auto"
                        data-dismiss="modal"
                      >
                        Cancelar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FactaRegras
