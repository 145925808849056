import { combineReducers } from "redux"

import { LOGOUT, UPDATE_USER, SET_TITLE } from "./Actions"

const merge = (prevState, nextState) => Object.assign({}, prevState, nextState)
const concat = (array, item) => array.concat(item)

const pageInitialState = {
  title: null,
}

const pageReducer = (state = pageInitialState, action) => {
  switch (action.type) {
    case SET_TITLE:
      return { ...state, title: action.payload }

    default:
      return state
  }
}

const userInitialStateReducer = {
  id: null,
  parceiro: null,
  nome: null,
  email: null,
  token: null,
  permissoes: [],
  cargos: [],
}

const userReducer = (state = userInitialStateReducer, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return merge(state, action.payload)

    case LOGOUT:
      return userInitialStateReducer

    default:
      return state
  }
}

export default combineReducers({
  user: userReducer,
  page: pageReducer,
})
