import React, { useState } from "react"

import { toast } from "react-toastify"

export function Robot(props) {
  const {
    name,
    mac,
    online,
    selected,
    robot,
    anydesk,
    fix,
    link,
    maquina,
    editarMaquinaFixa,
    pausarMaquinaFixa
  } = props

  const onClick = e => {
    if (e.target.nome == null || e.target.nome != "button") {
      return props.onClick && props.onClick(mac)
    }
  }

  const getImage = bank => {
    switch (bank) {
      case 1:
        return "/assets/img/icons/banks/sim.png"
      case 33:
        return "/assets/img/icons/banks/santander.png"
    }
  }

  const copy = text => {
    navigator.clipboard.writeText(text || "NULL")
    toast.success(`Anydesk '${text}' copiado com sucesso!`)
  }

  return (
    <div className="col-lg-3">
      <div className={`card ${selected ? "bg-gradient-default" : ""}`}>
        <div className="card-body text-center">
          <div className="row align-items-center">
            <div className="col">
              <div className="row">
                <div className="col-12">

                  <div className="col-12" style={{ display: "flex", alignItems: "center" }}>
                    <div className="col-md-3"></div>
                    <div className="col-6">
                      <h4 className="mb-0">
                        <a className={selected ? "text-white" : undefined} href="#!">
                          {name}
                        </a>
                      </h4>
                    </div>
                    <div className="col-3 text-right" style={{ marginLeft: '35px' }}>
                      <div className="dropdown">
                        <a className="btn btn-sm btn-icon-only" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                          <a
                            href="#!"
                            className="dropdown-item"
                            onClick={() => copy(anydesk)}
                          >
                            <i class="fas fa-solid fa-clone" style={{ marginRight: "10px" }}></i>
                            Copiar Anydesk
                          </a>
                          <a
                            href="#!"
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#modalMaquinaFixa"
                            onClick={() => editarMaquinaFixa(maquina)}
                          >
                            <i class="fas fa-solid fa-pen" style={{ marginRight: "10px" }}></i>
                            Editar máquina
                          </a>
                          <a
                            href="#!"
                            className="dropdown-item"
                            onClick={() => pausarMaquinaFixa(mac)}
                          >
                            <i class="fas fa-solid fa-stopwatch" style={{ marginRight: "10px" }}></i>
                            Pausar máquina
                          </a>
                          {link && (
                            <a
                              href={link}
                              name="button"
                              className="dropdown-item"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i class="fas fa-solid fa-link" style={{ marginRight: "10px" }}></i>
                              Visualizar
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <p className="text-xsm text-muted mb-0">{`MAC: ${mac}`}</p>
                  <h4 className="mb-0">
                    <span className={`text-${online ? "success" : robot && robot.name ? "warning" : "danger"}`}>● </span>
                    <small className={selected ? "text-white" : undefined}>
                      {online ? "Executando" : robot && robot.name ? "Aguardando o robô" : "Inativo"}
                    </small>
                  </h4>
                  <div style={{ marginTop: 8 }}>
                    {robot && robot.bank ? (
                      <img className="img-icon" src={getImage(robot.bank)} alt="Ícone do banco" />
                    ) : (
                      <img className="img-icon" src={"/assets/img/icons/common/zzz.png"} alt="Ícone de emoji dormindo" />
                    )}
                    <p className="text-xsm text-muted mb-0">{(robot && robot.name) || "Aguardando..."}</p>
                    <p className={`text-xsm mb-2 text-muted`}>
                      {robot && robot.name ? `Versão: ${robot.version}` : "-"}
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <p style={{ marginTop: 12, fontWeight: "bold", fontSize: 12, margin: 0 }}>{"Robôs fixos: "}</p>
                  {fix ? (
                    fix.split(",").map(x => <p style={{ fontSize: 9, fontWeight: "bold", margin: 0 }}>{`● ${x.trim()}`}</p>)
                  ) : (
                    <p style={{ fontSize: 9, fontWeight: "bold", margin: 0 }}>N/A</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
