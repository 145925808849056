import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { LOGOUT, SET_TITLE } from "../redux/Actions";

import { pm } from "../utils"

import Input from "../components/Input/Input";

const https = require("https");
const { REACT_APP_SERVIDOR } = process.env;

export function ExportacaoPropostasRecusadas(props) {
   const [permissao, setPermissao] = useState(false);
   const [dataInicial, setDataInicial] = useState(null);
   const [dataFinal, setDataFinal] = useState(null);

   const usuario = useSelector(state => state.user);
   const dispatch = useDispatch();

   function verificarPermissao(props) {
      if (!pm(usuario, 1)) {
         props.history.push("/");
         toast.error("Você não possui permissão para acessar essa página!");
      } else {
         setPermissao(true);
         dispatch({ type: SET_TITLE, payload: "Exportações > Propostas Recusadas" });
      }
   }

   function limparFormulario() {
      setDataInicial(moment().format("YYYY-MM-DD"));
      setDataFinal(moment().format("YYYY-MM-DD"));

      document.querySelector("#dataInicial").value = moment().format("YYYY-MM-DD");
      document.querySelector("#dataFinal").value = moment().format("YYYY-MM-DD");
   }

   function handleDataInicial(valor) {
      setDataInicial(valor);
   }

   function handleDataFinal(valor) {
      setDataFinal(valor);
   }

   async function handleExportar() {
      try {
         if (dataInicial != "" && dataFinal == "") {
            toast.warn("O campo Data Inicial está preenchido, por isso, o campo Data Final é obrigatório");
            return;
         }

         if (dataInicial == "" && dataFinal != "") {
            toast.warn("O campo Data Final está preenchido, por isso, o campo Data Inicial é obrigatório");
            return;
         }

         if (dataFinal < dataInicial) {
            toast.warn("O valor da Data Inicial não pode ser maior que a Data Final");
            return;
         }

         let inicial = dataInicial == "" ? null : dataInicial;
         let final = dataFinal == "" ? null : dataFinal;

         window.open(`${REACT_APP_SERVIDOR}arquivo/excel/propostas-recusadas?dataInicial=${inicial}&dataFinal=${final}`);
         limparFormulario();
      } catch (error) {
         console.error(error.message);
         toast.error("Ocorreu um erro ao exportar as Propostas Recusadas");
      }
   }

   useEffect(() => {
      const inicial = document.querySelector("#dataInicial");
      handleDataInicial(inicial.value);
   }, [dataInicial]);

   useEffect(() => {
      const final = document.querySelector("#dataFinal");
      handleDataFinal(final.value);
   }, [dataFinal]);

   useEffect(() => {
      verificarPermissao();

      const inicial = document.querySelector("#dataInicial");
      setDataInicial(inicial.value);

      const final = document.querySelector("#dataFinal");
      setDataFinal(final.value);
   }, []);

   return (
      <div className="container-fluid mt--6">
         <div className="card">
            <div className="card-body border-0">
               <form>
                  <div className="row align-items-center">
                     <div className="col">
                        <div className="form-group">
                           <label className="form-control-label">Data Inicial</label>
                           <input
                              type="date"
                              name="dataInicial"
                              id="dataInicial"
                              className="form-control"
                              defaultValue={moment().format("YYYY-MM-DD")}
                              max={moment().format("YYYY-MM-DD")}
                              onChange={handleDataInicial}
                           />
                        </div>
                     </div>
                     <div className="col">
                        <div className="form-group">
                           <label className="form-control-label">Data Final</label>
                           <input
                              type="date"
                              name="dataFinal"
                              id="dataFinal"
                              className="form-control"
                              defaultValue={moment().format("YYYY-MM-DD")}
                              max={moment().format("YYYY-MM-DD")}
                              onChange={handleDataFinal}
                           />
                        </div>
                     </div>
                  </div>
                  <div className="row text-right">
                     <div className="col">
                        <div className="form-group">
                           <button
                              type="button"
                              className="btn btn-primary btn-round btn-icon"
                              onClick={handleExportar}
                           >
                              <span className="btn-inner--icon">
                                 <i className="fas fa-download"></i>
                              </span>
                              <span className="btn-inner--text">
                                 Exportar
                              </span>
                           </button>
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   );
}
