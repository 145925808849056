//Styles
import { Controller, useWatch } from 'react-hook-form';
import Select from 'react-select';

export function SelectForm({ control, options, name, label, error, ...rest }) {
   useWatch({ control, name });

   return (
      <>
         <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {

               const valor = rest.isMulti ? value : (
                  value?.value ? value : (
                     value && options.length > 0 ? options.filter(o => o.value == value || o.label == value)[0]
                        : (
                           value ? { label: value } : null
                        )
                  )
               );

               return (
                  <>
                     <label className="form-control-label">
                        {label}
                     </label>
                     <div>
                        <Select
                           options={options}
                           onChange={onChange}
                           onBlur={onBlur}
                           value={valor}
                           className="form-control-select"
                           {...rest}
                        />
                     </div>
                  </>
               )
            }}
            name={name}
         />
         {error && <p style={{ marginTop: "5px", fontSize: ".8rem", fontWeight: "400", color: "#E50027" }}>{error}</p>}
      </>
   )
}