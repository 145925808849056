import axios from "axios";
import { useEffect, useState, useRef } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import TableV2 from "../components/TableV2";

import { LOGOUT, SET_TITLE } from "../redux/Actions";
import { pm } from "../utils";

const https = require("https");
const { REACT_APP_SERVIDOR } = process.env;
const columns = [
  {
    id: "campanha_ipc",
    name: "Campanha",
    sortable: false,
    cell: (x) => <b>{x.campanha_ipc}</b>,
  },
  {
    id: "proposta_ipc",
    name: "Proposta",
    sortable: false,
    cell: (x) => <b>{x.proposta_ipc}</b>,
  },
  {
    id: "cpf_ipc",
    name: "CPF",
    sortable: false,
    cell: (x) => <b>{x.cpf_ipc}</b>,
  },
  {
    id: "nome_ipc",
    name: "Nome",
    sortable: false,
    cell: (x) => <b>{x.nome_ipc}</b>,
  },
  {
    id: "tipo_ipc",
    name: "Tipo",
    sortable: false,
    cell: (x) => <b>{x.tipo_ipc}</b>,
  },
  {
    id: "convenio_ipc",
    name: "Convênio",
    sortable: false,
    cell: (x) => <b>{x.convenio_ipc}</b>,
  },
];

function ImportacaoCpfs(props) {
  const user = useSelector((state) => state.user);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [name, setName] = useState(null);
  const table = useRef();
  const [filter, setFilter] = useState(null);
  const [permitted, setPermitted] = useState(false);

  const dispatch = useDispatch();

  const checkPermission = () => {
    if (!pm(user, 11)) {
      props.history.push("/");
      toast.error("Você não tem permissão para acessar esta página.");
    } else {
      setPermitted(true);
      dispatch({ type: SET_TITLE, payload: "Importações > CPFs" });
    }
  };

  useEffect(() => {
    checkPermission();
  }, []);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const removeFile = () => {
    setIsFilePicked(false);
    setSelectedFile(undefined);
  };

  const handleSubmission = async () => {
    if (name == null || name.length < 3) {
      toast.warning(
        "Informe uma descrição para lista que contenha mais de três caracteres..."
      );
    } else if (selectedFile == null) {
      toast.warning("Selecione um arquivo CSV...");
    } else if (selectedFile.size / 1024 / 1024 > 5) {
      toast.warning(
        "Arquivo deve conter no máximo 5MB. Se necessário divida o arquivo em vários e faça o upload de todos com o mesmo Descrição."
      );
    } else {
      toast.promise(
        () => {
          return new Promise(async (resolve, reject) => {
            try {
              setUploading(true);

              const formData = new FormData();
              formData.append("file", selectedFile);
              formData.append("descricao", name);

              await axios.post(
                `${REACT_APP_SERVIDOR}geral/importacao/cpfs`,
                formData,
                {
                  timeout: 5 * 60000,
                  headers: {
                    token: user.token,
                    "Content-Type": "multipart/form-data",
                  },
                  httpsAgent: new https.Agent({
                    rejectUnauthorized: false,
                  }),
                }
              );

              setUploading(false);

              if (table) {
                table.current.refresh();
              }

              removeFile();
              resolve();
            } catch (err) {
              setUploading(false);
              console.warn(err);
              reject(err);

              if (err && err.response && err.response.status == 403) {
                dispatch({ type: LOGOUT });
                if (!toast.isActive("session")) {
                  toast.warning(
                    "Sua sessão foi expirada. Faça login novamente!",
                    { toastId: "session" }
                  );
                }
              }
            }
          });
        },
        {
          pending: `Aguarde, fazendo upload dos nomes...`,
          success: `Upload do arquivo feito com sucesso. EM BREVE OS NOMES SERÃO PROCESSADOS!`,
          error: `Falha ao efetuar upload. Verifique sua conexão e tente novamente.`,
        }
      );
    }
  };

  if (!permitted) {
    return null;
  }

  return (
    <div className="container-fluid mt--6">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            {/* Card header */}
            <div className="card-header border-0">
              <div className="row">
                <div className="col-9">
                  <h3 className="mb-0">Listas</h3>
                </div>
                <div className="col-3 text-right">
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    placeholder="Pesquisar usuário..."
                    aria-controls="datatable-basic"
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <TableV2
              ref={table}
              filter={filter}
              table={"importacoes/cpf/lista"}
              columns={columns}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h3 className="mb-0">Importar lista</h3>
            </div>
            {uploading ? (
              <div className="card-body text-center">
                <Loader
                  type="ThreeDots"
                  width={50}
                  height={50}
                  color="#172b4d"
                />
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group">
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Descrição*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Digite um nome para identificar o bloco de nomes posteriormente..."
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      {isFilePicked ? (
                        <div style={{ marginTop: 20 }}>
                          <p className="form-control-label">
                            Nome: {selectedFile.name}
                          </p>
                          <p className="form-control-label">
                            Tipo: {selectedFile.type}
                          </p>
                          <p className="form-control-label">
                            Tamanho em bytes: {selectedFile.size}
                          </p>
                          <p className="form-control-label">
                            Ult. Modificação:{" "}
                            {selectedFile.lastModifiedDate.toLocaleDateString()}
                          </p>
                        </div>
                      ) : (
                        <div className="form-group">
                          <label
                            className="form-control-label"
                            htmlFor="example3cols1Input"
                          >
                            Arquivo CSV*
                          </label>
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFileLang"
                              lang="en"
                              accept=".csv"
                              onChange={changeHandler}
                            />
                            <label className="custom-file-label">
                              Selecionar arquivo...
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {isFilePicked && (
                  <button
                    type="button"
                    class="btn btn-danger btn-block"
                    onClick={removeFile}
                  >
                    {`Remover ${selectedFile.name}`}
                  </button>
                )}
                {isFilePicked && (
                  <button
                    type="button"
                    class="btn btn-success btn-block"
                    onClick={handleSubmission}
                  >
                    Fazer Upload
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportacaoCpfs;
