import "./App.css"

import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { BrowserRouter as Router, Route } from "react-router-dom"
import io from "socket.io-client"

import Header from "./components/Header"
import Menu from "./components/Menu"
import TopNav from "./components/TopNav"
import Credentials from "./screens/Credentials"
import Dashboard from "./screens/Dashboard"
import Details from "./screens/Details"
import Login from "./screens/Login"
import PoupaconsigDisconnections from "./screens/PoupaconsigDisconnections"
import PoupaconsigConnections from "./screens/PoupaconsigConnections"
import PoupaconsigErrors from "./screens/PoupaconsigErrors"
import PoupaconsigLive from "./screens/PoupaconsigLive"
import PoupaconsigProposals from "./screens/PoupaconsigProposals"
import PoupaconsigConfig from "./screens/PoupaconsigConfig"
import PoupaconsigMetricsCSG from "./screens/PoupaconsigMetricsCSG"
// import Robots from "./screens/Robots"
import MapCSG from "./screens/MapCSG"
import Siglas from "./screens/Siglas"
import PoupaconsigProposalsBank from "./screens/PoupaconsigProposalsBank"
import RobotSimCP from "./screens/RobotSimCP"
import ImportacaoCpfs from "./screens/ImportacaoCpfs"
import RobotProposals from "./screens/RobotProposals"
import RobotTaxaConvenio from "./screens/RobotTaxaConvenio"
import RobotCancelamentoProposta from "./screens/RobotCancelamentoProposta"
import { MaquinasFixas } from "./screens/MaquinasFixas";
import { ExportacaoComissoes } from "./screens/ExportacaoComissoes";
import { ExportacaoPropostasRecusadas } from "./screens/ExportacaoPropostasRecusadas";
import { SiglasCompartilhadas } from "./screens/SiglasCompartilhadas";
import { PoupaconsigPropostasParceiro } from "./screens/PoupaconsigPropostasParceiro";
import { PoupaconsigPropostasRascunho } from "./screens/PoupaconsigPropostasRascunho";
import FactaRegras from "./screens/FactaRegras"

const { REACT_APP_SERVIDOR } = process.env
const CONFIG = { transports: ["websocket", "polling", "flashsocket"], rejectUnauthorized: false }

function App() {
	//const SOCKET = io(REACT_APP_SERVIDOR, CONFIG)
	const title = useSelector((state) => state.page.title)
	const user = useSelector((state) => state.user)
	const headerRef = useRef()

	useEffect(() => {
		process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0"
	}, [])

	if (user && user.id) {
		return (
			<div>
				<Menu />
				<div className="main-content" id="panel">
					<TopNav />
					<Header ref={headerRef} title={title} />
					<Router>
						<Route
							path="/"
							component={(props) => <Dashboard {...props} header={headerRef} />}
							exact
						/>
						<Route path="/maquinas-fixas" component={(props) => <MaquinasFixas {...props} />} exact />
						<Route path="/maquinas/detalhes" component={(props) => <Details {...props} />} exact />
						<Route path="/credenciais" component={(props) => <Credentials {...props} />} exact />
						<Route
							path="/importacoes/cpfs"
							component={(props) => <ImportacaoCpfs {...props} />}
							exact
						/>
						<Route
							path="/exportacoes/comissoes"
							component={(props) => <ExportacaoComissoes {...props} />}
							exact
						/>
						<Route
							path="/exportacoes/propostas-recusadas"
							component={(props) => <ExportacaoPropostasRecusadas {...props} />}
							exact
						/>

						<Route
							path="/poupanegocios/mapeamento-csg"
							component={(props) => <MapCSG {...props} />}
							exact
						/>
						<Route
							path="/poupanegocios/propostas"
							component={(props) => <PoupaconsigProposals {...props} />}
							exact
						/>
						<Route
							path="/poupanegocios/propostas-rascunhos"
							component={(props) => <PoupaconsigPropostasRascunho {...props} />}
							exact
						/>
						<Route
							path="/poupanegocios/propostas-banco"
							component={(props) => <PoupaconsigProposalsBank {...props} />}
							exact
						/>
						<Route
							path="/poupanegocios/propostas-parceiros"
							component={(props) => <PoupaconsigPropostasParceiro {...props} />}
							exact
						/>
						<Route
							path="/poupanegocios/live"
							component={(props) => <PoupaconsigLive {...props} />}
							exact
						/>
						<Route
							path="/poupanegocios/metricas-csg"
							component={(props) => <PoupaconsigMetricsCSG {...props} />}
							exact
						/>
						<Route
							path="/poupanegocios/erros"
							component={(props) => <PoupaconsigErrors {...props} />}
							exact
						/>
						<Route
							path="/poupanegocios/desconexoes"
							component={(props) => <PoupaconsigDisconnections {...props} />}
							exact
						/>
						<Route
							path="/poupanegocios/conexoes"
							component={(props) => <PoupaconsigConnections {...props} />}
							exact
						/>
						<Route
							path="/poupanegocios/siglas"
							component={(props) => <Siglas {...props} />}
							exact
						/>
						<Route
							path="/poupanegocios/siglas-compartilhadas"
							component={(props) => <SiglasCompartilhadas {...props} />}
							exact
						/>
						<Route
							path="/poupanegocios/configuracoes"
							component={(props) => <PoupaconsigConfig {...props} />}
							exact
						/>
						<Route path="/sim/robo/cp" component={(props) => <RobotSimCP {...props} />} exact />
						<Route
							path="/santander/robo/contratos"
							component={(props) => <RobotProposals {...props} />}
							exact
						/>
						<Route
							path="/santander/robo/taxa-convenio"
							component={(props) => <RobotTaxaConvenio {...props} />}
							exact
						/>
						<Route
							path="/santander/robo/cancelamento-proposta"
							component={(props) => <RobotCancelamentoProposta {...props} />}
							exact
						/>
						<Route
							path="/facta/regras"
							component={(props) => <FactaRegras {...props} />}
							exact
						/>
					</Router>
				</div>
			</div>
		)
	} else {
		return <Login />
	}
}

export default App
