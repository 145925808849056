import axios from "axios"
import { useState } from "react"
import Loader from "react-loader-spinner"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"

import { UPDATE_USER } from "../redux/Actions"

const https = require("https")
const { REACT_APP_SERVIDOR } = process.env

function Login(props) {
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const onClick = () => {
    if (email == null || email.length < 3) {
      toast.warn("Informe um login válido...")
    } else if (password == null || password.length < 0) {
      toast.warn("Informe uma senha...")
    } else {
      toast.promise(
        () => {
          return new Promise(async (resolve, reject) => {
            try {
              setLoading(true)

              const req = await axios.post(
                `${REACT_APP_SERVIDOR}geral/login`,
                { email, senha: password },
                {
                  httpsAgent: new https.Agent({
                    rejectUnauthorized: false,
                  }),
                }
              )

              dispatch({
                type: UPDATE_USER,
                payload: {
                  id: req.data.id,
                  nome: req.data.nome,
                  parceiro: req.data.parceiro,
                  email: req.data.email,
                  token: req.data.token,
                  permissoes: req.data.permissoes,
                  funcionarios: req.data.funcionarios,
                  cargos: req.data.cargos ? req.data.cargos.map(x => x.funcao_id) : [],
                },
              })
              resolve()
            } catch (err) {
              setLoading(false)
              console.warn(err)
              reject(err)
            }
          })
        },
        {
          pending: "Efetuando login...",
          success: "Login efetuado com sucesso!",
          error: "Falha ao efetuar login. Verifique suas credenciais, se você possui permissão e tente novamente.",
        }
      )
    }
  }

  return (
    <div style={{ backgroundColor: "#172b4d", height: "100vh" }}>
      <nav
        id="navbar-main"
        className="navbar navbar-horizontal navbar-transparent navbar-main navbar-expand-lg navbar-light"
      >
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-collapse"
            aria-controls="navbar-collapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="navbar-collapse navbar-custom-collapse collapse" id="navbar-collapse">
            <div className="navbar-collapse-header">
              <div className="row">
                <div className="col-6 collapse-close">
                  <button
                    type="button"
                    className="navbar-toggler"
                    data-toggle="collapse"
                    data-target="#navbar-collapse"
                    aria-controls="navbar-collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span />
                    <span />
                  </button>
                </div>
              </div>
            </div>
            <hr className="d-lg-none" />
          </div>
        </div>
      </nav>
      {/* Main content */}
      <div className="main-content">
        {/* Header */}
        <div className="header bg-gradient-primary py-6 py-lg-5 pt-lg-5">
          <div className="container">
            <div className="header-body text-center mb-7">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-9 px-2">
                  <img style={{ width: 150, marginBottom: 25 }} src="../../assets/img/brand/logo-pequeno.png" />
                  <p className="text-lead text-white">
                    Olá, você está acessando uma área restrita, por favor, informe seu login e senha.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              x={0}
              y={0}
              viewBox="0 0 2560 100"
              preserveAspectRatio="none"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon className="fill-default" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <div className="container mt--8 pb-5">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-7">
              <div className="card bg-secondary border-0 mb-0">
                <div className="card-body px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Entre com suas credenciais.</small>
                  </div>
                  <form role="form">
                    <div className="form-group mb-3">
                      <div className="input-group input-group-merge input-group-alternative">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="ni ni-circle-08" />
                          </span>
                        </div>
                        <input
                          disabled={loading}
                          value={email}
                          onChange={event => setEmail(event.target.value)}
                          className="form-control"
                          placeholder="Login"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group input-group-merge input-group-alternative">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="ni ni-lock-circle-open" />
                          </span>
                        </div>
                        <input
                          disabled={loading}
                          value={password}
                          onChange={event => setPassword(event.target.value)}
                          className="form-control"
                          placeholder="Senha"
                          type="password"
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      {loading ? (
                        <Loader type="ThreeDots" width={50} height={50} color="#172b4d" />
                      ) : (
                        <button disabled={loading} type="button" onClick={onClick} className="btn btn-primary my-4">
                          Entrar
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
