import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import axios from "axios"
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import TableV2 from "../components/TableV2"

import { LOGOUT, SET_TITLE } from "../redux/Actions"
import { pm } from "../utils"

const https = require("https")
const { REACT_APP_SERVIDOR } = process.env

const colunas = [
   { id: "lista", name: "Nome", sortable: false, cell: "lista" },
   { id: "iniciado_em", name: "Criada em", sortable: false, cell: "iniciado_em" },
   { id: "total", name: "Total", sortable: false, cell: "total" },
   { id: "processados", name: "Processados", sortable: false, cell: "processados" },
   { id: "nao_processados", name: "Não Processados", sortable: false, cell: "nao_processados" },
   { id: "porcentagem_processados", name: "% Processados", sortable: false, cell: "porcentagem_processados" },
   { id: "ultimo_processados", name: "Último Processados", sortable: false, cell: "ultimos_processados" }
]

function RobotTaxaConvenio(props) {
   const [permissao, setPermissao] = useState(false)
   const [filtro, setFiltro] = useState(null)
   const [indicadores, setIndicadores] = useState(null)
   const [listaApelidos, setListaApelidos] = useState([])
   const [apelidoSelecionado, setApelidoSelecionado] = useState(null)

   const tabela = useRef()

   const user = useSelector((state) => state.user)

   const dispatch = useDispatch()

   useEffect(() => {
		checarPermissao()
      handleBuscarIndicadores()
      handleBuscarListaApelidos()

      dispatch({ type: SET_TITLE, payload: null })
	}, [])

   const checarPermissao = () => {
      if (!pm(user, 17)) {
			props.history.push("/")
			toast.error("Você não tem permissão para acessar esta página.")
		} else {
			setPermissao(true)
	   }
   }

   const handleBuscarIndicadores = async () => {
      try {
         const response = await axios.get(`${REACT_APP_SERVIDOR}santander/robo/taxa-convenio/indicadores`, {
            headers: { token: user.token },
				httpsAgent: new https.Agent({
					rejectUnauthorized: false,
				})
         })

         
         if (response.status === 200) {
            setIndicadores(response.data)
         }
      } catch (error) {
         toast.error("Ops! Ocorreu um erro ao buscar os indicadores 🤯")
      }
   }

   const handleBuscarListaApelidos = async () => {
      try {
         setListaApelidos([])

         const response = await axios.get(`${REACT_APP_SERVIDOR}santander/robo/taxa-convenio/nome-listas`, {
            headers: { token: user.token },
				httpsAgent: new https.Agent({
					rejectUnauthorized: false,
				})
         })

         setListaApelidos(response.data)
      } catch (error) {
         console.error(error)
         toast.error("Ops! Ocorreu um erro ao buscar a lista dos apelidos 🤯")
      }
   }

   const handleGravarTaxaConvenioFila = async () => {
      try {
         const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-secondary'
         },
            buttonsStyling: false
         })

         swalWithBootstrapButtons.fire({
            title: "Taxas Convênio",
            text: "Deseja inserir uma nova lista de taxas de convênio?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, quero inserir',
            cancelButtonText: 'Não, quero cancelar',
            reverseButtons: true
         }).then(async (result) => {
            if (result.isConfirmed) {
               const response = await axios.post(`${REACT_APP_SERVIDOR}santander/robo/taxa-convenio/gravar-taxa-convenio-lista`, {
                  headers: { token: user.token },
                  httpsAgent: new https.Agent({
                     rejectUnauthorized: false,
                  })
               })

               if (response.status == 200) {
                  swalWithBootstrapButtons.fire(
                     "Sucesso",
                     "A lista foi inserida com sucesso",
                     "success"
                  )
               }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
               swalWithBootstrapButtons.fire(
                  "Ação cancelada",
                  "A lista não foi inserida na base",
                  "info"
               )
            }
         })
      } catch (error) {
         toast.error("Ops! Ocorreu um erro 🤯")
      }
   }

   const handleExportar = () => {
      try {
         window.open(
            `${REACT_APP_SERVIDOR}arquivo/excel/santander/robo/taxa-convenio?apelido=${encodeURIComponent(apelidoSelecionado)}`
         )
      } catch (error) {
         toast.error("Ops! Ocorreu um erro ao exportar a lista de Taxa Convênio 🤯")
      }
   }

   return (
      <main className="container-fluid mt--6">
         <section id="header" className="row card-wrapper">
            <div className="card-header bg-transparent border-0" style={{ width: "100vw" }}>
               <div className="row align-items-center">
                  <div className="col-8">
                     <h6 className="h2 d-inline-block mb-0">Robôs - Taxa por Convênio</h6>
                  </div>
                  <div className="col-4 text-right">
                     <button 
                        type="button" 
                        className="btn btn-primary btn-sm" 
                        onClick={handleGravarTaxaConvenioFila}
                     >
                        <i className="fas fa-solid fa-plus"></i>&nbsp;
                        Inserir taxas
                     </button>
                  </div>
               </div>
            </div>
         </section>

         <section id="cardIndicadores" className="row">
            {indicadores && 
            indicadores.map((item, index) => (
               <div className="col-xl-4 col-md-6" key={ index }>
                  <div className="card card-stats">
                     <div className="card-body">
                        <div className="row">
                           <div className="col">
                              <h5 className="card-title text-uppercase text-muted mb-0">{ item.title }</h5>
                              <span className="h4 font-weight-bold mb-0">{ item.value }</span>
                           </div>
                           <div className="col-auto">
                              <div 
                                 className={`
                                    icon icon-shape bg-gradient-${item.color || "red"} text-white rounded-circle shadow
                                 `}
                              >
                                 <i className={ item.icon || "ni ni-active-40" } />
                              </div>
                           </div>                        
                        </div>
                     </div>
                  </div>
               </div>
            ))}
         </section>

         <section id="listaTaxaConvenios" className="row">
            <div className="col-md-12">
               <div className="card">
                  <div className="card-header border-0">
                     <div className="row">
                        <div className="col-9">
                           <h3 className="mb-0">Listas</h3>
                        </div>
                        <div className="col-3 text-right" style={{ display: "none" }}>
                           <div className="form-group">
                              <input 
                                 type="search" 
                                 className="form-control form-control-sm" 
                                 placeholder="Pesquisar Taxa Convênio" 
                                 aria-label="Pesquisar Taxa Convênio" 
                                 aria-describedby="basic-addon1" 
                              />
                           </div>
                        </div>
                     </div>
                  </div>

                  <TableV2 ref={tabela} filter={filtro} table={"santander/lista-taxa-convenio"} columns={colunas} />
               </div>
            </div>
         </section>

         <section id="exportarTaxaConvenios" className="row">
            <div className="col-md-6">
               <div className="card">
                  <div className="card-header">
						   <h3 className="mb-0">Exportar Lista</h3>
						</div>
                  <div className="card-body">
                     <div className="form-group">
                        <label className="form-control-label" htmlFor="example3cols1Input">
                           Lista*
                        </label>
                        <select 
                           className="form-control"
                           value={apelidoSelecionado}
                           onChange={(el) => setApelidoSelecionado(el.target.value)}
                        >
                           <option value="">Selecione um apelido</option>
                           {listaApelidos && listaApelidos.map(item => (
                              <option value={item}>{item ? item : "Sem apelido"}</option>
                           ))}
                        </select>
						   </div>
                     <div className="form-group text-left">
                        <button
                           className="btn btn-primary"
                           onClick={handleExportar}
                           disabled={apelidoSelecionado ? false : true}
                        >
                           <i class="fas fa-solid fa-file-excel"></i>&nbsp;
                           Exportar
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </main>
   )
}

export default RobotTaxaConvenio