import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";

import { SET_TITLE } from "../redux/Actions";

import TableV2 from "../components/TableV2";

import { pm } from "../utils";

const https = require("https");
const { REACT_APP_SERVIDOR } = process.env;

const color = (x) => {
   if (x == "Reprovado" || x == "Cancelado") {
      return "red";
   } else if (x == "Integrado") {
      return "green";
   } else if (x == "Andamento") {
      return "orange";
   }
};

const columns = [
   {
      id: "criado_em",
      name: "Data Cadastro",
      selector: "cri",
      sortable: true,
   },
   {
      id: "dados->>'dtBase'",
      name: "Data Base",
      selector: "base",
      sortable: true,
   },
   {
      id: "iniciais->>'escritorio'",
      name: "Corban",
      selector: "corban",
      sortable: true,
   },
   {
      id: "proposta",
      name: "Proposta",
      selector: "proposta",
      sortable: true,
   },
   {
      id: "dados->>'a'",
      name: "Valor",
      selector: "valor",
      sortable: true,
   },
   {
      id: "status",
      name: "Status",
      selector: "status",
      cell: (row) =>
         row.status ? (
            <span>
               <span style={{ color: color(row.status) }}>● </span>
               <b>{row.status || "-"}</b>
            </span>
         ) : (
            ""
         ),
      sortable: true,
   },
   {
      id: "usuario->>'parceiro'",
      name: "Tipo",
      cell: (row) => <span>{row.tipo}</span>,
      sortable: true,
   },
   {
      id: "csg->>'login'",
      name: "Login",
      selector: "login",
      sortable: true,
   },
   {
      id: "senha",
      name: "Senha",
      selector: "senha",
      sortable: true,
   },
   {
      id: "usuario->>'nome'",
      name: "Responsável",
      selector: "nome",
      cell: (row) => <b>{row.nome}</b>,
      sortable: true,
   },
   {
      id: "dados->>'cpf'",
      name: "CPF - Cliente",
      selector: "cpf_cliente",
   },
   {
      id: "dados->>'nome'",
      name: "Nome - Cliente",
      selector: "nome_cliente",
      sortable: true,
   },
];

export function PoupaconsigPropostasParceiro(props) {
   const user = useSelector((state) => state.user);

   const [permission, setPermission] = useState(false);
   const [filter, setFilter] = useState(null);

   const dispatch = useDispatch();

   const checkPermission = () => {
      if (!pm(user, 15)) {
         props.history.push("/");
         toast.error("Você não possui permissão para acessar essa página.");
      } else {
         setPermission(true);
         dispatch({
            type: SET_TITLE,
            payload: "Poupanegócios > Propostas (Parceiro)",
         });
      }
   };

   const downloadProposalPartner = async (link) => {
      try {
         const result = await axios.post(
            `${REACT_APP_SERVIDOR}geral/proposta/download`,
            {
               linkPath: link,
            },
            {
               headers: { token: user.token },
               httpsAgent: new https.Agent({
                  rejectUnauthorized: false,
               }),
            }
         );

         const { status, data } = result;

         if (status === 200) {
            window.open(data.link);
         }
      } catch (error) {
         console.log(error);
      }
   };

   useEffect(() => {
      checkPermission();
   }, []);

   if (!permission) {
      return null;
   }

   return (
      <div className="container-fluid mt--6">
         <div className="card">
            <div className="card-header border-0">
               <div className="row">
                  <div className="col-9">
                     <h3 className="mb-0">Tabela</h3>
                  </div>
                  <div className="col-3 text-right">
                     <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder="Pesquisar"
                        aria-controls="datatable-basic"
                        onChange={(e) => setFilter(e.target.value)}
                     />
                  </div>
               </div>
            </div>
            <TableV2
               table={"poupaconsig/propostas-parceiros"}
               columns={[
                  ...columns,
                  {
                     id: "actions",
                     name: "Download",
                     cell: (row) =>
                        row.link_download !== "" ? (
                           <a
                              href="#!"
                              className="table-action text-success"
                              data-toggle="tooltip"
                              data-placement="left"
                              title="Baixar Contrato"
                              onClick={() =>
                                 downloadProposalPartner(row.link_download)
                              }
                           >
                              <i className="fas fa-download" />
                           </a>
                        ) : (
                           <a
                              href="#!"
                              className="table-action"
                              style={{ cursor: "not-allowed" }}
                           >
                              <i className="fas fa-download" />
                           </a>
                        ),
                  },
               ]}
               filter={filter}
            />
         </div>
      </div>
   );
}
