import React, { useRef, useMemo, useState } from "react"
import PropTypes from "prop-types"

// function Input({}) {}
const Input = ({
   id,
   type,
   help,
   label,
   value,
   action,
   display,
   onChange,
   required,
   htmlType,
   disabled,
   helpType,
   placeholder,
   col, // to width
   ...rest
}) => {
   const inputRef = useRef(null)

   const handleChange = (event) => {
      let { value: valueSelected } = event.target

      if (typeof onChange === "function") {
         onChange({ target: { id, value: valueSelected } })
      }
   }

   const renderValue = useMemo(() => {
      if (value) {
         switch (type) {
            default:
            break;
         }
      }

      return value;
   }, [type, value]);

   const renderPlaceholder = useMemo(() => {
      switch (type) {
         case 'cep':
         case 'cpf':
         case 'cpfCnpj':
         case 'money':
         case 'phone':
         default:
            break;
      }
  
      return placeholder;
   }, [type, placeholder]);
   
   return (
      <div
         data-label={!!label}
         data-display={display}
         data-col={typeof col === 'function' ? col(id) : col}
      >
         <div data-action={!!action}>
            <input
               {...rest}
               id={id}
               ref={inputRef}
               required={required}
               disabled={disabled}
               value={renderValue}
               onChange={handleChange}
               placeholder={renderPlaceholder}
               type={type ? type : "text"}
               accept="image/x-png,application/pdf,image/jpeg"
            />
         </div>
      </div>
   )
}

Input.defaultProps = {
   col: 16,
   help: '',
   label: '',
   action: null,
   type: 'text',
   required: false,
   disabled: false,
   htmlType: 'text',
   display: 'vertical',
}

Input.propTypes = {
   help: PropTypes.string,
   label: PropTypes.string,
   required: PropTypes.bool,
   disabled: PropTypes.bool,
   action: PropTypes.object,
   helpType: PropTypes.string,
   id: PropTypes.string.isRequired,
   onChange: PropTypes.func.isRequired,
   display: PropTypes.oneOf(['vertical', 'horizontal']),
   htmlType: PropTypes.oneOf(['text', 'password', 'email']),
   col: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
   type: PropTypes.oneOf([
     'text',
     'cep',
     'cpf',
     'cpfCnpj',
     'phone',
     'money',
     'number',
     'birthday',
     'percentual'
   ])
}

export default Input