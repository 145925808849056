import axios from "axios";
import { useEffect, useState, useRef } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import TableV2 from "../components/TableV2";

import { LOGOUT, SET_TITLE } from "../redux/Actions";
import { pm } from "../utils";

const https = require("https");
const { REACT_APP_SERVIDOR } = process.env;
const columns = [
   {
      id: "lista",
      name: "Nome",
      sortable: false,
      cell: (x) => <b>{x.lista}</b>,
   },
   {
      id: "criado_em",
      name: "Criada Em",
      sortable: false,
      cell: "criado_em",
   },
   {
      id: "total",
      name: "Total de Nomes",
      sortable: false,
      cell: "total",
   },
   {
      id: "p",
      name: "Processados",
      sortable: false,
      cell: "p",
   },
   {
      id: "np",
      name: "Não Processados",
      sortable: false,
      cell: "np",
   },
   {
      id: "pc",
      name: "% Processamento",
      sortable: false,
      cell: (x) => <b>{x.pc}</b>,
   },
   {
      id: "ult",
      name: "Ult. Processamento",
      sortable: false,
      cell: "ult",
   },
   {
      id: "e",
      name: "Elegíveis",
      sortable: false,
      cell: "e",
   },
   {
      id: "ne",
      name: "Não Elegíveis",
      sortable: false,
      cell: "ne",
   },
   {
      id: "pe",
      name: "% Elegíveis",
      sortable: false,
      cell: (x) => <b>{x.pe}</b>,
   },
   {
      id: "puro",
      name: "CP - Puro",
      sortable: false,
      cell: "puro",
   },
   {
      id: "auto",
      name: "CP - Auto",
      sortable: false,
      cell: "auto",
   },
   {
      id: "moto",
      name: "CP - Moto",
      sortable: false,
      cell: "moto",
   },
];

function RobotSimCP(props) {
   const user = useSelector((state) => state.user);
   const [indicators, setIndicators] = useState(null);
   const [selectedFile, setSelectedFile] = useState();
   const [isFilePicked, setIsFilePicked] = useState(false);
   const [uploading, setUploading] = useState(false);
   const [name, setName] = useState(null);
   const [prioridade, setPrioridade] = useState(null);
   const table = useRef();
   const [filter, setFilter] = useState(null);
   const [lists, setLists] = useState([]);
   const [list, setList] = useState(null);
   const [permitted, setPermitted] = useState(false);

   const dispatch = useDispatch();

   const checkPermission = () => {
      if (!pm(user, 11)) {
         props.history.push("/");
         toast.error("Você não tem permissão para acessar esta página.");
      } else {
         setPermitted(true);
         dispatch({ type: SET_TITLE, payload: "Robô - Crédito Pessoal" });

         try {
            getIndicators();
            getLists();
         } catch (err) {
            console.warn(err);
         }
      }
   };

   useEffect(() => {
      checkPermission();
   }, []);

   const changeHandler = (event) => {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
   };

   const removeFile = () => {
      setIsFilePicked(false);
      setSelectedFile(undefined);
   };

   const handleExport = () => {
      window.open(
         `${REACT_APP_SERVIDOR}arquivo/excel/sim/robo/cp?apelido=${encodeURIComponent(
            list
         )}`
      );
   };

   const handleSubmission = async () => {
      if (name == null || name.length < 3) {
         toast.warning(
            "Informe um apelido para lista que contenha mais de três caracteres..."
         );
      } else if (selectedFile == null) {
         toast.warning("Selecione um arquivo CSV...");
      } else if (selectedFile.size / 1024 / 1024 > 5) {
         toast.warning(
            "Arquivo deve conter no máximo 5MB. Se necessário divida o arquivo em vários e faça o upload de todos com o mesmo APELIDO."
         );
      } else {
         toast.promise(
            () => {
               return new Promise(async (resolve, reject) => {
                  try {
                     setUploading(true);

                     const formData = new FormData();
                     formData.append("file", selectedFile);
                     formData.append("apelido", name);
                     formData.append("prioridade", prioridade);

                     await axios.post(
                        `${REACT_APP_SERVIDOR}sim/robo/cp/importar`,
                        formData,
                        {
                           timeout: 5 * 60000,
                           headers: {
                              token: user.token,
                              "Content-Type": "multipart/form-data",
                           },
                           httpsAgent: new https.Agent({
                              rejectUnauthorized: false,
                           }),
                        }
                     );

                     setUploading(false);
                     getIndicators();
                     getLists();
                     if (table) {
                        table.current.refresh();
                     }

                     removeFile();
                     resolve();
                  } catch (err) {
                     setUploading(false);
                     console.warn(err);
                     reject(err);

                     if (err && err.response && err.response.status == 403) {
                        dispatch({ type: LOGOUT });
                        if (!toast.isActive("session")) {
                           toast.warning(
                              "Sua sessão foi expirada. Faça login novamente!",
                              { toastId: "session" }
                           );
                        }
                     }
                  }
               });
            },
            {
               pending: `Aguarde, fazendo upload dos nomes...`,
               success: `Upload do arquivo feito com sucesso. EM BREVE OS NOMES SERÃO PROCESSADOS!`,
               error: `Falha ao efetuar upload. Verifique sua conexão e tente novamente.`,
            }
         );
      }
   };

   const getIndicators = async () => {
      try {
         const req = await axios.get(
            `${REACT_APP_SERVIDOR}sim/robo/cp/indicadores`,
            {
               headers: { token: user.token },
               httpsAgent: new https.Agent({
                  rejectUnauthorized: false,
               }),
            }
         );

         setIndicators(req.data);
      } catch (err) {
         if (err && err.response && err.response.status == 403) {
            dispatch({ type: LOGOUT });
            if (!toast.isActive("session")) {
               toast.warning("Sua sessão foi expirada. Faça login novamente!", {
                  toastId: "session",
               });
            }
         }
      }
   };

   const getLists = async () => {
      try {
         setLists([]);

         const req = await axios.get(
            `${REACT_APP_SERVIDOR}sim/robo/cp/nome-listas`,
            {
               headers: { token: user.token },
               httpsAgent: new https.Agent({
                  rejectUnauthorized: false,
               }),
            }
         );

         setLists(req.data);

         if (req.data && req.data.length > 0) {
            setList(req.data[0]);
         }
      } catch (err) {
         if (err && err.response && err.response.status == 403) {
            dispatch({ type: LOGOUT });
            if (!toast.isActive("session")) {
               toast.warning("Sua sessão foi expirada. Faça login novamente!", {
                  toastId: "session",
               });
            }
         }
      }
   };

   if (!permitted) {
      return null;
   }

   return (
      <div className="container-fluid mt--6">
         <div className="row">
            {/* Indicadores */}
            {indicators == null && (
               <div className="col-12 text-center mb-5">
                  <Loader
                     type="ThreeDots"
                     width={50}
                     height={50}
                     color="#172b4d"
                  />
               </div>
            )}
            {indicators &&
               indicators.map((x, i) => (
                  <div key={i} className="col-xl-4 col-md-6">
                     <div className="card card-stats">
                        <div className="card-body">
                           <div className="row">
                              <div className="col">
                                 <h5 className="card-title text-uppercase text-muted mb-0">
                                    {x.title}
                                 </h5>
                                 <span className="h4 font-weight-bold mb-0">
                                    {x.value}
                                 </span>
                              </div>
                              <div className="col-auto">
                                 <div
                                    className={`icon icon-shape bg-gradient-${
                                       x.color || "red"
                                    } text-white rounded-circle shadow`}
                                 >
                                    <i
                                       className={x.icon || "ni ni-active-40"}
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               ))}
         </div>
         <div className="row">
            <div className="col-md-12">
               <div className="card">
                  {/* Card header */}
                  <div className="card-header border-0">
                     <div className="row">
                        <div className="col-9">
                           <h3 className="mb-0">Listas</h3>
                        </div>
                        <div className="col-3 text-right">
                           <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder="Pesquisar usuário..."
                              aria-controls="datatable-basic"
                              onChange={(e) => setFilter(e.target.value)}
                           />
                        </div>
                     </div>
                  </div>
                  <TableV2
                     ref={table}
                     filter={filter}
                     table={"sim/listas-cp"}
                     columns={columns}
                  />
               </div>
            </div>
            <div className="col-md-6">
               <div className="card">
                  <div className="card-header">
                     <h3 className="mb-0">Importar lista</h3>
                  </div>
                  {uploading ? (
                     <div className="card-body text-center">
                        <Loader
                           type="ThreeDots"
                           width={50}
                           height={50}
                           color="#172b4d"
                        />
                     </div>
                  ) : (
                     <div className="card-body">
                        <div className="row">
                           <div className="col-lg-8">
                              <div className="form-group">
                                 <label
                                    className="form-control-label"
                                    htmlFor="example3cols1Input"
                                 >
                                    Apelido*
                                 </label>
                                 <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Digite um nome para identificar o bloco de nomes posteriormente..."
                                    onChange={(e) => setName(e.target.value)}
                                 />
                              </div>
                           </div>

                           <div className="col-lg-4">
                              <div className="form-group">
                                 <label
                                    className="form-control-label"
                                    htmlFor="example3cols1Input"
                                 >
                                    Prioridade
                                 </label>
                                 <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Informe o nível de prioridade"
                                    onChange={(e) =>
                                       setPrioridade(e.target.value)
                                    }
                                 />
                              </div>
                           </div>
                        </div>

                        <div className="row">
                           <div className="col-lg-12">
                              <div className="form-group">
                                 {isFilePicked ? (
                                    <div style={{ marginTop: 20 }}>
                                       <p className="form-control-label">
                                          Nome: {selectedFile.name}
                                       </p>
                                       <p className="form-control-label">
                                          Tipo: {selectedFile.type}
                                       </p>
                                       <p className="form-control-label">
                                          Tamanho em bytes: {selectedFile.size}
                                       </p>
                                       <p className="form-control-label">
                                          Ult. Modificação:{" "}
                                          {selectedFile.lastModifiedDate.toLocaleDateString()}
                                       </p>
                                    </div>
                                 ) : (
                                    <div className="form-group">
                                       <label
                                          className="form-control-label"
                                          htmlFor="example3cols1Input"
                                       >
                                          Arquivo CSV*
                                       </label>
                                       <div className="custom-file">
                                          <input
                                             type="file"
                                             className="custom-file-input"
                                             id="customFileLang"
                                             lang="en"
                                             accept=".csv"
                                             onChange={changeHandler}
                                          />
                                          <label className="custom-file-label">
                                             Selecionar arquivo...
                                          </label>
                                       </div>
                                    </div>
                                 )}
                              </div>
                           </div>
                        </div>
                        {isFilePicked && (
                           <button
                              type="button"
                              class="btn btn-danger btn-block"
                              onClick={removeFile}
                           >
                              {`Remover ${selectedFile.name}`}
                           </button>
                        )}
                        {isFilePicked && (
                           <button
                              type="button"
                              class="btn btn-success btn-block"
                              onClick={handleSubmission}
                           >
                              Fazer Upload
                           </button>
                        )}
                     </div>
                  )}
               </div>
            </div>
            <div className="col-md-6">
               <div className="card">
                  <div className="card-header">
                     <h3 className="mb-0">Exportar lista</h3>
                  </div>
                  <div className="card-body">
                     <div className="form-group">
                        <label
                           className="form-control-label"
                           htmlFor="example3cols1Input"
                        >
                           Lista*
                        </label>
                        <select
                           value={list}
                           onChange={(e) => setList(e.target.value)}
                           className="form-control"
                        >
                           {lists &&
                              lists.map((x) => <option value={x}>{x}</option>)}
                        </select>
                     </div>
                     <div className="form-group">
                        <label
                           className="form-control-label"
                           htmlFor="example3cols1Input"
                        >
                           Ação
                        </label>
                        <button
                           disabled={list == null}
                           type="button"
                           class="btn btn-info btn-block"
                           onClick={handleExport}
                        >
                           {list
                              ? `Exportar dados de '${list}'`
                              : "Exportar Dados"}
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default RobotSimCP;
