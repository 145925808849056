import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { LOGOUT, SET_TITLE } from "../redux/Actions";

import { pm } from "../utils"
import { Robot } from "../components/Robot";

import Input from "../components/Input/Input";

const https = require("https");
const { REACT_APP_SERVIDOR } = process.env;

export function MaquinasFixas() {
   const [permissao, setPermissao] = useState(false);
   const [selecionado, setSelecionado] = useState([]);
   const [maquinasFixas, setMaquinasFixas] = useState([]);
   const [dadosMaquinaFixa, setDadosMaquinaFixa] = useState({});
   const [dadosFiltroMaquinaFixa, setDadosFiltroMaquinaFixa] = useState({});
   const [roboMaquinaFixa, setRoboMaquinaFixa] = useState([]);
   const [listaRobos, setListaRobos] = useState([]);
   const [editarRoboMaquinaFixa, setEditarRoboMaquinaFixa] = useState(false);
   const [removerRoboMaquinaFixa, setRemoverRoboMaquinaFixa] = useState([]);
   const [valoresParaTrocarRoboMaquinaFixa, setValoresParaTrocarRoboMaquinaFixa] = useState([]);
   const [filtroAtivo, setFiltroAtivo] = useState(false);
   const [erros, setErros] = useState({});
   const [errosFiltro, setErrosFiltro] = useState({});

   const usuario = useSelector(state => state.user);
   const dispatch = useDispatch();

   function verificarPermissao(props) {
      if (!pm(usuario, 1)) {
         props.history.push("/");
         toast.error("Você não possui permissão para acessar essa página!");
      } else {
         setPermissao(true);
         dispatch({ type: SET_TITLE, payload: "Máquinas Fixas" });
      }
   }

   function handleChange(event) {
      const { id, value } = event.target;

      setDadosMaquinaFixa({ ...dadosMaquinaFixa, [id]: value });
      setErros((prevErros) => ({ ...prevErros, [id]: "" }));
   }

   function handleChangeFiltro(event) {
      const { id, value } = event.target;

      setDadosFiltroMaquinaFixa({ ...dadosFiltroMaquinaFixa, [id]: value });
      setErrosFiltro((prevErros) => ({ ...prevErros, [id]: "" }));
   }

   function handleBlur(event) {
      const { id, value } = event.target;
      const mensagem = "Campo obrigatório";

      if (!value) {
         setErros((prevErros) => ({ ...prevErros, [id]: mensagem }));
      }
   }

   function handleBlurFiltro(event) {
      const { id, value } = event.target;
      const mensagem = "Campo obrigatório";

      if (!value) {
         setErrosFiltro((prevErros) => ({ ...prevErros, [id]: mensagem }));
      }
   }

   function onClick(mac) {
      if (selecionado.includes(mac)) {
         setSelecionado(selecionado.filter(item => item != mac));
      } else {
         setSelecionado([...selecionado, mac]);
      }
   }

   function limparFormulario() {
      setDadosMaquinaFixa({});
      setRoboMaquinaFixa([]);

      document.getElementById("mac").value = ""
      document.getElementById("anydesk").value = ""
      document.getElementById("senha").value = ""
      document.getElementById("robo").value = ""
      document.getElementById("horaInicio").value = ""
      document.getElementById("horaTermino").value = ""
   }

   function limparFormularioFiltroMaquinasFixas() {
      setDadosFiltroMaquinaFixa({});

      window.$("#modalFiltroMaquinaFixa").modal("hide");

      document.getElementById("computadorFiltro").value = "";
      document.getElementById("macFiltro").value = "";
      document.getElementById("roboFiltro").value = "";
   }

   function montarTabelaRobosMaquinaFixa(data) {
      const buscarRobo = (roboId) => {
         return listaRobos.find(item => item.id == roboId).nome;
      }

      return data.map((item, index) => (
         <tr key={index}>
            <td>{item.roboId}</td>
            <td>{buscarRobo(item.roboId)}</td>
            <td>{item.horaInicio} - {item.horaTermino}</td>
            <td className="table-actions text-center">
               <span
                  className="table-action"
                  onClick={() => habilitarEdicaoRoboMaquinaFixa(item)}
                  style={{ cursor: "pointer", display: item.maquinaFixaId == 0 && "none" }}
               >
                  <i className="fas fa-user-edit"></i>
               </span>
               <span
                  className="table-action"
                  onClick={() => removerRobo(item, index)}
                  style={{ cursor: "pointer" }}
               >
                  <i className="fas fa-trash"></i>
               </span>
            </td>
         </tr>
      ));
   }

   function habilitarEdicaoRoboMaquinaFixa(dados) {
      setEditarRoboMaquinaFixa(true);
      setValoresParaTrocarRoboMaquinaFixa(dados);

      document.getElementById("robo").value = dados.roboId;
      document.getElementById("horaInicio").value = dados.horaInicio;
      document.getElementById("horaTermino").value = dados.horaTermino;
   }

   function desabilitarEdicaoRoboMaquinaFixa() {
      setEditarRoboMaquinaFixa(false);
      setValoresParaTrocarRoboMaquinaFixa([]);

      document.getElementById("robo").value = "";
      document.getElementById("horaInicio").value = "";
      document.getElementById("horaTermino").value = "";
   }

   async function pausarMaquinaFixa(mac) {
      try {
         const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
               confirmButton: 'btn btn-primary',
               cancelButton: 'btn btn-secondary'
            },
            buttonsStyling: false
         })

         swalWithBootstrapButtons.fire({
            title: "Deseja pausar a máquina?",
            text: "A máquina será pausada e o robô não irá processar",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, quero pausar",
            cancelButtonText: "Não, cancelar",
            reverseButtons: true
         }).then(async (result) => {
            if (result.isConfirmed) {
               const resultado = await axios.patch(`${REACT_APP_SERVIDOR}maquinas-fixas/pausar?mac=${mac}`, {
                  httpsAgent: new https.Agent({
                     rejectUnauthorized: false
                  })
               });

               const { status } = resultado;
               const { retorno } = resultado.data;

               if (status === 200 && retorno) {
                  swalWithBootstrapButtons.fire(
                     "Máquina Fixa pausada",
                     `A Máquina Fixa de mac: ${mac}, foi pausada com sucesso.`,
                     "success"
                  )

                  listarMaquinasFixas();
               }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
               swalWithBootstrapButtons.fire(
                  "Ação cancelada",
                  "A máquina fixa não foi pausada",
                  "info"
               );
            }
         })
      } catch (error) {
         console.error(error.message)
         toast.error(`Ocorreu um erro ao pausar a máquina fixa com o mac: ${mac}`)
      }
   }

   function adicionarRoboMaquinaFixa() {
      const { robo, horaInicio, horaTermino } = dadosMaquinaFixa;

      if (robo == null) {
         toast.warn("Para adicionar, primeiro você precisa selecionar um robô");
         return;
      }

      if (horaInicio > horaTermino) {
         toast.warn("A hora de inicio não pode ser maior que a hora de termino");
         return;
      }

      try {
         setRoboMaquinaFixa(preRoboMaquinaFixa => [...preRoboMaquinaFixa, {
            maquinaFixaId: 0,
            roboId: robo,
            horaInicio,
            horaTermino
         }]);

         document.getElementById("robo").value = "";
         document.getElementById("horaInicio").value = "";
         document.getElementById("horaTermino").value = "";

         setValoresParaTrocarRoboMaquinaFixa([]);
         setDadosMaquinaFixa({ ...dadosMaquinaFixa, roboId: "", horaInicio: "", horaTermino: "" });
      } catch (error) {
         toast.error("Ocorreu um erro ao adicionar um novo robô");
         console.error(error.message);
      }
   }

   function removerRobo(dadosRobo, roboIndex) {
      try {
         roboMaquinaFixa.filter((item, index) => {
            if (dadosRobo.maquinaFixaId === item.maquinaFixaId) {
               setRemoverRoboMaquinaFixa(prevRemoverRoboMaquinaFixa => [...prevRemoverRoboMaquinaFixa, item]);
            }

            setRoboMaquinaFixa(current => current.filter((item, index) => {
               return dadosRobo.maquinaFixaId !== item.maquinaFixaId;
            }));
         });
      } catch (error) {
         console.error(error.message);
         toast.error("Ocorreu um erro ao remover um robô da máquina fixa");
      }
   }

   async function filtrarListaMaquinaFixa() {
      const { computadorFiltro, macFiltro, roboFiltro } = dadosFiltroMaquinaFixa;

      try {
         const resultado = await axios.get(`${REACT_APP_SERVIDOR}maquinas-fixas/listar`,
            {
               params: {
                  roboId: roboFiltro,
                  nome: computadorFiltro,
                  mac: macFiltro
               }
            },
            {
               headers: { token: usuario.token },
               httpsAgent: new https.Agent({
                  rejectUnauthorized: false,
               }),
            }
         );

         const { status } = resultado;
         const { dados, retorno } = resultado.data;

         if (retorno && status === 200) {
            setMaquinasFixas(dados);
            setDadosFiltroMaquinaFixa({});
            setFiltroAtivo(true);

            limparFormularioFiltroMaquinasFixas();
         }
      } catch (error) {
         console.error(error);
         toast.error("Ocorreu um erro ao filtrar a lista das máquinas fixas");
      }
   }

   async function listarMaquinasFixas() {
      try {
         setMaquinasFixas([]);

         const resultado = await axios.get(`${REACT_APP_SERVIDOR}maquinas-fixas/listar`, {
            headers: { token: usuario.token },
            httpsAgent: new https.Agent({
               rejectUnauthorized: false,
            }),
         });

         const { status } = resultado;
         const { dados, retorno } = resultado.data;

         if (retorno && status === 200) {
            setMaquinasFixas(dados);
            setFiltroAtivo(false);
         }
      } catch (error) {
         if (error && error.response && error.response.status === 403) {
            dispatch({ type: LOGOUT });

            if (!toast.isActive("session")) {
               toast.warning("Sua sessão foi expirada. Faça o login novamente!");
            }
         }
      }
   }

   async function listarRobos() {
      try {
         const resultado = await axios.get(`${REACT_APP_SERVIDOR}robos/listar`, {
            httpsAgent: new https.Agent({
               rejectUnauthorized: false
            })
         });

         const { status } = resultado;
         const { dados, retorno } = resultado.data;

         if (status === 200 && retorno) {
            setListaRobos(dados);
         }
      } catch (error) {
         console.error(error.message);
         toast.error("Ocorreu um erro ao retornar uma lista dos robôs");
      }
   }

   async function buscarRobosMaquinaFixa(mac) {
      setRoboMaquinaFixa([]);

      if (mac == null || mac == "") {
         toast.error("Ocorreu um erro ao buscar os robôs devido ao MAC estar vazio");
         return;
      }

      try {
         const resultado = await axios.get(`${REACT_APP_SERVIDOR}maquinas-fixas/robos/buscar-pelo-mac?mac=${mac}`, {
            httpsAgent: new https.Agent({
               rejectUnauthorized: false
            })
         });

         const { status } = resultado;
         const { dados, retorno } = resultado.data;

         if (status === 200 && retorno) {
            dados.map(item => {
               const maquinaFixaId = item.id;
               const roboId = item.robo;
               const horaInicio = item.inicio ? moment(item.inicio, "h:mm").format("HH:mm") : null;
               const horaTermino = item.termino ? moment(item.termino, "h:mm").format("HH:mm") : null;

               setRoboMaquinaFixa(prevRoboMaquinaFixa => [
                  ...prevRoboMaquinaFixa,
                  { maquinaFixaId, roboId, horaInicio, horaTermino }
               ]);
            });
         }
      } catch (error) {
         console.error(error.message);
         toast.error("Ocorreu um erro ao retornar os robôs da máquina fixa selecionada");
      }
   }

   async function salvarMaquinaFixa() {
      const { computador, link, mac, anydesk, senha } = dadosMaquinaFixa;

      const robos = roboMaquinaFixa;
      const removerRobos = removerRoboMaquinaFixa;

      try {
         const resultado = await axios.post(`${REACT_APP_SERVIDOR}maquinas-fixas/salvar`,
            {
               computador,
               link,
               mac,
               anydesk,
               senha,
               robos,
               removerRobos
            },
            {
               httpsAgent: new https.Agent({
                  rejectUnauthorized: false
               })
            }
         );

         const { status } = resultado;
         const { retorno } = resultado.data;

         if (status === 200 && retorno) {
            toast.success("Máquina Fixa salva com sucesso");

            window.$("#modalFormularioMaquinaFixa").modal("hide");

            limparFormulario();
            listarMaquinasFixas();
         }
      } catch (error) {
         console.error(error);
         toast.error("Ocorreu um erro ao salvar a máquina fixa");
      }
   }

   function editarMaquinaFixa(data) {
      if (data == null) {
         toast.error("Erro ao selecionar a máquina fixa para editar");
         return;
      }

      setDadosMaquinaFixa(data);
      buscarRobosMaquinaFixa(data.mac);

      window.$("#modalFormularioMaquinaFixa").modal("show");
   }

   async function editarRobo() {
      const valoresParaTrocar = valoresParaTrocarRoboMaquinaFixa;

      const horaInicio = document.getElementById("horaInicio").value;
      const horaTermino = document.getElementById("horaTermino").value;
      const roboId = document.getElementById("robo").value;

      const novoValor = roboMaquinaFixa.map(robo => {
         if (robo.maquinaFixaId === valoresParaTrocar.maquinaFixaId) {
            return { ...robo, roboId, horaInicio, horaTermino }
         }

         return robo;
      });

      setRoboMaquinaFixa(novoValor);
      setDadosMaquinaFixa({ ...dadosMaquinaFixa, roboId: "", horaInicio: "", horaTermino: "" });

      desabilitarEdicaoRoboMaquinaFixa();
   }

   useEffect(() => {
      try {
         verificarPermissao();
         listarMaquinasFixas();
         listarRobos();
      } catch (error) {
         console.error(error);
      }
   }, []);

   useEffect(() => {
      montarTabelaRobosMaquinaFixa(roboMaquinaFixa);
   }, [roboMaquinaFixa]);

   if (!permissao) return null;

   return (
      <div className="container-fluid mt--6">
         <div className="card">
            <div className="card-body border-0">
               <div className="row align-items-center">
                  <div className="col-8">
                     <h5 className="h3 mb-0">Lista</h5>
                  </div>
                  <div className="col-4 text-right">
                     {filtroAtivo && (
                        <button
                           type="button"
                           className="btn btn-sm btn-primary btn-round btn-icon"
                           data-toggle="tooltip"
                           data-placement="left"
                           title="Limpar filtro da lista"
                           onClick={listarMaquinasFixas}
                        >
                           <span className="btn-inner--icon">
                              <i className="fas fa-sync" />
                           </span>
                        </button>
                     )}
                     <button
                        type="button"
                        data-toggle="modal"
                        data-target="#modalFiltroMaquinaFixa"
                        className="btn btn-sm btn-primary btn-round btn-icon"
                     >
                        <span className="btn-inner--icon">
                           <i className="fas fa-search"></i>
                        </span>
                        <span className="btn-inner--text">
                           Filtrar
                        </span>
                     </button>

                     <button
                        type="button"
                        className="btn btn-sm btn-primary btn-round btn-icon"
                        data-toggle="modal"
                        data-target="#modalFormularioMaquinaFixa"
                        onClick={limparFormulario}
                     >
                        <span className="btn-inner--icon">
                           <i className="fas fa-plus"></i>
                        </span>
                        <span className="btn-inner--text">
                           Adicionar
                        </span>
                     </button>
                  </div>
               </div>
            </div>
         </div>

         <div className="row">
            {maquinasFixas.map((item, index) => (
               <Robot
                  key={index}
                  mac={item.mac}
                  name={item.computador}
                  anydesk={item.anydesk}
                  fix={item.fixos}
                  link={item.link}
                  online={item.online}
                  robot={{ name: item.robo_nome, version: item.robo_versao, id: item.maquina_id, bank: item.robo_banco_id }}
                  maquina={item}
                  selected={selecionado.includes(item.mac)}
                  onClick={onClick}
                  editarMaquinaFixa={editarMaquinaFixa}
                  pausarMaquinaFixa={pausarMaquinaFixa}
               />
            ))}
            {maquinasFixas.length === 0 && (
               <div className="col-lg-12">
                  <div className="alert text-center" role="alert">
                     <span className="alert-icon">
                        <i className="fas fa-exclamation-circle" />
                     </span>
                     <span className="alert-text">
                        Ops! Nenhuma máquina está online no momento!
                     </span>
                  </div>
               </div>
            )}
         </div>

         {/* Modal - Formulário da Máquina Fixa  */}
         <div
            id="modalFormularioMaquinaFixa"
            className="modal fade"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalMaquinaFixaLabel"
            aria-hidden="true"
         >
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
               <div className="modal-content">
                  <div className="modal-header align-items-center">
                     <h5 id="tituloFormularioMaquinaFixa" className="modal-title">Formulário de Máquina Fixa</h5>
                     <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                     </button>
                  </div>
                  <div className="modal-body">
                     <form>
                        <div className="row card-wrapper">
                           <div className="col-lg-12">
                              <div className="card">
                                 <div className="card-header">
                                    <h5 className="h3 mb-0">Dados da máquina</h5>
                                 </div>
                                 <div className="card-body">
                                    <div className="row">
                                       <div className="col-6">
                                          <div className="form-group">
                                             <label htmlFor="computador" className="form-control-label">
                                                Nome do computador
                                             </label>
                                             <Input
                                                name="computador"
                                                id="computador"
                                                className="form-control"
                                                value={dadosMaquinaFixa.computador || null}
                                                placeholder="Ex: Máquina Escobs 01"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                help={erros.computador || ""}
                                             />
                                          </div>
                                       </div>
                                       <div className="col-6">
                                          <div className="form-group">
                                             <label htmlFor="mac" className="form-control-label">
                                                MAC
                                             </label>
                                             <Input
                                                name="mac"
                                                id="mac"
                                                className="form-control"
                                                value={dadosMaquinaFixa.mac || null}
                                                placeholder="Ex: 0A:E0:AF:CD:00:57"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                help={erros.mac || ""}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-6">
                                          <div className="form-group">
                                             <label htmlFor="anydesk" className="form-control-label">
                                                Anydesk
                                             </label>
                                             <Input
                                                name="anydesk"
                                                id="anydesk"
                                                className="form-control"
                                                value={dadosMaquinaFixa.anydesk || null}
                                                placeholder="Ex: 288185722"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                help={erros.anydesk || ""}
                                             />
                                          </div>
                                       </div>
                                       <div className="col-6">
                                          <div className="form-group">
                                             <label htmlFor="senha" className="form-control-label">
                                                Senha
                                             </label>
                                             <Input
                                                name="senha"
                                                id="senha"
                                                className="form-control"
                                                value={dadosMaquinaFixa.senha || null}
                                                placeholder="Ex: Poupa2022"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                help={erros.senha || ""}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-12">
                                          <div className="form-group">
                                             <label htmlFor="link" className="form-control-label">
                                                Link
                                             </label>
                                             <Input
                                                name="link"
                                                id="link"
                                                className="form-control"
                                                value={dadosMaquinaFixa.link || null}
                                                placeholder="Ex: http://cc210d89b186.sn.mynetname.net:5801/vnc.html?autoconnect=1"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                help={erros.link || ""}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-lg-12">
                              <div className="card">
                                 <div className="card-header">
                                    <div className="row">
                                       <div className="col-8">
                                          <h5 className="h3 mb-0">Dados dos robôs</h5>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="card-body">
                                    <div className="row">
                                       <div className="col-lg-12">
                                          <div className="form-group">
                                             <label htmlFor="robo" className="form-control-label">
                                                Robôs
                                             </label>
                                             <select
                                                name="robo"
                                                id="robo"
                                                className="form-control"
                                                value={dadosMaquinaFixa.robo || null}
                                                onChange={handleChange}
                                             >
                                                <option value="">Selecione um robô</option>
                                                {listaRobos.map(item => (
                                                   <option value={item.id}>{item.nome}</option>
                                                ))}
                                             </select>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-lg-6">
                                          <div className="form-group">
                                             <label htmlFor="horaInicio" className="form-control-label">
                                                Hora Início
                                             </label>
                                             <Input
                                                type="time"
                                                name="horaInicio"
                                                id="horaInicio"
                                                className="form-control"
                                                value={dadosMaquinaFixa.horaInicio || null}
                                                placeholder="Ex: 10:00"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                help={erros.horaInicio || ""}
                                             />
                                          </div>
                                       </div>
                                       <div className="col-lg-6">
                                          <div className="form-group">
                                             <label htmlFor="horaTermino" className="form-control-label">
                                                Hora Termino
                                             </label>
                                             <Input
                                                type="time"
                                                name="horaTermino"
                                                id="horaTermino"
                                                className="form-control"
                                                value={dadosMaquinaFixa.horaTermino || null}
                                                placeholder="Ex: 21:59"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                help={erros.horaTermino || ""}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row mb-5">
                                       <div className="col-lg-12 text-right">
                                          {editarRoboMaquinaFixa ? (
                                             <>
                                                <button
                                                   type="button"
                                                   className="btn btn-sm btn-secondary"
                                                   onClick={desabilitarEdicaoRoboMaquinaFixa}
                                                >
                                                   Cancelar
                                                </button>
                                                <button
                                                   type="button"
                                                   className="btn btn-sm btn-primary"
                                                   onClick={editarRobo}
                                                >
                                                   Alterar
                                                </button>
                                             </>
                                          ) : (
                                             <button
                                                type="button"
                                                className="btn btn-sm btn-primary"
                                                onClick={adicionarRoboMaquinaFixa}
                                             >
                                                Adicionar
                                             </button>
                                          )}
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-lg-12">
                                          <div className="table-responsive">
                                             <table className="table table-flush align-items-center">
                                                <thead className="thead-light">
                                                   <tr>
                                                      <th scope="col">#</th>
                                                      <th scope="col">Robô</th>
                                                      <th scope="col">Horário</th>
                                                      <th scope="col"></th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                   {montarTabelaRobosMaquinaFixa(roboMaquinaFixa)}
                                                </tbody>
                                             </table>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
                  <div className="modal-footer">
                     <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={desabilitarEdicaoRoboMaquinaFixa}
                     >
                        Cancelar
                     </button>
                     <button
                        type="button"
                        className="btn btn-primary"
                        onClick={salvarMaquinaFixa}
                     >
                        Salvar
                     </button>
                  </div>
               </div>
            </div>
         </div>

         {/* Modal - Filtro */}
         <div
            id="modalFiltroMaquinaFixa"
            className="modal fade"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalFiltroMaquinaFixa"
            aria-hidden="true"
         >
            <div className="modal-dialog modal-dialog-centered" role="document">
               <div className="modal-content">
                  <div className="modal-header align-items-center">
                     <h5 id="tituloFormularioMaquinaFixa" className="modal-title">Filtro</h5>
                     <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                     </button>
                  </div>
                  <div className="modal-body">
                     <form>
                        <div className="row card-wrapper">
                           <div className="col-lg-12">
                              <div className="card">
                                 <div className="card-header">
                                    <h5 className="h3 mb-0">Dados do filtro</h5>
                                 </div>
                                 <div className="card-body">
                                    <div className="row">
                                       <div className="col-12">
                                          <div className="form-group">
                                             <label htmlFor="computador" className="form-control-label">
                                                Nome do computador
                                             </label>
                                             <Input
                                                name="computadorFiltro"
                                                id="computadorFiltro"
                                                className="form-control"
                                                value={dadosFiltroMaquinaFixa.computadorFiltro || null}
                                                placeholder="Ex: Máquina Escobs 01"
                                                onChange={handleChangeFiltro}
                                                onBlur={handleBlurFiltro}
                                                help={errosFiltro.computadorFiltro || ""}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-12">
                                          <div className="form-group">
                                             <label htmlFor="computador" className="form-control-label">
                                                MAC
                                             </label>
                                             <Input
                                                name="macFiltro"
                                                id="macFiltro"
                                                className="form-control"
                                                value={dadosFiltroMaquinaFixa.macFiltro || null}
                                                placeholder="Ex: 0A:E0:AF:CD:00:57"
                                                onChange={handleChangeFiltro}
                                                onBlur={handleBlurFiltro}
                                                help={errosFiltro.macFiltro || ""}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-12">
                                          <div className="form-group">
                                             <label htmlFor="computador" className="form-control-label">
                                                Robôs
                                             </label>
                                             <select
                                                name="roboFiltro"
                                                id="roboFiltro"
                                                className="form-control"
                                                value={dadosFiltroMaquinaFixa.roboFiltro || null}
                                                onChange={handleChangeFiltro}
                                             >
                                                <option value="">Selecione um robô</option>
                                                {listaRobos.map(item => (
                                                   <option value={item.id}>{item.nome}</option>
                                                ))}
                                             </select>
                                          </div>
                                       </div>
                                    </div>
                                    {/* <div className="row">
                                       <div className="col-12">
                                          <div className="form-group">
                                             <label htmlFor="computador" className="form-control-label">
                                                Status
                                             </label>
                                             <select
                                                name="statusFiltro"
                                                id="statusFiltro"
                                                className="form-control"
                                                value={dadosFiltroMaquinaFixa.statusFiltro || null}
                                                onChange={handleChangeFiltro}
                                             >
                                                <option>Status 1</option>
                                                <option>Status 2</option>
                                                <option>Status 3</option>
                                                <option>Status 4</option>
                                             </select>
                                          </div>
                                       </div>
                                    </div> */}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
                  <div className="modal-footer">
                     <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={limparFormularioFiltroMaquinasFixas}
                     >
                        Cancelar
                     </button>
                     <button
                        type="button"
                        className="btn btn-primary"
                        onClick={filtrarListaMaquinaFixa}
                     >
                        Filtrar
                     </button>
                  </div>
               </div>
            </div>
         </div>

      </div>
   );
}