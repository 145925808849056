import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import axios from "axios"
import TableV2 from "../components/TableV2"
import { toast } from "react-toastify"
import { LOGOUT, SET_TITLE } from "../redux/Actions"

import { pm } from "../utils"
const https = require("https")

const { REACT_APP_SERVIDOR } = process.env

const columns = [
   { 
      id: "lista", 
      name: "Arquivo", 
      sortable: false, 
      cell: (item) => <strong>{item.lista}</strong> 
   },
   {
      id: "criado_em", 
      name: "Criado Em", 
      sortable: false, 
      cell: "criado_em"
   },
   {
      id: "total", 
      name: "Total", 
      sortable: false, 
      cell: "total"
   },
   {
      id: "processado", 
      name: "Processados", 
      sortable: false, 
      cell: "processado"
   },
   {
      id: "nao_processado", 
      name: "Não Processados", 
      sortable: false, 
      cell: "nao_processado"
   },
   {
      id: "porcentagem", 
      name: "% Processamento", 
      sortable: false, 
      cell: (item) => <strong>{item.porcentagem}</strong>
   },
   {
      id: "ultimo_processado", 
      name: "Último Processado", 
      sortable: false, 
      cell: "ultimo_processado"
   }
]

function RobotCancelamentoProposta(props) {
   const [filter, setFilter] = useState(null)
   const [permission, setPermission] = useState(false)
   
   // Upload File
   const [filename, setFilename] = useState(null)
   const [selectedFile, setSelectedFile] = useState()
   const [isFilePicked, setIsFilePicked] = useState(false)
   const [uploading, setUploading] = useState(false)
   
   const table = useRef()

   const user = useSelector((state) => state.user)
   const dispatch = useDispatch()

   useEffect(() => {
      dispatch({ type: SET_TITLE, payload: null })
	}, [])

   const changeHandler = (event) => {
      setSelectedFile(event.target.files[0])
		setIsFilePicked(true)
   }

   const removeFile = () => {
		setIsFilePicked(false)
		setSelectedFile(undefined)
	}

   const handleFileImport = async () => {
      if (selectedFile == null) {
         toast.warning("Por favor, selecionar um arquivo")         
      } else if (selectedFile.size / 1024 / 1024 > 5) {
         toast.warning(
				"Arquivo deve conter no máximo 5MB. Se necessário divida o arquivo em vários e faça o upload de todos com o mesmo APELIDO."
			)
      } else {
         toast.promise(
				() => {
					return new Promise(async (resolve, reject) => {
						try {
                     setUploading(true)

                     const formaData = new FormData()
                     formaData.append("file", selectedFile)

                     await axios.post(`${REACT_APP_SERVIDOR}santander/robo/cancelamento-proposta/upload-lista`, formaData, {
                        timeout: 5 * 60000,
                        headers: { token: user.token, "Content-Type": "multipart/form-data" },
								httpsAgent: new https.Agent({
									rejectUnauthorized: false
								})
                     })

                     setUploading(false)

                     removeFile()
                     resolve()
                  } catch (error) {
                     setUploading(false)
                     console.warn(error)
							reject(error)

                     if (error && error.response && error.response.status == 403) {
                        dispatch({ type: LOGOUT })

                        if (!toast.isActive("session")) {
									toast.warning("Sua sessão foi expirada. Faça login novamente!", {
										toastId: "session",
									})
								}
                     }
                  }
					})
				},
				{
					pending: `Aguarde, fazendo upload das propostas a cancelar.`,
					success: `Upload do arquivo feito com sucesso. Em breve as propostas serão canceladas`,
					error: `Falha ao efetuar upload. Verifique sua conexão e tente novamente.`,
				}
			)
      }
   }

   return (
      <main className="container-fluid mt--6">
         <section id="header" className="row card-wrapper">
            <div className="card-header bg-transparent border-0" style={{ width: "100vw" }}>
               <div className="row align-items-center">
                  <div className="col-8">
                     <h6 className="h2 d-inline-block mb-0">Robô - Cancelamento de propostas</h6>
                  </div>
               </div>
            </div>
         </section>

         <section id="lista-propostas-canceladas" className="row">
            <div className="col-md-12 col-sm-12">
               <div className="card">
                  <div className="card-header border-0">
                     <div className="row">
                        <div className="col-9">
                           <h3 className="mb-0">Listas</h3>
                        </div>
                     </div>
                  </div>
                  <TableV2 ref={table} filter={filter} table={"santander/lista-cancelamento-proposta"} columns={columns} />
               </div>
            </div>
         </section>

         <section id="importar-propostas-cancelamento" className="row">
            <div className="col-md-6 col-sm-12">
               <div className="card">
                  <div className="card-header border-0">
                     <div className="row">
                        <div className="col-9">
                           <h3 className="mb-0">Importar Arquivo</h3>
                        </div>
                     </div>
                  </div>
                  <div className="card-body">
                     <form>
                        <div className="form-group">
                           {isFilePicked ? (
                              <div style={{ marginTop: 20 }}>
                                 <p className="form-control-label">Nome: {selectedFile.name}</p>
                                 <p className="form-control-label">Tipo: {selectedFile.type}</p>
                                 <p className="form-control-label">Tamanho em bytes: {selectedFile.size}</p>
                                 <p className="form-control-label">
                                    Última Modificação: {selectedFile.lastModifiedDate.toLocaleDateString()}
                                 </p>
                              </div>
                           ) : (
                              <div className="form-group">
											<label className="form-control-label" htmlFor="example3cols1Input">
												Arquivo CSV*
											</label>
											<div className="custom-file">
												<input
													type="file"
													id="input-proposal-file"
													className="custom-file-input"
													lang="en"
													accept=".csv"
													onChange={changeHandler}
												/>
												<label className="custom-file-label">Selecionar arquivo...</label>
											</div>
										</div>                              
                           )}
                        </div>
                        {isFilePicked && (
                           <div>
                              <button type="button" className="btn btn-danger" onClick={removeFile}>
                                 Remover
                              </button>
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 onClick={handleFileImport}
                              >
                                 Upload
                              </button>
                           </div>
                        )}
                     </form>
                  </div>
               </div>
            </div>
         </section>
      </main>
   )
}

export default RobotCancelamentoProposta