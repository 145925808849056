import "./Live.css"

import axios from "axios"
import datalabels from "chartjs-plugin-datalabels"
import moment from "moment"
import { useEffect, useState } from "react"
import { Chart, Doughnut, Line } from "react-chartjs-2"
import Loader from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import ReactTable from "react-table"
import Status from "../components/Status"
import { LOGOUT, SET_TITLE } from "../redux/Actions"
import { diff, dynamicColors, f, formatName, pm, capitalize } from "../utils"

const https = require("https")
const { REACT_APP_SERVIDOR } = process.env
Chart.register(datalabels)
const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
    },
    datalabels: {
      backgroundColor: function (context) {
        return context.dataset.backgroundColor
      },
      borderColor: "white",
      borderRadius: 100,
      color: "white",
      display: function (context) {
        var dataset = context.dataset
        var count = dataset.data.length
        var value = dataset.data[context.dataIndex]
        return value > count * 0.2
      },
      font: {
        weight: "bold",
      },
      padding: 2,
      formatter: Math.round,
    },
  },
}

function PoupaconsigLive(props) {
  const user = useSelector(state => state.user)
  const [list, setList] = useState([])
  const [permitted, setPermitted] = useState(false)
  const [name, setName] = useState(null)
  const [id, setId] = useState(null)
  const [socketId, setSocketId] = useState(null)
  const [partner, setPartner] = useState(null)
  const [base64, setBase64] = useState(null)
  const [logs, setLogs] = useState(null)
  const [loadingPrint, setLoadingPrint] = useState(true)
  const [loadingLogs, setLoadingLogs] = useState(false)
  const [hardware, setHardware] = useState(null)
  const [version, setVersion] = useState(null)
  const [message, setMessage] = useState(null)
  const [ids, setIds] = useState(null)
  const dispatch = useDispatch()

  const checkPermission = () => {
    if (!pm(user, 5)) {
      props.history.push("/")
      toast.error("Você não tem permissão para acessar esta página.")
    } else {
      setPermitted(true)
      dispatch({ type: SET_TITLE, payload: "Poupanegócios > Live" })
    }
  }

  const ft = x => (ids && ids.length == 0) || ids || ids.includes(x.id + x.parceiro.toString())

  const getInitial = async () => {
    try {
      setList([])

      const req1 = await axios.get(
        `${REACT_APP_SERVIDOR}poupaconsig/lista-ids-permitidos?id=${user.id}&parceiro=${user.parceiro}`,
        {
          headers: { token: user.token },
          httpsAgent: new https.Agent({
            rejectUnauthorized: false,
          }),
        }
      )

      const req2 = await axios.get(`${REACT_APP_SERVIDOR}poupaconsig/lista`, {
        headers: { token: user.token },
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
        }),
      })

      if (req1.data.length > 0) {
        const aux = req1.data.map(x => x.id + x.parceiro.toString())
        setIds(aux)
        setList(
          req2.data
            .filter(x => aux.includes(x.id + x.parceiro.toString()))
            .sort((a, b) => a.usuario.localeCompare(b.usuario))
        )
      } else {
        setIds([])
        setList(req2.data.sort((a, b) => a.usuario.localeCompare(b.usuario)))
      }
    } catch (err) {
      console.warn(err)
      if (err && err.response && err.response.status == 403) {
        dispatch({ type: LOGOUT })
        if (!toast.isActive("session")) {
          toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
        }
      }
    }
  }

  // Enviar requisição para as máquinas online:
  useEffect(() => {
    try {
      checkPermission()
      getInitial()
    } catch (err) {
      console.warn(err)
    }
  }, [])

  const getScreenshot = async socketId => {
    try {
      setLoadingPrint(true)
      const req = await axios.get(`${REACT_APP_SERVIDOR}poupaconsig/screenshot?socketId=${socketId}`, {
        headers: { token: user.token },
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
        }),
      })
      setBase64(req.data.base64)
      setLoadingPrint(false)
    } catch (err) {
      console.warn(err)

      if (err && err.response && err.response.status == 403) {
        dispatch({ type: LOGOUT })
        if (!toast.isActive("session")) {
          toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
        }
      }
    }
  }

  const getLogs = async socketId => {
    try {
      setLoadingLogs(true)
      const req = await axios.get(`${REACT_APP_SERVIDOR}poupaconsig/log?socketId=${socketId}`, {
        headers: { token: user.token },
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
        }),
      })
      setLogs(req.data.logs)
      setHardware(req.data.hardware)
      setVersion(req.data.versao)
      setLoadingLogs(false)
    } catch (err) {
      console.warn(err)
      if (err && err.response && err.response.status == 403) {
        dispatch({ type: LOGOUT })
        if (!toast.isActive("session")) {
          toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
        }
      }
    }
  }

  const diffNow = start => {
    const ms = moment().diff(start)
    const d = moment.duration(ms)
    const s = [f(d.minutes()), f(d.seconds())].join(":")
    return s
  }

  const disconnect = async () => {
    toast.promise(
      () => {
        return new Promise(async (resolve, reject) => {
          try {
            await axios.post(
              `${REACT_APP_SERVIDOR}poupaconsig/desconectar`,
              {
                id,
                parceiro: partner,
                mensagem: message ? message.trim().toUpperCase() : undefined,
                socketId: socketId,
              },
              {
                headers: { token: user.token },
                httpsAgent: new https.Agent({
                  rejectUnauthorized: false,
                }),
              }
            )

            await getInitial()

            resolve()
          } catch (err) {
            reject(err)

            if (err && err.response && err.response.status == 403) {
              dispatch({ type: LOGOUT })
              if (!toast.isActive("session")) {
                toast.warning("Sua sessão foi expirada. Faça login novamente!", { toastId: "session" })
              }
            }
          }
        })
      },
      {
        pending: `Desconectando ${name}...`,
        success: `${name} foi desconectado com sucesso!`,
        error: `Falha ao desconectar ${name}. Verifique sua conexão e tente novamente.`,
      }
    )
  }

  const bytesToSize = bytes => {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    if (bytes == 0) return "0 Byte"
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i]
  }

  const onClickPrint = x => {
    setName(x.usuario)
    setId(x.id)
    setSocketId(x.socketId)
    setPartner(x.parceiro)
    getScreenshot(x.socketId)
  }

  const onClickLogs = x => {
    setName(x.usuario)
    setId(x.id)
    setSocketId(x.socketId)
    setPartner(x.parceiro)
    getLogs(x.socketId)
  }

  const onClickDisconnnect = x => {
    setName(x.usuario)
    setPartner(x.parceiro)
    setId(x.id)
    setSocketId(x.socketId)
    setMessage(null)
  }

  const getCorbans = () => {
    const corbans = {}
    const aux = list.filter(x => x.corban)
    for (let i = 0; i < aux.length; i++) {
      const corban = aux[i].corban
      if (corbans[corban]) {
        corbans[corban] += 1
      } else {
        corbans[corban] = 1
      }
    }
    return corbans
  }

  if (!permitted) {
    return null
  }

  return (
    <div className="container-fluid mt--6">
      {ids != null && (
        <div className="row card-wrapper">
          <div className="col-xl-2">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h6 className="card-title text-uppercase text-muted mb-0">Total de Usuários</h6>
              </div>
              <div style={{ height: 200 }} className="card-body">
                <Doughnut
                  options={defaultOptions}
                  data={{
                    labels: ["Promotores", "Parceiros"],
                    datasets: [
                      {
                        label: "Dados",
                        data: [
                          list.filter(ft).filter(x => x.parceiro == false).length,
                          list.filter(ft).filter(x => x.parceiro == true).length,
                        ],
                        backgroundColor: ["orange", "blue"],
                        hoverOffset: 4,
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-2">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h6 className="card-title text-uppercase text-muted mb-0">Status dos Usuários</h6>
              </div>
              <div style={{ height: 200 }} className="card-body">
                <Doughnut
                  options={defaultOptions}
                  data={{
                    labels: ["Online", "Ausentes"],
                    datasets: [
                      {
                        label: "Dados",
                        data: [
                          list.filter(ft).filter(x => x.ausencia == null).length,
                          list.filter(ft).filter(x => x.ausencia != null).length,
                        ],
                        backgroundColor: ["#2dce89", "#fb6340"],
                        hoverOffset: 4,
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-2">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h6 className="card-title text-uppercase text-muted mb-0">Uso de Siglas</h6>
              </div>
              <div style={{ height: 200 }} className="card-body">
                <Doughnut
                  options={defaultOptions}
                  data={{
                    labels: ["Usando", "Não usando"],
                    datasets: [
                      {
                        label: "Dados",
                        data: [
                          list.filter(ft).filter(x => x.csgLogin != null).length,
                          list.filter(ft).filter(x => x.csgLogin == null).length,
                        ],
                        backgroundColor: ["red", "gray"],
                        hoverOffset: 4,
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-2">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h6 className="card-title text-uppercase text-muted mb-0">Corbans</h6>
              </div>
              <div style={{ height: 200 }} className="card-body">
                <Doughnut
                  options={defaultOptions}
                  data={{
                    labels: Object.keys(getCorbans()),
                    datasets: [
                      {
                        label: "Dados",
                        data: Object.values(getCorbans()),
                        hoverOffset: 4,
                        backgroundColor: Object.values(getCorbans()).map(x => dynamicColors()),
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h6 className="card-title text-uppercase text-muted mb-0">Tempo de Login</h6>
              </div>
              <div style={{ height: 200 }} className="card-body">
                <Line
                  options={{
                    ...defaultOptions,
                    scales: {
                      x: {
                        ticks: {
                          display: false,
                        },
                      },
                    },
                  }}
                  data={{
                    labels: list
                      .filter(ft)
                      .filter(x => x.cookieFim)
                      .map(x => x.usuario),
                    datasets: [
                      {
                        label: "Segundos",
                        data: list
                          .filter(ft)
                          .filter(x => x.cookieFim)
                          .map(x =>
                            moment
                              .duration(moment.utc(x.cookieFim, "X").diff(moment.utc(x.cookieInicio, "X")))
                              .asSeconds()
                          ),
                        hoverOffset: 4,
                        backgroundColor: "red",
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row card-wrapper">
        <div className="col-xl-12">
          <div className="card">
            {/* Card header */}
            <div className="card-header border-0">
              <div className="row">
                <div className="col-6">
                  <div className="row col-12">
                    <h3 className="mb-0">Usuários</h3>
                    <button
                      onClick={() => getInitial()}
                      style={{ marginLeft: 10 }}
                      class="btn btn-sm btn-warning"
                      type="button"
                    >
                      <i className="fas fa-sync" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Light table */}
            {ids == null || list.filter(ft).length == 0 ? (
              <div className="col-lg-12">
                <div className="alert text-center" role="alert">
                  <span className="alert-icon">
                    <i className="fas fa-exclamation-circle" />
                  </span>
                  <span className="alert-text">
                    <strong>Ops...</strong> nenhuma máquina online foi encontrada.
                  </span>
                </div>
              </div>
            ) : (
              <ReactTable
                columns={[
                  {
                    id: "Id",
                    Header: "Id",
                    accessor: "id",
                    sortable: false,
                    className: "text-center",
                    width: 60,
                  },
                  {
                    id: "Tipo",
                    Header: "Tipo",
                    accessor: x => (
                      <span className="badge badge-dot">
                        <span style={{ color: x.parceiro ? "blue" : "orange" }}>● </span>
                        {x.parceiro ? "parceiro" : "promotor"}
                      </span>
                    ),
                    sortable: false,
                    className: "text-center",
                    width: 100,
                  },
                  {
                    id: "Nome",
                    Header: "Nome",
                    accessor: x => <b>{formatName(x.usuario)}</b>,
                    sortable: false,
                    className: "text-center",
                    width: 170,
                  },
                  {
                    id: "Cargo",
                    Header: "Cargo",
                    accessor: x =>
                      x.cargos.length == 0 ? (
                        <b>Parceiro</b>
                      ) : (
                        x.cargos
                          .filter(y => y.escritorio_id != 1)
                          .map(y => (
                            <span>
                              <b>{capitalize(y.funcao)}</b>
                              {` ● ${y.escritorio}`}
                              <br />
                            </span>
                          ))
                      ),
                    sortable: false,
                    className: "text-center",
                  },
                  {
                    id: "Status",
                    Header: "Status",
                    accessor: x => <Status away={x.ausencia != null} />,
                    sortable: false,
                    className: "text-center",
                    width: 80,
                  },
                  {
                    id: "Ausência",
                    Header: "Ausência",
                    accessor: x => (x.ausencia ? diffNow(moment.utc(x.ausencia, "X")) : "-"),
                    sortable: false,
                    className: "text-center",
                    width: 65,
                  },
                  {
                    id: "Versão",
                    Header: "Versão",
                    accessor: "versao",
                    sortable: false,
                    className: "text-center",
                    width: 70,
                  },
                  {
                    id: "Login",
                    Header: "Login",
                    accessor: x =>
                      x.cookieInicio && x.cookieFim
                        ? diff(moment.utc(x.cookieInicio, "X"), moment.utc(x.cookieFim, "X"))
                        : x.cookieInicio
                        ? diffNow(moment.utc(x.cookieInicio, "X"))
                        : "-",
                    sortable: false,
                    className: "text-center",
                    width: 65,
                  },
                  {
                    id: "CSG",
                    Header: "CSG",
                    accessor: x =>
                      x.cookieFim ? diffNow(moment.utc(x.cookieFim, "X")) : x.cookieInicio ? "Conectando..." : "-",
                    sortable: false,
                    className: "text-center",
                    width: 65,
                  },
                  {
                    id: "Sigla",
                    Header: "Sigla",
                    accessor: x => (x.csgLogin ? <b>{x.csgLogin}</b> : "-"),
                    sortable: false,
                    className: "text-center",
                    width: 80,
                  },
                  {
                    id: "Corban",
                    Header: "Corban",
                    accessor: x => (x.corban ? <b>{x.corban}</b> : x.cookieInicio ? "Conectando" : "-"),
                    sortable: false,
                    className: "text-center",
                    width: 80,
                  },
                  {
                    id: "Página",
                    Header: "Página",
                    accessor: x => (x.urlInicio ? diffNow(moment.utc(x.urlInicio, "X")) : "-"),
                    sortable: false,
                    className: "text-center",
                    width: 65,
                  },
                  {
                    id: "Ações",
                    Header: "Ações",
                    accessor: x =>
                      x.cookieInicio != null && (
                        <div style={{ display: "block" }} className="row text-center">
                          <a
                            title="Ver tela do usuário"
                            href="#!"
                            onClick={() => onClickPrint(x)}
                            className="table-action"
                            data-toggle="modal"
                            data-target="#modal-print"
                          >
                            <i className="fas fa-camera" />
                          </a>
                          <a
                            title="Ver logs do usuário"
                            href="#!"
                            onClick={() => onClickLogs(x)}
                            className="table-action"
                            data-toggle="modal"
                            data-target="#modal-logs"
                          >
                            <i className="fas fa-clipboard-list" />
                          </a>
                          <a
                            title="Desconectar usuário"
                            href="#!"
                            onClick={() => onClickDisconnnect(x)}
                            className="table-action"
                            data-toggle="modal"
                            data-target="#modal-disconnect"
                          >
                            <i className="fas fa-power-off" />
                          </a>
                        </div>
                      ),
                    sortable: false,
                    width: 80,
                  },
                ]}
                multiSort={false}
                filterable={false}
                showPagination={false}
                manual
                data={list.filter(ft)}
                className="-striped -highlight"
                previousText={"Anterior"}
                nextText={"Próxima"}
                loadingText={"Carregando..."}
                noDataText={"Nenhum dado encontrado."}
                pageText={"Página"}
                ofText={"de"}
                rowsText={"linhas"}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modal-print"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modal-print"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">{`Tela - ${name}`}</h6>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {loadingPrint ? (
                <div className="text-center">
                  <Loader type="ThreeDots" width={50} height={50} color="#172b4d" />
                </div>
              ) : (
                <img
                  className="card-img-top zoom"
                  src={base64 ? `data:image/png;base64, ${base64}` : "../../assets/img/theme/offline.png"}
                  alt="Image placeholder"
                  onClick={() => {
                    let data = `data:image/png;base64,${base64}`
                    let w = window.open("about:blank")
                    let image = new Image()
                    image.src = data
                    setTimeout(function () {
                      w.document.write(image.outerHTML)
                    }, 0)
                  }}
                />
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-link ml-auto" data-dismiss="modal">
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modal-logs"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modal-logs"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title text-center" id="modal-title-default">{`Logs - ${name}`}</h6>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {loadingLogs ? (
                <div className="text-center">
                  <Loader type="ThreeDots" width={50} height={50} color="#172b4d" />
                </div>
              ) : logs ? (
                <div className="table-responsive">
                  <h2>Máquina</h2>
                  <table
                    style={{ borderRadius: 10, overflow: "hidden" }}
                    className="table table-bordered table-sm align-items-center text-center"
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>CPU</th>
                        <th>Cores</th>
                        <th>Arquitetura</th>
                        <th>Ram (Livre)</th>
                        <th>Ram (Total)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{hardware && hardware.cpus ? hardware.cpus[0] : "-"}</td>
                        <td>{hardware && hardware.cpus ? hardware.cpus.length : "-"}</td>
                        <td>{hardware && hardware.arch ? hardware.arch : "-"}</td>
                        <td>{hardware && hardware.freemem ? bytesToSize(hardware.freemem) : "-"}</td>
                        <td>{hardware && hardware.totalmem ? bytesToSize(hardware.totalmem) : "-"}</td>
                      </tr>
                    </tbody>
                    <thead className="thead-light">
                      <tr>
                        <th>Sistema Operacional</th>
                        <th>Usuário</th>
                        <th>Versão SO</th>
                        <th>Versão Robô</th>
                        <th>Uptime</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{hardware && hardware.type ? hardware.type : "-"}</td>
                        <td>{hardware && hardware.hostname ? hardware.hostname : "-"}</td>
                        <td>{hardware && hardware.release ? hardware.release : "-"}</td>
                        <td>{version ? version : "-"}</td>
                        <td>{hardware && hardware.uptime ? hardware.uptime / 60 + "min" : "-"}</td>
                      </tr>
                    </tbody>
                  </table>
                  <h2>Log</h2>
                  <table
                    style={{ maxHeight: 400, overflowY: "scroll", display: "block", borderRadius: 20 }}
                    className="table table-bordered table-sm table-striped"
                  >
                    <tbody>
                      {logs &&
                        logs.map(x => (
                          <tr key={x.time}>
                            <td>
                              <b>{`${moment.utc(x.time, "X").format("DD/MM HH:mm:ss")} ● `}</b>
                              <span
                                style={{
                                  color: x.type == "error" ? "red" : x.text.startsWith("(CSG)") ? "blue" : undefined,
                                }}
                              >
                                {x.text}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-center">Não foi comunicar com a máquina desejada.</p>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-link ml-auto" data-dismiss="modal">
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modal-disconnect"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modal-disconnect"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">{`Desconectar - ${name}`}</h6>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-control-label">Informe o usuário o motivo da desconexão:</label>
                  <div className="input-group input-group-merge input-group-alternative">
                    <input
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      maxLength="100"
                      placeholder="Motivo..."
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button onClick={disconnect} type="button" className="btn btn-link ml-auto" data-dismiss="modal">
                Desconectar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PoupaconsigLive
