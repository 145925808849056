const moment = require("moment")

const capitalize = str => {
  if (str) {
    const splitStr = str.toLowerCase().split(" ")
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(" ")
  } else {
    return null
  }
}

const pm = (user, id) => {
  if (user.permissoes) {
    if (Array.isArray(id)) {
      return user.permissoes.some(item => id.includes(item))
    } else {
      return user.permissoes.includes(id)
    }
  } else {
    return false
  }
}

const f = myNumber => (myNumber.toString().length < 2 ? "0" + myNumber : myNumber)

const diff = (start, end) => {
  const ms = end.diff(start)
  const d = moment.duration(ms)
  const s = [f(d.minutes()), f(d.seconds())].join(":")
  return s
}

const cpfMask = value => {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

const formatName = x => {
  if (x) {
    let aux = capitalize(
      x
        .split("-")
        .at(-1)
        .trim()
        .split(" ")
        .filter(x => x.length > 1)
        .join(" ")
    )

    if (aux.split(" ").length > 2) {
      return aux.split(/(\s).+\s/).join("")
    } else {
      return aux
    }
  } else {
    return x
  }
}

const dynamicColors = function () {
  var r = Math.floor(Math.random() * 255)
  var g = Math.floor(Math.random() * 255)
  var b = Math.floor(Math.random() * 255)
  return "rgb(" + r + "," + g + "," + b + ")"
}

module.exports = {
  capitalize,
  pm,
  f,
  diff,
  cpfMask,
  formatName,
  dynamicColors,
}
