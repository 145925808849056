import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { LOGOUT, SET_TITLE } from "../redux/Actions";

import { pm } from "../utils"

import Input from "../components/Input/Input";

const https = require("https");
const { REACT_APP_SERVIDOR } = process.env;

export function ExportacaoComissoes(props) {
   const [permissao, setPermissao] = useState(false);
   const [dadosExportacao, setDadosExportacao] = useState({});
   const [listaCorbans, setListaCorbans] = useState([]);
   const [dataProcessado, setDataProcessado] = useState(moment().subtract(1, "day"));

   const usuario = useSelector(state => state.user);
   const dispatch = useDispatch();

   function verificarPermissao(props) {
      if (!pm(usuario, 1)) {
         props.history.push("/");
         toast.error("Você não possui permissão para acessar essa página!");
      } else {
         setPermissao(true);
         dispatch({ type: SET_TITLE, payload: "Exportações > Comissões" });
      }
   }

   function handleChange(event) {
      const { id, value } = event.target;
      setDadosExportacao({ ...dadosExportacao, [id]: value });
   }

   function handleDateChange(id, value) {
      setDadosExportacao({ ...dadosExportacao, [id]: value });
   }

   function limparFormulario() {
      setDadosExportacao({});
      setDataProcessado(moment().subtract(1, "day"));

      document.querySelector("#dataProcessado").value = dataProcessado.format("YYYY-MM-DD");
      document.querySelector("#corban").value = "";
   }

   async function listarCorban() {
      try {
         const resultado = await axios.get(`${REACT_APP_SERVIDOR}geral/corban/listar`, {
            headers: { token: usuario.token },
            httpsAgent: new https.Agent({
               rejectUnauthorized: false,
            }),
         }
         );

         const { status } = resultado;
         const { dados, retorno } = resultado.data;

         if (status === 200 && retorno) {
            setListaCorbans(dados);
         }
      } catch (error) {
         console.log(error);
         toast.error("Ocorreu um erro ao listar os corbans");
      }
   }

   async function handleExportar() {
      try {
         if (dadosExportacao.dataProcessado == "") {
            toast.warn("O campo de data processado é obrigatório");
            return;
         }

         window.open(`${REACT_APP_SERVIDOR}arquivo/excel/comissao?params=${JSON.stringify(dadosExportacao)}`);

         if (typeof dadosExportacao.corban == "undefined") {
            limparFormulario();
         }
      } catch (error) {
         console.error(error.message);
         toast.error("Ocorreu um erro ao realizar a exportação das comissões");
      }
   }

   useEffect(() => {
      try {
         verificarPermissao();
         listarCorban();

         const dataProcessado = document.querySelector("#dataProcessado");
         handleDateChange("dataProcessado", dataProcessado.value);
      } catch (error) {
         console.error(error);
      }
   }, []);

   useEffect(() => {
      const dataProcessado = document.querySelector("#dataProcessado");
      handleDateChange("dataProcessado", dataProcessado.value);
   }, [dataProcessado]);

   return (
      <div className="container-fluid mt--6">
         <div className="card">
            <div className="card-body border-0">
               <form>
                  <div className="row align-items-center">
                     <div className="col">
                        <div className="form-group">
                           <label className="form-control-label">Data Processado</label>
                           <input
                              type="date"
                              name="dataProcessado"
                              id="dataProcessado"
                              className="form-control"
                              defaultValue={dataProcessado.format("YYYY-MM-DD")}
                              onChange={handleChange}
                              max={moment().format("YYYY-MM-DD")}
                           />
                        </div>
                     </div>
                     <div className="col">
                        <div className="form-group">
                           <label className="form-control-label">Corban</label>
                           <select
                              name="corban"
                              id="corban"
                              className="form-control"
                              onChange={handleChange}
                           >
                              <option value="">Selecione um corban</option>
                              {listaCorbans.map(item => (
                                 <option value={item.corban}>{item.nome}</option>
                              ))}
                           </select>
                        </div>
                     </div>
                  </div>
                  <div className="row text-right">
                     <div className="col">
                        <div className="form-group">
                           <button
                              type="button"
                              className="btn btn-primary btn-round btn-icon"
                              onClick={handleExportar}
                           >
                              <span className="btn-inner--icon">
                                 <i className="fas fa-download"></i>
                              </span>
                              <span className="btn-inner--text">
                                 Exportar
                              </span>
                           </button>
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   );
}
